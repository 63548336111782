import { Directive, ElementRef, OnDestroy, OnInit, TemplateRef, ViewContainerRef } from '@angular/core';
import { Subscription } from 'rxjs';
import { SafariUiComponentReduxObject } from '../../../state/actions/layout-actions';
import { IfNotReadyBaseDirective } from '../if-not-ready-base/if-not-ready-base.directive';
import { ReduxWrapperService } from '@safarilaw-webapp/shared/redux';

@Directive({
  selector: '[slUiKitIfNotReadyToDisplay]'
})
export class IfNotReadyToDisplayDirective extends IfNotReadyBaseDirective implements OnDestroy, OnInit {
  private _component: any = null;
  private _storeSubscription: Subscription = null;
  constructor(
    private _uiComponentReduxObject: SafariUiComponentReduxObject,
    private _reduxWrapperService: ReduxWrapperService,
    templateRef: TemplateRef<any>,
    viewContainer: ViewContainerRef,
    elementRef: ElementRef
  ) {
    super(templateRef, viewContainer);
    this.propName = 'slUiKitIfNotReadyToDisplay';
    this._context = { slUiKitIfNotReadyToDisplay: null, $implicit: null };
    let element = elementRef.nativeElement;
    this._component = element.__component;
    while (this._component == null && element != null) {
      element = element.parentElement;
      if (element != null) {
        this._component = element.__component;
      }
    }
  }
  ngOnInit(): void {
    // New ready to display - comment out if we see any issues
    if (this._component && typeof this._component['readyToDisplay'] === 'function') {
      if (!this._component.componentId) {
        throw new Error('Unknown component or listid/formid missing');
      }

      this._storeSubscription = this._reduxWrapperService.getGenericSelector(this._uiComponentReduxObject.default.selectors.readyToDisplay(this._component.componentId)).subscribe(readyToDisplay => {
        this._context.slUiKitIfNotReadyToDisplay = this._context.$implicit = readyToDisplay;
        this._processCondition();
      });
    }
  }
  ngOnDestroy(): void {
    if (this._storeSubscription) {
      this._storeSubscription.unsubscribe();
    }
  }
  // New ready to display - uncomment if we see any issues
  // ngDoCheck(): void {
  //   if (this._component && typeof this._component['readyToDisplay'] === 'function') {
  //     const isConditionTrue = this._component['readyToDisplay']();
  //     this._context.slUiKitIfNotReadyToDisplay = this._context.$implicit = isConditionTrue;
  //     this._processCondition();
  //   }
  // }
}
