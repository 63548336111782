import { CommonModule } from '@angular/common';
import { InjectionToken, NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { ActionReducerMap, StoreModule } from '@ngrx/store';
import { ConfirmationDialogComponent } from './dialog/components/confirmation-dialog/confirmation-dialog.component';
import { ISharedState } from './state/state.interface';
import { DialogReducerService } from './state/state.reducer';
const REDUCER_TOKEN = new InjectionToken<ActionReducerMap<ISharedState>>('Dialog State Reducers');

/**
 * This module is used to provide store feature for our dialog utility.
 *
 * We might need to pop up a dialog sometimes , even before the features are loaded, so, like some other "StoreAccess" modules its' probably best to import it
 * in main.ts.
 *
 * Note that other than setting the store, this module also exports one component - ConfirmationDialog component. This is a basic, generic Yes/No dialog that we
 * use throughout the app and it's there so we can pop basic Yes/No messages during the app initialization, if necessary (some error, warning, update info, etc)
 *
 * We shouldn't add any more embelishments to this - more complex dialogs belong to the features.
 *
 */

@NgModule({
  imports: [StoreModule.forFeature('dialogs', REDUCER_TOKEN), CommonModule, ReactiveFormsModule, ConfirmationDialogComponent],
  declarations: [],
  exports: [ConfirmationDialogComponent],
  providers: [
    {
      provide: REDUCER_TOKEN,
      deps: [DialogReducerService],
      useFactory: (service: DialogReducerService) => service.reducers
    }
  ]
})
export class SharedDialogStoreAccessModule {}
