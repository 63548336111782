import { HttpClient } from '@angular/common/http';
import { AfterViewInit, ApplicationRef, ChangeDetectorRef, Directive, HostListener, Injector, Input, OnDestroy, OnInit, Optional, ViewChild } from '@angular/core';
import { ActivatedRoute, ActivatedRouteSnapshot, NavigationCancel, NavigationEnd, NavigationError, NavigationStart, Router } from '@angular/router';
import { Actions } from '@ngrx/effects';
import { Store, select } from '@ngrx/store';
import { AuthService } from '@safarilaw-webapp/shared/auth/store-access';
import { AppDialogUiReduxObject, ModalDialogService } from '@safarilaw-webapp/shared/dialog/store-access';
import { AppConfigurationBootstrap, AppConfigurationService } from '@safarilaw-webapp/shared/environment';
import { ErrorHandlerService } from '@safarilaw-webapp/shared/error-handling';
import { AppContextService, ApplicationInsightsService, LoggerService } from '@safarilaw-webapp/shared/logging';

import { DOCUMENT } from '@angular/common';
import { SafariRouterReduxObject } from '@safarilaw-webapp/shared/routing-utility/store-access';
import { SafariPollingService } from '@safarilaw-webapp/shared/utils';
import { cloneDeep } from 'lodash-es';
import { DateTime } from 'luxon';
import { BlockUI, BlockUIService, NgBlockUI } from 'ng-block-ui';
import { Subscription, of } from 'rxjs';
import { catchError, filter, first } from 'rxjs/operators';
import { NavbarComponent } from '../navbar/components/navbar/navbar.component';
import { NavbarGroup } from '../navbar/models/navbar-group';
import { NavbarHeader } from '../navbar/models/navbar-header';
import { NavbarItem } from '../navbar/models/navbar-item';
import { NavbarService } from '../navbar/services/navbar/navbar.service';
import { AppErrorReduxObject, AppUiReduxObject } from '../state/actions/ui-actions';

import { DefaultValueAccessor } from '@angular/forms';
import { AutologoutDialogComponent } from '@safarilaw-webapp/shared/auth/store-access/autologout-dialog';
import { AuthReduxObject } from '@safarilaw-webapp/shared/common-objects-models';
import { HotKeyRegistrationService } from './hotkey-registration-service';

// eslint-disable-next-line @typescript-eslint/naming-convention -- Global JS property
declare let BrowserAgentCheck: any;

// eslint-disable-next-line @typescript-eslint/unbound-method -- this is what we have to do
const original = DefaultValueAccessor.prototype.registerOnChange;

// I noticed that you can bypass angulars isRequired by simply adding a bunch of empty
// space characters. That's kinda dumb. Fortunately this trick overrides the global
// registerOnChange function and adds a trim() call to it.
DefaultValueAccessor.prototype.registerOnChange = function (fn) {
  return original.call(this, value => {
    if (value == null || typeof value != 'string') {
      return fn(value);
    }
    const trimmed = value.trim().length == 0 ? value.trim() : value;

    return fn(trimmed);
  });
};

export class MaintenanceInfo {
  dateTimeStartUtc: string;
  dateTimeEndUtc: string;
  header?: {
    title?: string;
    details?: string;
  };
  splash?: {
    title?: string;
    details?: string;
  };
  // Our helper props - not a part of the api payload
  startsIn?: number;
  endsIn?: number;
}
@Directive()
export class SafariRootAppComponent implements OnInit, OnDestroy, AfterViewInit {
  @Input() title = '';
  backgroundImageClass: string;
  get shouldShowSwWarning() {
    if (this._appConfig?.uiSettings?.showSwWarning != true) {
      return false;
    }
    return navigator.serviceWorker != null && navigator.serviceWorker.controller == null;
  }
  maintenanceInfoLastRetrieved: Date = new Date(1900, 1, 1);
  maintenanceInfo: MaintenanceInfo = undefined;
  get siteUnderMaintenance() {
    // Don't like these special checks but maybe we can keep this for now ? Makes testing easier and dev-tools path
    // won't be available past QA anyway so it seems fairly harmless...
    if (this._router.url.includes('dev-tools')) {
      return false;
    }
    if (this.maintenanceInfo == null || this.maintenanceInfo.startsIn > 0) {
      return false;
    }
    return this.maintenanceInfo.endsIn > 0;
  }

  get siteScheduledMaintenance() {
    if (this.maintenanceInfo == null || this.maintenanceInfo.startsIn <= 0) {
      return false;
    }
    return true;
  }
  protected _navbarItems: (NavbarGroup | NavbarHeader | NavbarItem)[] = [];
  protected _subs: Subscription[] = [];
  private _preloadInterval = null;

  public auth: AuthService;
  private _maintenanceInterval = null;
  private _numBlockingDialogs = 0;
  protected _cdr: ChangeDetectorRef;
  protected _blockUI: BlockUIService;
  protected _router: Router;
  protected _route: ActivatedRoute;

  protected _navbarService: NavbarService;
  protected _store: Store<any>;
  protected _actions: Actions<unknown>;
  protected _modalService: ModalDialogService;
  protected _hotkeyRegistrationService: HotKeyRegistrationService;
  protected _appConfig: AppConfigurationService;
  protected _httpClient: HttpClient;
  protected _appContextService: AppContextService;
  protected _safariPollingService: SafariPollingService;
  private _appRef: ApplicationRef;
  protected get loggerService() {
    if (this._loggerService == null) {
      this._loggerService = this._injector.get(LoggerService);
    }
    return this._loggerService;
  }
  private _loggerService: LoggerService;
  protected get appInsightsService() {
    if (this._appInsightsService == null) {
      this._appInsightsService = this._injector.get(ApplicationInsightsService);
    }
    return this._appInsightsService;
  }
  private _appInsightsService: ApplicationInsightsService;
  private _document: Document;
  protected _appUiReduxObject: AppUiReduxObject;
  private _appErrorReduxObject: AppErrorReduxObject;
  private _appDialogReduxObject: AppDialogUiReduxObject;
  private _routerReduxObject: SafariRouterReduxObject;
  private _authReduxObject: AuthReduxObject;

  private _imagesToPreload = 0;
  private _preloadTimeout = 0;
  showHeader = true;
  showNavbar = true;
  showLoaderOverlay = true;
  navigate = false;
  isDialog = false;
  suppressContainer = false;
  @BlockUI() blockUI: NgBlockUI;
  @ViewChild(NavbarComponent)
  private _navbarComponent;

  @HostListener('document:visibilitychange', ['$event'])
  visibilitychange() {
    if (!document.hidden) {
      this._getMaintenanceInfo();
    }
  }
  #dismissBanner = false;
  isBrowserSupported: boolean;

  // TODO: Once we clean up SafariSmartComponent a bit more it can be moved
  // together with the DumbComponent into something like shared-common-object-models.
  // It won't add much to it and it will allow us to use the same constructs for subscribing
  // (this.subscribe) and for injecting (this.inject)
  __constructor(navbarService: NavbarService = null) {
    this.auth = this._injector.get(AuthService);
    this._appUiReduxObject = this._injector.get(AppUiReduxObject);
    this._appErrorReduxObject = this._injector.get(AppErrorReduxObject);
    this._appDialogReduxObject = this._injector.get(AppDialogUiReduxObject);
    this._blockUI = this._injector.get(BlockUIService);
    this._routerReduxObject = this._injector.get(SafariRouterReduxObject);
    this._authReduxObject = this._injector.get(AuthReduxObject);
    this._router = this._injector.get(Router);
    this._route = this._injector.get(ActivatedRoute);

    this._navbarService = navbarService;
    this._store = this._injector.get(Store);
    this._actions = this._injector.get(Actions);
    this._modalService = this._injector.get(ModalDialogService);
    this._hotkeyRegistrationService = this._injector.get(HotKeyRegistrationService);
    this._appConfig = this._injector.get(AppConfigurationService);
    this._httpClient = this._injector.get(HttpClient);
    this._appContextService = this._injector.get(AppContextService);
    this._safariPollingService = this._injector.get(SafariPollingService);
    this._document = this._injector.get(DOCUMENT);
    this._appRef = this._injector.get(ApplicationRef);
    this._cdr = this._injector.get(ChangeDetectorRef);
    this._hotkeyRegistrationService.registerHotkeys();
    // This is permissive, but we can't guarantee that BrowserAgentCheck is defined. Some companies are blocking the load of this extra script,
    // so we'd rather fail gracefully than tell them they can't use the app.
    this.isBrowserSupported = typeof BrowserAgentCheck !== 'object' || BrowserAgentCheck.isSupported(navigator.userAgent);
  }
  constructor(
    private _injector: Injector,
    @Optional() navbarService: NavbarService = null
  ) {
    this.__constructor(navbarService);

    const modalService = this._injector.get(ModalDialogService);
    modalService.registerComponent(AutologoutDialogComponent.ClassId, AutologoutDialogComponent);
  }
  private lastChild(route: ActivatedRouteSnapshot): ActivatedRouteSnapshot {
    if (route.firstChild) {
      return this.lastChild(route.firstChild);
    } else {
      return route;
    }
  }

  processLayout() {
    const root = this._router.routerState.snapshot.root;
    const child = this.lastChild(root);

    if (child?.data) {
      if (child.data['hideNavbar']) {
        this.showNavbar = false;
      } else {
        this.showNavbar = true;
      }
      if (child.data['hideHeader']) {
        this.showHeader = false;
      } else {
        this.showHeader = true;
      }
      if (child.data['isDialog'] === true) {
        this.isDialog = true;
      } else {
        this.isDialog = false;
      }
      if (child.data['suppressContainer'] === true) {
        this.suppressContainer = true;
      } else {
        this.suppressContainer = false;
      }
      // This could be overriden by some of the forms auto-generation based on the redux mapper type
      if (child.data['pageTitle']) {
        this._store.dispatch(this._appUiReduxObject.default.actions.setPageTitle({ payload: child.data['pageTitle'] }));
      }
      // Set the default background image.  This may be overridden later.
      if (child.data['backgroundImage']) {
        let num = +child.data['backgroundImage'];
        if (num) {
          if (num >= 9) {
            num = num - 8;
          }
          const className = 's_bg-' + num.toString();
          this._store.dispatch(this._appUiReduxObject.default.actions.setBackgroundImageClass({ payload: className }));
        }
      }
    }
  }
  onUiBlock(block: boolean, transparent: boolean) {
    const blockerArray = document.getElementsByTagName('block-ui');
    const blocker = blockerArray?.length > 0 ? blockerArray[0] : null;
    if (block) {
      if (blocker) {
        blocker.className = transparent ? 'transparent' : '';
      }

      this._blockUI.start('block-ui-main');
    } else {
      // Reset releases all regardless of how many blocks were issued
      blocker.className = '';
      this._blockUI.reset('block-ui-main');
    }
  }

  onShowNavbar(value: boolean) {
    this.showHeader = value;
  }
  onShowHeader(value: boolean) {
    this.showNavbar = value;
  }
  onSetIsDialog(value: boolean) {
    this.isDialog = value;
  }

  dispatchStopBlockingUI() {
    this._store.dispatch(this._appUiReduxObject.default.actions.toggleBlockUi({ payload: { value: false } }));
  }
  dispatchStartBlockingUI() {
    this._store.dispatch(this._appUiReduxObject.default.actions.toggleBlockUi({ payload: { value: true } }));
  }
  private set error(value) {
    // We need to investigate why we do this. Could be some really old code or something
    // I'd think it should be able just to throw the error without this set property that
    // then throws the error after a timeout...
    setTimeout(() => {
      throw value;
    }, 100);
  }
  get canShowHeader() {
    if (!this.auth.isAuthenticated() && !this.auth.isLoggingOut) {
      return false;
    }
    return this.showHeader;
  }
  get canShowNavbar() {
    if (!this.auth.isAuthenticated() && !this.auth.isLoggingOut) {
      return false;
    }
    if (this._preloadTimeout === 0) {
      return this.showNavbar;
    }
    return this.showNavbar && this._imagesToPreload === 0;
  }
  private _updateMaintenanceInfoStartEndValues() {
    if (this.maintenanceInfo == null) {
      return;
    }
    const dateNow = new Date();

    //NOTE: We dont' want to use moment here. If we did we'd have to import it and since this is a part of our
    // main bootstrap module that woudl add another 350kb+ causing NG's over limit warning
    this.maintenanceInfo.startsIn = new Date(this.maintenanceInfo.dateTimeStartUtc).getTime() - dateNow.getTime();
    this.maintenanceInfo.endsIn = new Date(this.maintenanceInfo.dateTimeEndUtc).getTime() - dateNow.getTime();
  }
  private _getMaintenanceInfo() {
    if (this._appConfig.statusApp?.apiRootUrl == null) {
      return;
    }
    // Always update this - this function is called during navigation, polling, etc and we want to make sure
    // we always recalculate startsIn so we can show the splash screen when it goes to 0. We don't want to cache these
    // values otherwise they could enter maintenance mode (< 0 time left) and still show just the warning header instead
    // of going to the splash screen
    this._updateMaintenanceInfoStartEndValues();
    const minutesSinceLastRetrieved = (DateTime.now().toMillis() - this.maintenanceInfoLastRetrieved.getTime()) / 60000;
    const cacheTime = parseInt(this._appConfig.statusApp?.cacheDurationInMinutes || '5', 10);

    if (minutesSinceLastRetrieved <= cacheTime) {
      return;
    }

    this._httpClient
      .get<MaintenanceInfo>(`${this._appConfig.statusApp?.apiRootUrl}status?code=${this._appConfig.statusApp?.code}`)
      .pipe(
        catchError(err => {
          this.loggerService.LogError(err, window.location.href);
          return of(null);
        })
      )
      .subscribe(o => {
        this.maintenanceInfoLastRetrieved = new Date();
        if (o != null && o.dateTimeStartUtc != null && o.dateTimeEndUtc != null) {
          const dateNow = new Date();

          //NOTE: We dont' want to use moment here. If we did we'd have to import it and since this is a part of our
          // main bootstrap module that woudl add another 350kb+ causing NG's over limit warning
          const startTime = Date.parse(o.dateTimeStartUtc);
          const endTime = Date.parse(o.dateTimeEndUtc);
          if (!isNaN(startTime) && !isNaN(endTime)) {
            const startsIn = startTime - dateNow.getTime();
            const endsIn = endTime - dateNow.getTime();

            this.maintenanceInfo = {
              ...o,
              ...{
                startsIn,
                endsIn
              }
            };
          } else {
            this.maintenanceInfo = null;
          }
        } else {
          this.maintenanceInfo = null;
        }
      });
  }

  ngOnInit(): void {
    const angularFailedMessage = window.document.getElementById('angularFailedMessage');
    if (angularFailedMessage != null) {
      angularFailedMessage.style.display = 'none';
    }
    this._appRef.isStable.subscribe(isIdle => {
      if (!isIdle) {
        // When we're idling let's update start/end time based on what we have in our most recently cached
        // maintainace info object. This will ensure that the user screen switches from header to splash as soon
        // as they trigger some UI event
        this._updateMaintenanceInfoStartEndValues();
      }
    });
    clearInterval(window['angularFailedInterval']);
    if (this._appConfig.statusApp?.apiRootUrl) {
      this._appRef.isStable.pipe(first(isStable => isStable === true)).subscribe(() => {
        {
          const interval = parseInt(this._appConfig.statusApp?.cacheDurationInMinutes || '5', 10);
          this._maintenanceInterval = this._safariPollingService.setInterval(() => this._getMaintenanceInfo(), interval * 60000);
        }
      });
    }

    const boostrap: AppConfigurationBootstrap = this._appConfig.bootstrap;
    if (boostrap) {
      this._preloadTimeout = boostrap.preloadTimeout;
      if (boostrap.imagesToPreload) {
        this._imagesToPreload = boostrap.imagesToPreload.length;

        for (const image of boostrap.imagesToPreload) {
          const img = new Image();
          img.onload = () => {
            this._imagesToPreload--;
          };
          img.src = image;
        }
      }
    }

    this.backgroundImageClass = '';
    this._subs.push(
      this._store.pipe(select(this._appUiReduxObject.default.selectors.getBackgroundImageClass)).subscribe(backgroundImageClass => {
        this.backgroundImageClass = backgroundImageClass;
        this._cdr.detectChanges();
      })
    );
    if (this._navbarService) {
      this._navbarService.setupNavbarItems(this._navbarItems);
    }
    this._subs.push(this._store.pipe(select(this._appUiReduxObject.default.selectors.getShowNavbar)).subscribe(value => this.onShowNavbar(value)));
    this._subs.push(this._store.pipe(select(this._appUiReduxObject.default.selectors.getShowHeader)).subscribe(value => this.onShowHeader(value)));
    this._subs.push(this._store.pipe(select(this._appUiReduxObject.default.selectors.getIsDialog)).subscribe(value => this.onSetIsDialog(value)));
    this._subs.push(
      this._store.pipe(select(this._appUiReduxObject.default.selectors.getBlockUi)).subscribe(state => {
        if (state != null) {
          this.onUiBlock(state.value, state.transparent);
        } else {
          this.onUiBlock(false, false);
        }
      })
    );

    this._subs.push(
      this._store
        .pipe(
          select(this._appDialogReduxObject.default.selectors.getDialogRequest),
          filter(o => o != null)
        )
        .subscribe(o => {
          if (!o.noUiBlock) {
            this.dispatchStartBlockingUI();
            this._numBlockingDialogs++;
          }

          this._modalService.showModal(o);
        })
    );
    this._subs.push(
      this._store
        .pipe(
          select(this._appDialogReduxObject.default.selectors.getDialogResponse),
          filter(o => o != null)
        )
        .subscribe(o => {
          if (!o.noUiBlock) {
            this._numBlockingDialogs--;
            this.dispatchStopBlockingUI();
          }
        })
    );
    this._subs.push(
      this._store
        .pipe(
          select(this._appErrorReduxObject.default.selectors.getlastError),
          filter(o => o != null)
        )
        .subscribe(o => {
          // NOTE !!!
          // The "error" thrown after this assignment happens will be taken from lastErrorState
          // and will contain another error property as well as potentially payload, silent, etc.
          // Example of lastErrorState:
          //    error: { error: HttpInstanceError, status... } (we keep the original error object as-is in this error property)
          //    payload: {a:1, b:2} (original payload)
          //    someOtherOption: x (mostly this will be options object)
          // To be consistent with errors that might be thrown from JS directly (thus bypassing errors stored in state)
          // we're just going to send the actual error, and not other info
          let error = null;

          if (o.error instanceof Error) {
            error = new Error(o.error.message);
            error.stack = o.error.stack;
          } else if (typeof o.error === 'string' || o.error instanceof String) {
            error = new Error(o.error);
          } else {
            error = cloneDeep(o.error);
          }
          error.source = o.source;
          error.silent = o.silent;
          error.uiOption = o.uiOption;
          error.maxValidationErrors = o.maxValidationErrors;
          this.error = error;
        })
    );

    this._router.events.subscribe(evt => {
      if (evt instanceof NavigationStart) {
        // Set state will set state to this payload IF there isn't something already in the state
        // This will be the case when direct navlinks are clicked (hrefs as opposed to explicit dispatchnavigate backedn calls)
        // In turn this will allow navigation effect to see if there was a prior HREF link that was clicked before the effect was called
        // This would happen if for example a link was clicked, save dialog popped up, we chose to save and then the code tried to redirect
        // to default save (usually a list)
        this._store.dispatch(this._routerReduxObject.default.actions.setState({ payload: { path: [evt.url], checkDirty: true } }));
        return;
      }
      if (evt instanceof NavigationCancel) {
        this.dispatchStopBlockingUI();
        this._store.dispatch(this._routerReduxObject.default.actions.clearState());
        return;
      }
      if (evt instanceof NavigationError) {
        this.dispatchStopBlockingUI();
        this._store.dispatch(this._routerReduxObject.default.actions.clearState());
        // We are going to wrap everything here in try/catch just to make sure no exceptions
        // get thrown from any parsing, etc. If they do they will not retry chunking but at least
        // they won't break anything
        // CHUNKERROR COMMENTS
        // The code below is specifically set up for handling ChunkLoadError when THE MODULE WHOSE
        // URL WE ARE ACCESSING IS THE ONE THAT'S FAILING. This is different than when the background
        // module fails due to Chunk error. In the case of requested URL failing to due Chunk module
        // error we will get navigation error. In this particular case this is our last chance to retrieve
        // the URL they tried to get to. By the time it gets to error handler Angular will drop the URL
        // and set it to origin and we will have no way of knowing where they tried to go to.
        // This will NEVER be called if Chunk error was due to failure while trying to load background module
        // (the one that you are not asking the URL for but instead is preloading in the background)
        // For better understanding of all the pieces just search for "CHUNKERROR COMMENTS" throughout the code.
        try {
          // This will only happen IF chunkload happens on the module we're actually navigating to
          // If it happens on the module that is being loaded in the background this will not be
          // called here, but instead handling is in the error page itself
          if (evt.error.name === 'ChunkLoadError') {
            const urlWithOrigin = `${window.location.origin}${evt.url}`;
            // CHUNKERROR COMMENTS
            // This code will retry and go the right URL that we will extract from the event
            // Note "true" in the second param - that tells this function to throw a final error if retries are exhausted
            // We need this because main module chunk errors are going to be silenced and we need some final non-chunk error to be thrown
            // once the retries are complete. The function below just throws a plain JS error if retries are exhausted.
            // For better understanding of all the pieces just search for "CHUNKERROR COMMENTS" throughout the code.
            // For better understanding of all the pieces just search for "CHUNKERROR COMMENTS" throughout the code.
            ErrorHandlerService.retryChunkError(urlWithOrigin, true);
          } else {
            this._processNavigationError(evt);
          }
        } catch (err) {
          this.loggerService.LogError(err, window.location.href);
          this._processNavigationError(evt);
        }
        return;
      }
      if (!(evt instanceof NavigationEnd)) {
        return;
      }
      if (!evt.url.endsWith('/logout')) {
        this._store.dispatch(this._authReduxObject.default.actions.updateLastActivityTimestamp());
      }
      if (this._appConfig.statusApp?.apiRootUrl) {
        this._getMaintenanceInfo();
      } else {
        // We didn't find any maintenance info so we'll just set it to NULL
        // (to reduce flicker we don't render on maintenanceInfo = undefined and use
        // null to signal that we've retrieved but there isn't anything there , vs undefined
        // meaning we haven't retrieved yet)
        this.maintenanceInfo = null;
      }

      this._store.dispatch(this._routerReduxObject.default.actions.clearState());
      this.processLayout();
      if (this._navbarComponent && this._navbarService) {
        // If both navbar and service are present reload items
        this._navbarComponent.reloadItems();
      } else if (this._navbarComponent) {
        // Otherwise if navbar compoent is present but service was not passed into the constructor throw an error
        throw new Error('Navbar service must be passed in when navbar component is present!');
      }

      // For some reason we had setTimeout wrapping this which was causing a small blink during
      // page transitions. Not sure why it was there - maybe from some old design, let's commetn out
      // and see if it causes any issues
      // setTimeout(() => {

      this.dispatchStopBlockingUI();
      this.OnNavigationEnd(evt);
      // });
    });
    this.processLayout();
  }

  protected OnNavigationEnd(evt: NavigationEnd) {}
  private _processNavigationError(evt) {
    evt.error.statusText = 'Navigation Error';
    evt.error.url = evt.url;
  }
  private getActivatedComponent(snapshot: ActivatedRouteSnapshot): any {
    if (snapshot.firstChild) {
      return this.getActivatedComponent(snapshot.firstChild);
    }

    return snapshot.component;
  }

  private getRouteTemplate(snapshot: ActivatedRouteSnapshot): string {
    let path = '';
    if (snapshot.routeConfig) {
      path += snapshot.routeConfig.path;
    }

    if (snapshot.firstChild) {
      return path + this.getRouteTemplate(snapshot.firstChild);
    }

    return path;
  }

  ngOnDestroy(): void {
    if (this._maintenanceInterval) {
      clearInterval(this._maintenanceInterval);
    }
    this._subs.forEach(sub => sub.unsubscribe());
  }

  shouldShowBanner() {
    return !this.isBrowserSupported && !this.#dismissBanner;
  }

  dismissBanner() {
    this.#dismissBanner = true;
  }

  /**
   * Returns the CSS class for the router-outlet content div below the header and navbar divs.
   * s_lpo is currently used for dialogs.
   */
  get containerClass() {
    if (this.isDialog) {
      return 's_lpo';
    }

    return this.suppressContainer ? '' : 'container';
  }

  ngAfterViewInit(): void {
    let intervalCount = 0;
    const intervalLength = 10;
    // Start preloading interval - we're using interval so that we can
    // count up to "preloadTimeout" and bail out if it doesn't happen by then
    this._preloadInterval = setInterval(() => {
      if (this._imagesToPreload === 0 || intervalCount * intervalLength > this._preloadTimeout) {
        // If we either preloaded all images or have hit max timeout we're going to do whatever we need to do for bootstrapping
        // Currently we only have navbar to worry about. Hopefully it stays that way...
        this._preloadTimeout = 0;
        this._imagesToPreload = 0;
        // If this application has navbar then let's remove fake navbar that's blocking the view

        clearInterval(this._preloadInterval);
      }
      intervalCount++;
    }, intervalLength);
  }
}
