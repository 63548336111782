import { Component, Input, OnDestroy, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { cancelAllFiles, clearAllFileStores, FileOperationType, TransferDialogMessage } from '@safarilaw-webapp/shared/common-objects-models';
import { ConfirmationDialogButton } from '@safarilaw-webapp/shared/dialog/store-access';

import { DateTime } from 'luxon';
import { ModalDirective, ModalModule } from 'ngx-bootstrap/modal';
import { Subscription } from 'rxjs';
import { SafariSmartComponent } from '../../../../forms/components/safari-base/safari-base.component';
import { FileTransferTableComponent } from '../file-transfer-table/file-transfer-table.component';

export enum DialogSize {
  Large,
  ExtraLarge
}

class DialogTransferDialogMessage extends TransferDialogMessage {
  timeStampFirstMessage: number;
  readyToShow: boolean;
}
@Component({
  imports: [ModalModule, FileTransferTableComponent],
  selector: 'sl-ui-kit-file-transfer-dialog',
  templateUrl: './file-transfer-dialog.component.html',
  styleUrls: ['./file-transfer-dialog.component.scss']
})
export class FileTransferDialogComponent extends SafariSmartComponent implements OnInit, OnDestroy {
  @ViewChild('template')
  private _modalTemplate: TemplateRef<any>;
  @ViewChild(ModalDirective, { static: false }) modal?: ModalDirective;

  defaultFilter: any;

  private _dialogSizeClass = 'modal-lg';
  get dialogSizeClass() {
    return this._dialogSizeClass;
  }
  @Input()
  set dialogSize(value: DialogSize) {
    if (value === DialogSize.ExtraLarge) {
      this._dialogSizeClass = 'modal-xl';
    } else {
      this._dialogSizeClass = 'modal-lg';
    }
  }

  @Input()
  set filesFromStore(value: TransferDialogMessage[]) {
    this.addFilesFromStore(value);
  }

  protected _closeDialogRequestedBy: ConfirmationDialogButton = null;

  data: any;
  dialogData: any;

  private _modalOpen = false;
  protected _fileUploadSubs: Subscription[] = [];
  private _onHiddenSub: Subscription = null;
  private _onShownSub: Subscription = null;
  private _interval = null;
  private _dismissedByUser = false;
  private _wasCancelled = false;
  private _showDetailsMessage = 'show details';
  private _hideDetailsMessage = 'hide details';
  private _isHiding = false;
  // This is the true "shown" flag, as it will be set only after the dialog
  // has finished CSS transitions
  private _isShown = false;
  get isHiding() {
    return this._isHiding;
  }
  @Input()
  id: string;

  detailButtonName = this._showDetailsMessage;
  percentUploaded = 0;
  totalUploadSize = 0;
  totalFilesToRemove = 0;
  totalFilesToMove = 0;
  uploadedSoFar = 0;
  removedSoFar = 0;
  movedSoFar = 0;
  totalFilesToUpdate = 0;
  updatedSoFar = 0;
  percentRemoved = 0;
  percentMoved = 0;
  percentUpdated = 0;
  percentDownloaded = 0;
  numUploadErrors = 0;
  numMoveErrors = 0;
  numRemoveErrors = 0;
  numUpdateErrors = 0;
  protected _showDetails = false;
  get showDetails() {
    return this._showDetails;
  }
  set showDetails(value: boolean) {
    this._showDetails = value;
    if (this._showDetails) {
      this.detailButtonName = this._hideDetailsMessage;
    } else {
      this.detailButtonName = this._showDetailsMessage;
    }
  }
  protected _filesToAdd: DialogTransferDialogMessage[] = [];
  protected _filesToRemove: DialogTransferDialogMessage[] = [];
  protected _filesToMove: DialogTransferDialogMessage[] = [];
  protected _filesToDownload: DialogTransferDialogMessage[] = [];

  get filesToDownload() {
    return this._filesToDownload.filter(o => o.readyToShow == true);
  }
  get filesToAdd() {
    return this._filesToAdd.filter(o => o.readyToShow == true);
  }
  get filesToRemove() {
    return this._filesToRemove.filter(o => o.readyToShow == true);
  }
  get filesToMove() {
    return this._filesToMove.filter(o => o.readyToShow == true);
  }
  get hasFilesToUpload() {
    return this.filesToAdd.length && this.filesToAdd.find(o => o.fileOperationType != FileOperationType.Update) != null;
  }
  get hasFilesToUpdate() {
    return this.filesToAdd.length && this.filesToAdd.find(o => o.fileOperationType == FileOperationType.Update) != null;
  }

  allFiles = [];
  public get uploadCompleteWithErrors() {
    // This used to return false if the dialog was manually dismissed by user regardless of whether
    // there were errors or not. I m not sure why it was written that way but it was causing a weird
    // glitch in auto-changing the filter from "Errors Only" to "All" when the user clicks OK
    // (we set the filter to "errors only" automatically when upload ends with errors)
    // For now, going to just comment it out and we'll see if there are any weird issues
    //
    // if (this._dismissedByUser) {
    //   return false;
    // }
    if (this.allFiles.find(o => o.percentComplete < 100)) {
      return false;
    }
    return this.allFiles.find(o => o.isError) != null;
  }
  public toggleDetails() {
    this.showDetails = !this.showDetails;
  }
  public get uploadComplete() {
    if (this.filesToRemove.length > 0 && this.filesToRemove.find(o => o.percentComplete < 100)) {
      return false;
    }
    if (this.filesToMove.length > 0 && this.filesToMove.find(o => o.percentComplete < 100)) {
      return false;
    }
    if (this.filesToAdd.length > 0 && this.filesToAdd.find(o => o.percentComplete < 100)) {
      return false;
    }
    if (this.filesToDownload.length > 0 && this.filesToDownload.find(o => o.percentComplete < 100)) {
      return false;
    }
    return true;
  }
  private get _hadErrors() {
    return (this.filesToDownload.find(o => o.isError) || this.filesToAdd.find(o => o.isError) || this.filesToRemove.find(o => o.isError) || this.filesToMove.find(o => o.isError)) != null;
  }
  public get uploadCompleteWithoutErrors() {
    if (!this._dismissedByUser) {
      if (this._hadErrors) {
        return false;
      }
      return this.uploadComplete;
    }
    return true;
  }
  constructor() {
    super();
    // Hardcoded, not customizable. There is always one file transfer dialog
    this.componentId = 'file-transfer-dialog';
  }
  protected clearInterval() {
    if (this._interval != null) {
      clearInterval(this._interval);
      this._interval = null;
    }
  }
  getButtonName() {
    if (!this.uploadComplete) {
      return 'CANCEL';
    }
    return 'OK';
  }
  cancel() {
    this.clearInterval();
    this._dismissedByUser = true;
    this._wasCancelled = this._dismissedByUser && !this.uploadComplete;

    this._store.dispatch(cancelAllFiles());
  }
  dispatchClearInfoMessage(): void {
    this._store.dispatch(
      clearAllFileStores({
        id: '0',
        fileTransferDialogId: this.id,
        wasCancelled: this._wasCancelled,
        hadErrors: this._hadErrors
      })
    );

    this._wasCancelled = false;
  }
  getUploadErrorsMessage() {
    if (this.filesToAdd.find(o => o.message === 'Cancelled')) {
      return '( Cancelled )';
    }
    return `( ${this.numUploadErrors} error(s) occurred )`;
  }
  getRemoveErrorsMessage() {
    if (this.filesToRemove.find(o => o.message === 'Cancelled')) {
      return '( Cancelled )';
    }
    return `( ${this.numRemoveErrors} error(s) occurred )`;
  }
  getMoveErrorsMessage() {
    if (this.filesToMove.find(o => o.message === 'Cancelled')) {
      return '( Cancelled )';
    }
    return `( ${this.numMoveErrors} error(s) occurred )`;
  }
  getUpdateErrorsMessage() {
    if (this.filesToRemove.find(o => o.fileOperationType == FileOperationType.Update && o.message === 'Cancelled')) {
      return '( Cancelled )';
    }
    return `( ${this.numUpdateErrors} error(s) occurred )`;
  }
  ngOnDestroy(): void {
    this.clearInterval();

    for (const fileUploadSub of this._fileUploadSubs) {
      if (fileUploadSub) {
        fileUploadSub.unsubscribe();
      }
    }

    if (this._onHiddenSub) {
      this._onHiddenSub.unsubscribe();
      this._onHiddenSub = null;
    }
    if (this._onShownSub) {
      this._onShownSub.unsubscribe();
      this._onShownSub = null;
    }
  }
  getUploadMessage() {
    if (this.percentUploaded === 100 && this.filesToAdd.find(o => o.fileOperationType != FileOperationType.Update && o.percentComplete != 100)) {
      return 'Finalizing...';
    }
    if (this.percentUploaded === 100 && this.filesToAdd.find(o => o.fileOperationType != FileOperationType.Update && o.percentComplete != 100) == null) {
      return 'Completed...';
    }
    let uploadedSoFar = this.uploadedSoFar;
    let totalUploadSize = this.totalUploadSize;
    let sizeMesure = 'B';
    if (this.totalUploadSize >= 1000000) {
      uploadedSoFar /= 1000000;
      totalUploadSize /= 1000000;
      sizeMesure = 'MB';
    } else if (this.totalUploadSize >= 1000) {
      uploadedSoFar /= 1000;
      totalUploadSize /= 1000;
      sizeMesure = 'KB';
    }
    return `Upload progress: ${uploadedSoFar.toFixed(1)} ${sizeMesure} of ${totalUploadSize.toFixed(1)} ${sizeMesure} (${this.percentUploaded}%)`;
  }
  protected _recalculateProgress() {
    let totalSize = 0;
    let totalProcessed = 0;
    this.numUploadErrors = 0;
    this.numRemoveErrors = 0;
    this.numMoveErrors = 0;
    this.numUpdateErrors = 0;
    this.percentRemoved = 0;
    this.percentUploaded = 0;
    const filesToUpload = this.filesToAdd.filter(o => o.fileOperationType != FileOperationType.Update);
    filesToUpload.forEach(element => {
      if (element.isError) {
        this.numUploadErrors++;
      }
      totalSize += element.totalSize;
      totalProcessed += element.totalProcessed;
    });
    if (filesToUpload.find(o => o.percentComplete < 100) == null) {
      this.percentUploaded = 100;
    } else if (totalSize == 0 || totalProcessed == 0) {
      this.percentUploaded = 0;
    } else {
      this.percentUploaded = Math.round((100 * totalProcessed) / totalSize);
    }
    this.totalUploadSize = totalSize;
    this.uploadedSoFar = totalProcessed;
    // Now removals... they are different since there is no concept of "size" and "bytes deleted"
    // so we'll calculate that as "x fully removed of y requested to be removed"
    totalSize = this.filesToRemove.length;
    this.filesToRemove.forEach(element => {
      if (element.isError) {
        this.numRemoveErrors++;
      }
    });
    totalProcessed = this.filesToRemove.filter(o => o.percentComplete == 100).length;
    if (totalSize == 0 || totalProcessed == 0) {
      this.percentRemoved = 0;
    } else {
      this.percentRemoved = Math.round((100 * totalProcessed) / totalSize);
    }
    this.totalFilesToRemove = totalSize;
    this.removedSoFar = totalProcessed;
    // Moves... Similar to removes
    totalSize = this.filesToMove.length;
    this.filesToMove.forEach(element => {
      if (element.isError) {
        this.numMoveErrors++;
      }
    });
    totalProcessed = this.filesToMove.filter(o => o.percentComplete == 100).length;
    if (totalSize == 0 || totalProcessed == 0) {
      this.percentMoved = 0;
    } else {
      this.percentMoved = Math.round((100 * totalProcessed) / totalSize);
    }
    this.totalFilesToMove = totalSize;
    this.movedSoFar = totalProcessed;
    // Now updates - they will be similar to removals as there is no concept of bytes processed
    const filesToUpdate = this.filesToAdd.filter(o => o.fileOperationType == FileOperationType.Update);
    totalSize = filesToUpdate.length;
    filesToUpdate.forEach(element => {
      if (element.isError) {
        this.numUpdateErrors++;
      }
    });
    totalProcessed = filesToUpdate.filter(o => o.percentComplete == 100).length;
    if (totalSize == 0 || totalProcessed == 0) {
      this.percentUpdated = 0;
    } else {
      this.percentUpdated = Math.round((100 * totalProcessed) / totalSize);
    }
    this.totalFilesToUpdate = totalSize;
    this.updatedSoFar = totalProcessed;

    if (this.uploadCompleteWithErrors) {
      this.showDetails = true;
    }
  }
  private _recalcAllFiles() {
    this.allFiles = [...this.filesToAdd, ...this.filesToDownload, ...this.filesToRemove, ...this.filesToMove];
  }
  protected addFilesFromStore(filesFromStore: TransferDialogMessage[]) {
    if (filesFromStore && filesFromStore.length > 0) {
      if (this._interval == null) {
        this._interval = setInterval(() => {
          if (this.uploadCompleteWithoutErrors && this._isShown) {
            this.clearInterval();
            this.dispatchClearInfoMessage();
            this.closeModal();
          }
        }, 1000);
      }
      if (!this._modalOpen) {
        // If reopening reset relevant flags
        this.showDetails = false;
        this._dismissedByUser = false;
      }
      // We are not opening the modal as soon as some files come in
      // some of the files might actually be flagged as "do not show" dialog
      // but they will let dialog override that behavior
      if (this.filesToAdd.length > 0 || this.filesToRemove.length > 0 || this.filesToMove.length > 0) {
        this.openModal();
      }
    }

    for (const fileFromStore of filesFromStore) {
      let array = [];
      if (fileFromStore.fileOperationType === FileOperationType.Add || fileFromStore.fileOperationType === FileOperationType.Update) {
        array = this._filesToAdd;
      } else if (fileFromStore.fileOperationType === FileOperationType.Remove) {
        array = this._filesToRemove;
      } else if (fileFromStore.fileOperationType === FileOperationType.Download) {
        array = this._filesToDownload;
      } else if (fileFromStore.fileOperationType === FileOperationType.Move) {
        array = this._filesToMove;
      } else {
        throw new Error('Unknown file operation type: ' + fileFromStore.fileOperationType.toString());
      }
      const file = array.find(o => o.actionId === fileFromStore.actionId) as DialogTransferDialogMessage;

      if (file != null) {
        // There should be no more messages after 100 pct completion unless it was a global "cancel"
        // message. Don't overwrite possibly completed or errored files with "cancelled"
        if (file.percentComplete !== 100) {
          file.percentComplete = fileFromStore.percentComplete;
          file.totalSize = fileFromStore.totalSize;
          file.totalProcessed = fileFromStore.totalProcessed;

          file.isError = fileFromStore.isError;
          file.message = fileFromStore.message;

          file.secondsUntilTransferDialogShown = fileFromStore.secondsUntilTransferDialogShown;
          if (file.secondsUntilTransferDialogShown > 0) {
            if (file.timeStampFirstMessage == null) {
              file.timeStampFirstMessage = DateTime.now().toMillis();
            } else if (file.readyToShow == false) {
              const currentDate = DateTime.now().toMillis();
              const diff = (currentDate - file.timeStampFirstMessage) / 1000;

              const transferSpeedInBytes = file.totalProcessed / diff;
              const transferTimeInSeconds = file.totalSize / transferSpeedInBytes;
              if (transferTimeInSeconds >= file.secondsUntilTransferDialogShown) {
                file.readyToShow = true;
              }
            }
          } else {
            file.readyToShow = true;
          }
        }
      } else {
        array.push({ ...fileFromStore, ...{ timeStampFirstMessage: null, readyToShow: fileFromStore.secondsUntilTransferDialogShown > 0 ? false : true } } as DialogTransferDialogMessage);
      }
    }
    this._recalcAllFiles();
    if (this.uploadCompleteWithErrors) {
      // TODO: Need to revise this. There is no need to call this remotely when table is a part
      // of this components hierarchy
      this.defaultFilter = { status: 2 };
    } else {
      this.defaultFilter = null;
    }
    // We don't support downloading while uploading/removing so if there are any files to download we will present download dialog
    // with the table open right away. This is temporary anyway until we replace our download dialog with browser's regular download
    if (this.filesToDownload.length > 0) {
      this.showDetails = true;
      return;
    }
    this._recalculateProgress();
  }

  ngOnInit() {
    this._dismissedByUser = false;
  }
  openModal(optionOverrides = {}) {
    this._isHiding = false;

    if (this._modalOpen) {
      return;
    }

    this._modalOpen = true;

    this.modal.config = {
      ...{
        backdrop: 'static',
        keyboard: false,
        animated: true,
        class: this.dialogSizeClass,
        ignoreBackdropClick: true
      },
      ...optionOverrides
    };

    this._onHiddenSub = this.modal.onHidden.subscribe((o: any) => {
      this._isShown = false;
      this._filesToAdd = [];
      this._filesToRemove = [];
      this._filesToDownload = [];
      this._filesToMove = [];
      this.allFiles = [];
    });
    this._onShownSub = this.modal.onShown.subscribe(o => {
      this._isShown = true;
    });
    this.modal.show();
  }
  closeModal(dialogButton: ConfirmationDialogButton = ConfirmationDialogButton.Auto) {
    if (!this._modalOpen) {
      return;
    }
    this.hideDialog();
    this._closeDialogRequestedBy = dialogButton;
    if (this._closeDialogRequestedBy) {
      this._store.dispatch(
        this._appDialogUiReduxObject.default.actions.modalDialogDismissed({
          payload: { buttonType: this._closeDialogRequestedBy, id: this.id, noUiBlock: false, parentData: this.data, dialogData: this.dialogData }
        })
      );
    }
  }

  private hideDialog() {
    if (!this._modalOpen) {
      return;
    }

    this._isHiding = true;
    // Not sure that this could be NULL but let's check anyway
    if (this.modal) {
      // If we ever wanted to prevent dialog close from unblocking UI we'd have to send something meaningful
      // here - so not just ID, but rather an object with some info. The weird thing is that this function
      // accepts string, but we can probably use JSON.stringify and unpack in listener. We just have to go through
      // the existing code and make sure to update whatever may be listening on this
      this.modal.dismissReason = this.id;
      this.modal.hide();
    }

    this._modalOpen = false;
  }
}
