import { CommonModule } from '@angular/common';
import { Component, Input, OnInit } from '@angular/core';
import { DropdownFilterComponent } from '../../../../list/components/filters/dropdown-filter/dropdown-filter.component';
import { SafariListComponent } from '../../../../list/components/safari-list/safari-list.component';

@Component({
  imports: [SafariListComponent.standaloneImports, CommonModule, DropdownFilterComponent],
  selector: 'sl-ui-kit-file-transfer-table',
  templateUrl: './file-transfer-table.component.html',
  styleUrls: ['./file-transfer-table.component.scss']
})
export class FileTransferTableComponent extends SafariListComponent<any> implements OnInit {
  static listId = 'shared-ui-file-transfer-dialog-table';

  @Input()
  hasPreviewFiles = false;

  get headerHeight() {
    if (this.hasPreviewFiles) {
      return 1;
    }
    return 35;
  }
  constructor() {
    super();
    this._doNotStoreTableRef = true;
    this.sort = 'displayFilename';
    this.filter = { status: 1 };
    this.componentId = FileTransferTableComponent.listId;
  }
  ngOnInit() {
    this.columns[1] = {
      minWidth: 200,
      width: 200,
      flexGrow: 0
    };
    super.ngOnInit();
  }
  onInMemoryFilter(obj, filter) {
    if (filter.status == 2) {
      return obj.isError;
    } else if (filter.status == 3) {
      return !obj.isError;
    } else {
      return true;
    }
  }
}
