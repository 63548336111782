import { Component } from '@angular/core';
import { Actions } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { AppDialogUiReduxObject } from '../../../state/actions/dialog-actions';
import { ConfirmationDialogButton } from '../../models/confirmation-dialog-payload';
import { DialogBaseComponent } from '../dialog-base/dialog-base.component';
import { NgClass } from '@angular/common';

@Component({
  selector: 'sl-dialog-confirmation-dialog',
  templateUrl: './confirmation-dialog.component.html',
  styleUrls: ['./confirmation-dialog.component.scss'],
  imports: [NgClass]
})
export class ConfirmationDialogComponent extends DialogBaseComponent {
  content: string;
  title: string;
  buttons: ConfirmationDialogButton.Ok;
  id: string;
  data: any;
  dialogData: any;
  okBtnName = 'OK';
  okBtnSubtext = '';
  cancelBtnName = 'CANCEL';
  cancelBtnSubtext = '';
  aux1BtnName = '';
  aux1BtnSubtext = '';
  okBtnClass = '';
  cancelBtnClass = '';
  aux1BtnClass = '';
  closeDialogRequestedBy: ConfirmationDialogButton = null;
  ConfirmationDialogButton = ConfirmationDialogButton;
  constructor(dialogReduxObject: AppDialogUiReduxObject, bsModalService: BsModalService, store: Store<any>, actions: Actions, bsModalRef: BsModalRef) {
    super(dialogReduxObject, bsModalService, store, actions, bsModalRef);
  }

  hasOk() {
    // eslint-disable-next-line no-bitwise -- intended
    return this.buttons & ConfirmationDialogButton.Ok;
  }
  hasOkSubtext() {
    return !String.isNullOrEmpty(this.okBtnSubtext);
  }
  getOkButtonClass() {
    return this.okBtnClass ? this.okBtnClass : 'btn-primary';
  }
  hasCancel() {
    // eslint-disable-next-line no-bitwise -- intended
    return this.buttons & ConfirmationDialogButton.Cancel;
  }
  hasCancelSubtext() {
    return !String.isNullOrEmpty(this.cancelBtnSubtext);
  }
  getCancelButtonClass() {
    return this.cancelBtnClass ? this.cancelBtnClass : 'btn-outline-secondary';
  }
  hasAux1() {
    // eslint-disable-next-line no-bitwise -- intended
    return this.buttons & ConfirmationDialogButton.Aux1;
  }
  hasAux1Subtext() {
    return !String.isNullOrEmpty(this.aux1BtnSubtext);
  }
  getAux1ButtonClass() {
    return this.aux1BtnClass ? this.aux1BtnClass : ' btn-outline-secondary';
  }
}
