import { Action, ActionCreator, DefaultProjectorFn, MemoizedSelector, createAction, props } from '@ngrx/store';
import { ActionReducer } from '@ngrx/store/src/models';
import { FileOperationActions } from '../enums';
import { BulkFileOperationRequest, ClearFileInfoPayload, FilePreviewRequest, FilePreviewResponse, TransferDialogMessage } from './file-operation';

const createCancelTransferAction = (type: any) => {
  if (String.isNullOrEmpty(type)) {
    return null;
  }
  return createAction(type, props<{ abort?: any }>());
};
const createProcessFileFailAction = (type: any) => {
  if (String.isNullOrEmpty(type)) {
    return null;
  }
  return createAction(type, props<{ payload: TransferDialogMessage }>());
};
const createProcessFileSuccessAction = (type: any) => {
  if (String.isNullOrEmpty(type)) {
    return null;
  }
  return createAction(type, props<{ payload: TransferDialogMessage }>());
};
const createUpdateFileProgressAction = (type: any) => {
  if (String.isNullOrEmpty(type)) {
    return null;
  }
  return createAction(type, props<{ payload: TransferDialogMessage }>());
};
const createClearFileInfoAction = (type: any) => {
  if (String.isNullOrEmpty(type)) {
    return null;
  }
  // TODO: in the backend release this should be wrapped in payload property first, to be consistent with the rest
  // of the actions
  return createAction(type, props<ClearFileInfoPayload>());
};

const createPrepareForBulkTransferAction = (type: any) => {
  if (String.isNullOrEmpty(type)) {
    return null;
  }
  return createAction(type, props<{ payload: BulkFileOperationRequest }>());
};

export class DefaultFileActionConverter {
  constructor(
    private _actions: {
      cancelTransfer?: ActionCreator<any, (props: { abort?: any }) => { abort?: any } & Action<string>>;
      processFileFail?: ActionCreator<any, (props: { payload: TransferDialogMessage }) => { payload: TransferDialogMessage } & Action<string>>;
      processFileSuccess?: ActionCreator<any, (props: { payload: TransferDialogMessage }) => { payload: TransferDialogMessage } & Action<string>>;
      updateFileUploadProgress?: ActionCreator<any, (props: { payload: TransferDialogMessage }) => { payload: TransferDialogMessage } & Action<string>>;
      clearFileInfoFromStore?: ActionCreator<any, (props: ClearFileInfoPayload) => ClearFileInfoPayload & Action<string>>;
      updateFileDownloadProgress?: ActionCreator<any, (props: { payload: TransferDialogMessage }) => { payload: TransferDialogMessage } & Action<string>>;
      prepareForBulkTransfer?: ActionCreator<any, (props: { payload: BulkFileOperationRequest }) => { payload: BulkFileOperationRequest } & Action<string>>;
      previewFile?: ActionCreator<any, (props: { payload: FilePreviewRequest }) => { payload: FilePreviewRequest } & Action<string>>;
      previewFileClosed?: ActionCreator<any, (props: { payload: FilePreviewResponse }) => { payload: FilePreviewResponse } & Action<string>>;
      previewFileEdit?: ActionCreator<any, (props: { payload: FilePreviewResponse }) => { payload: FilePreviewResponse } & Action<string>>;
    }
  ) {}
  get cancelTransfer() {
    return this._actions.cancelTransfer;
  }
  get processFileFail() {
    return this._actions.processFileFail;
  }
  get processFileSuccess() {
    return this._actions.processFileSuccess;
  }
  get updateFileUploadProgress() {
    return this._actions.updateFileUploadProgress;
  }
  get clearFileInfoFromStore() {
    return this._actions.clearFileInfoFromStore;
  }
  get updateFileDownloadProgress() {
    return this._actions.updateFileDownloadProgress;
  }
  get prepareForBulkTransfer() {
    return this._actions.prepareForBulkTransfer;
  }
  get previewFile() {
    return this._actions.previewFile;
  }
  get previewFileClosed() {
    return this._actions.previewFileClosed;
  }
  get previewFileEdit() {
    return this._actions.previewFileEdit;
  }
}

export const cancelAllFiles = createAction('[*] File Transfer: Cancel');
export const clearAllFileStores = createAction('[*] File Transfer: Clear All Info', props<ClearFileInfoPayload>());
export const populateDefaultFileActions = (actionMap: Map<number, string>) =>
  new DefaultFileActionConverter({
    cancelTransfer: createCancelTransferAction(actionMap.get(FileOperationActions.CancelTransfer)),
    clearFileInfoFromStore: createClearFileInfoAction(actionMap.get(FileOperationActions.ClearFileInfoFromStore)),
    prepareForBulkTransfer: createPrepareForBulkTransferAction(actionMap.get(FileOperationActions.PrepareForBulkTransfer)),

    processFileFail: createProcessFileFailAction(actionMap.get(FileOperationActions.ProcessFileFail)),
    processFileSuccess: createProcessFileSuccessAction(actionMap.get(FileOperationActions.ProcessFileSuccess)),
    updateFileDownloadProgress: createUpdateFileProgressAction(actionMap.get(FileOperationActions.UpdateFileDownloadProgress)),
    updateFileUploadProgress: createUpdateFileProgressAction(actionMap.get(FileOperationActions.UpdateFileUploadProgress))
  });
/**
 * This object defines sets of actions and selectors that are used by the file transfer dialog within a particular feature (lpms, rms, etc.)
 * The only difference between the objects is really just a project name.
 * Every new feature needs to provide an instance of this object specific to the feature  (exameple: feature-lpms=-data-access.module.ts)
 * Any service within that project that needs to interact with the file transfer dialog should inject this object as well (example: COMPANY_ENTITY_LOGO_FILE_OBJECT_SERVICE_TOKEN)
 * Maybe in the future we'll have all services inject this automatically and if they don't use it, no big deal. But at the same time there are only a handful of services that need this,
 * so injecting manually isn't a big deal either...
 */
export class SafariReduxTransferDialogObject {
  constructor(
    private _fileActions: DefaultFileActionConverter,
    private _reducer: ActionReducer<any, Action>,
    private _fileSelectors: DefaultFileSelectorConverter
  ) {}

  get default() {
    return { actions: this._fileActions, selectors: this._fileSelectors };
  }
  get reducer() {
    return this._reducer;
  }
}

export class DefaultFileSelectorConverter {
  constructor(private _selectors: { fileOperations?: MemoizedSelector<object, TransferDialogMessage[], DefaultProjectorFn<TransferDialogMessage[]>> }) {}
  get fileOperations() {
    return this._selectors.fileOperations;
  }
}
