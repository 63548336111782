import { Location } from '@angular/common';
import { AfterViewInit, Directive, Input, OnDestroy, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { Actions, ofType } from '@ngrx/effects';
import { ReduxWrapperService, reduxActionFail } from '@safarilaw-webapp/shared/redux';
import { DatatableComponent, SelectionType } from '@siemens/ngx-datatable';
import { cloneDeep, isEqual, memoize } from 'lodash-es';
import { Observable, Subscription, combineLatest, of } from 'rxjs';
import { catchError, filter, mergeMap, take } from 'rxjs/operators';
import { dialogControlMixin } from '../../../forms/components/safari-base-page/dialog-control-mixin';
import { PageUiService, SafariSmartComponent } from '../../../forms/components/safari-base/safari-base.component';

import { SafariUiDataTableReduxObject } from '../../../state/actions/layout-actions';
import { TableSubmitInfo } from '../../../state/interfaces/layout-interface';

import { SafariObject, SafariObjectId } from '@safarilaw-webapp/shared/common-objects-models';

import { Collection } from '@safarilaw-webapp/shared/crud';

import { FlyoutItem } from '../../../shared/components/flyout/flyout.component';
import { IfNotReadyToDisplayDirective } from '../../../shared/directives/if-not-ready-to-display/if-not-ready-to-display.directive';
import { ListWarningComponent } from '../list-warning/list-warning.component';
import { DataTableWrapperComponent, RowMenuActions, RowMenuType } from './data-table-wrapper/data-table-wrapper.component';
import { SafariListWrapperComponent, TableMenuMode } from './safari-list-wrapper/safari-list-wrapper.component';
export { RowMenuActions } from './data-table-wrapper/data-table-wrapper.component';
export interface ISafariListComponent<T> {
  listItemUrl: string;
  clearFilterOnDestroy: boolean;
  clearPageOnDestroy: boolean;
  clearSortOnDestroy: boolean;

  totalCount: number;

  page: number;
  pageSize: number;
  bindTo: T[];

  rows: any[];

  filterForm: FormGroup;
  sort: string;
  filter: any;
  appModelList: T[];

  getEditRoute(row: any);
  preserveListParameters();

  onSort(prop: any);
  readyToDisplay();
  getSortIcon(prop: any);
  onPage(event);
  registerInMemoryValueTransform(propName: string, fn: any);
  onModelBound();

  onInMemorySort(sort: string);
  onInMemoryFilter(obj: any, filters: any);
  onInMemoryTextSearch(obj: any, filters: any);
}

export enum BulkMenuActions {
  Delete = 1,
  Undo
}

export enum TableItemStatus {
  None = 0,
  PendingUpdate,
  PendingUpload,
  PendingDelete,
  Removed // this will be fired for new rows that were then deleted before saving
}
// eslint-disable-next-line @typescript-eslint/naming-convention -- "secret" parameters
export type TableItem<T> = T & { error?: any; id: SafariObjectId; __isNew?: boolean; __tableStatus?: TableItemStatus };

@Directive()
export class SafariListComponent<T, PageServiceType extends PageUiService = PageUiService>
  extends dialogControlMixin(SafariSmartComponent)<PageServiceType>
  implements ISafariListComponent<T>, OnInit, OnDestroy, AfterViewInit
{
  static standaloneImports = [SafariListWrapperComponent, DataTableWrapperComponent, IfNotReadyToDisplayDirective];
  RowMenuType = RowMenuType;
  TableMenuMode = TableMenuMode;

  private _hiddenColumnIndexes: number[] = [];
  private _columnOrder: number[] = [];
  private _totalCount: number = undefined;
  private _sortFunctions: Map<string, any> = new Map<string, any>();
  private _removedRows: T[] = [];
  private _addedRows: T[] = [];
  private _boundToSafariList = false;
  private _totalCountInTheDb = 0;
  protected tableReduxObject: SafariUiDataTableReduxObject;
  private _nullWeightOverrides = new Map<string, NullWeight>();
  private _selected: any[] = [];
  public get selected(): any[] {
    return this._selected;
  }
  public set selected(value: any[]) {
    this._selected = value;
    if (this._tableWrapper) {
      this._tableWrapper.selected = value;
    }
  }
  listItemUrl: string = null;
  clearPageOnDestroy = true;
  clearSortOnDestroy = true;
  clearFilterOnDestroy = true;
  clearCountsOnDestroy = true;
  clearSelectedOnDestroy = true;
  filterForm: FormGroup;
  private _rows: TableItem<T>[] = [];
  public get rows(): TableItem<T>[] {
    return this._rows;
  }
  public set rows(value: TableItem<T>[]) {
    this._rows = value;
    if (this._tableWrapper) {
      this._tableWrapper.rows = value;
    }
  }
  columns: any[] = [];

  canMassDelete = false;

  private _page = 0;

  private _filter: any = {};
  private _initCalled = false;
  editRowIndex = -1;

  protected _router: Router;
  protected _location: Location;

  protected _route: ActivatedRoute;

  protected _actions: Actions<unknown>;
  protected _doNotStoreTableRef = false;
  protected getRowClasses(row): any {
    return {
      // eslint-disable-next-line @typescript-eslint/naming-convention -- CSS propery name, don't complain
      s_highlight: true,
      // eslint-disable-next-line @typescript-eslint/naming-convention -- CSS propery name, don't complain
      's_txt-sm': this._listWrapper?.tableMenuMode == TableMenuMode.Compact
    };
  }

  @ViewChild(ListWarningComponent, { static: false }) listWarning: any;
  private _table: DatatableComponent = null;
  private _tableWrapper: DataTableWrapperComponent = null;
  private _listWrapper: SafariListWrapperComponent = null;
  protected _fb: FormBuilder;

  // This is the "actual" table rows and initially it will be the same as what we pass in bindTo
  // However with filtering and sorting "rows" that the datatable is bound to changes ,
  // while _rowsAtBinding always stays the same (otherwise we would lose the original rows the moment we filter)
  // Best to be thought of as "all rows, unfiltered, unsorted that represent original databound state"
  // The ONLY time this property will change is explicit additions and deletions in a table that manages its own data
  // (adding/deleting directly rather than broadcasting new/delete to the parent and asking a parent to do it instead)
  private _rowsAtBinding = [];
  private _appModelList: T[] = null;
  private _pageSize = 0;
  private _sort = '';
  private _onPageSub: Subscription = null;
  private _onSelectSub: Subscription = null;
  private _displayPropNames = null;
  @ViewChild('checkboxHeaderTemplate', { static: true }) checkboxHeaderTemplate: TemplateRef<any>;
  @ViewChild('checkboxCellTemplate', { static: true }) checkboxCellTemplate: TemplateRef<any>;

  @Input()
  autoExpandRowDetails = false;

  static isItemStatus<T>(obj: T, status: TableItemStatus) {
    return obj['__tableStatus'] === status;
  }
  static generateNewTableItem<T>(obj: T) {
    return {
      ...obj,
      __isNew: true,
      // eslint-disable-next-line @typescript-eslint/naming-convention -- "secret" param
      __tableStatus: TableItemStatus.PendingUpload
    };
  }
  static generateExistingTableItem<T>(obj: T) {
    return {
      ...obj,
      __isNew: false,
      // eslint-disable-next-line @typescript-eslint/naming-convention -- "secret" param
      __tableStatus: TableItemStatus.None
    };
  }
  @Input()
  set totalCount(value: number) {
    this._totalCount = value;

    if (this._tableWrapper) {
      this._tableWrapper.totalCount = this._totalCount;
    }

    this._reduxWrapper.dispatchGenericAction(this.tableReduxObject.default.actions.tableUpdateTotalCount({ payload: { id: this.componentId, totalCount: this._totalCount } }));
  }
  get totalCount(): number {
    return this._totalCount;
  }

  set pageSize(value: number) {
    this._pageSize = value;
    if (this._tableWrapper) {
      this._tableWrapper.pageSize = this._pageSize;
    }

    if (this.table) {
      this.table.recalculate();
    }
  }
  get pageSize(): number {
    return this._pageSize;
  }

  @Input()
  set componentId(value: string) {
    this._componentId = value;
  }
  get componentId(): string {
    return this._componentId;
  }
  getTrashcanClass(row) {
    if (this.isRowPendingDelete(row)) {
      return 's_ico-ungarbage_fill';
    }
    return 's_ico-garbage_fill';
  }

  protected deleteItem(id: string) {
    const { item, index } = this._findTableItemById(id);
    if (item) {
      if (item.__isNew) {
        this.setItemAction(item, TableItemStatus.Removed);
        this.rows.splice(index, 1);
        // Also remove this item from row cache if present. This is a brand new item that is permanently gone
        // we don't want it hiding in rowCache (which is used for filtering purpose as well as in hasRows function)
        // Without this a brand new table to which a new item was added and them immediately removed without
        // save would show that hasRows = true, because the newly added and removed item would be in rowCache.
        this._rowsAtBinding = this._rowsAtBinding.filter(o => !SafariObject.idEqual(o.id, id));
        this.notifyRowItemChangeAndRefreshRows(item);
      } else {
        if (!this.isRowPendingDelete(item)) {
          this.setItemAction(item, TableItemStatus.PendingDelete);
        }
        this.notifyRowItemChangeAndRefreshRows(item);
      }
    }
  }

  protected addOrUpdateItem(row: TableItem<T | Partial<T>>) {
    const { item } = this._findTableItemById(row.id);
    if (!item) {
      this.addItem(row as TableItem<T>);
    } else {
      this.updateItem(row);
    }
  }
  protected addItem(row: TableItem<T>) {
    const newItem = { ...row, __isNew: true, __tableStatus: TableItemStatus.PendingUpload };

    this._rowsAtBinding.push(newItem);
    this.refreshSortAndRefilter();
    this.notifyRowItemChangeAndRefreshRows(newItem);
    this._goToRowId(newItem.id);
  }

  protected updateItem(row: TableItem<Partial<T>>) {
    const { item, index } = this._findTableItemById(row.id);
    if (item) {
      const updatedRow = { ...item, ...row };
      if (!updatedRow.__isNew) {
        this.setItemAction(updatedRow, TableItemStatus.PendingUpdate);
      }

      this.rows[index] = updatedRow;
      this.notifyRowItemChangeAndRefreshRows(updatedRow);
    }
  }

  isRowPendingDelete(row): boolean {
    return row.__tableStatus === TableItemStatus.PendingDelete;
  }

  undoSelected() {
    for (const row of this.selected) {
      this.undoItem(row.id);
    }
  }

  canBeUndone(row: TableItem<T>, massSelect = false): boolean {
    // If massSelect and the file can't be selected that it can't be mass undone either
    if (massSelect) {
      if (!this.canBeSelected(row)) {
        return false;
      }
    }

    // Can't undo new files
    if (row.__isNew) {
      return false;
    }
    // Can't undo if nothing has been done to the file or if it's a brand new file
    // with no renames
    if (row.__tableStatus == TableItemStatus.None) {
      return false;
    }

    return true;
  }

  get canMassUndo() {
    for (const selected of this.selected) {
      if (this.canBeUndone(selected)) {
        // if at least one of the selected items can be undone that we can mass uno
        return true;
      }
    }
    return false;
  }

  protected undoItem(id: string) {
    // eslint-disable-next-line no-unused-vars, @typescript-eslint/no-unused-vars -- this is just to destructure - TS lint complains...
    const { item, index } = this._findTableItemById(id);
    this.undoRow(item);
  }

  protected undoRow(row) {
    if (row && row.__tableStatus) {
      this.setItemAction(row, TableItemStatus.None);
      this.notifyRowItemChangeAndRefreshRows(row);
    }
  }

  protected _findTableItemById(id: SafariObjectId): { item: TableItem<T>; index: number } {
    const index = this.rows.findIndex(o => SafariObject.idEqual(o.id, id));
    if (index >= 0) {
      return { item: this.rows[index], index };
    }
    return { item: undefined, index: undefined };
  }
  protected notifyRowItemChangeAndRefreshRows(row) {
    this._reduxWrapper.dispatchGenericAction(this.tableReduxObject.default.actions.tableItemStatusChanged({ payload: { id: this.componentId, item: { ...row } } }));
    this._reassignRows();
  }
  protected getRowById(id: SafariObjectId) {
    return this.rows.find(o => SafariObject.idEqual(o.id, id));
  }
  protected setItemAction(row, action: TableItemStatus) {
    if (row == null) {
      return;
    }

    row.__tableStatus = action;
  }
  recalcListWarning() {
    if (this._boundToSafariList) {
      setTimeout(() => {
        if (this.listWarning) {
          this.listWarning.maxCountInMemory = this.uiSettings.list.maxCountInMemory;
          this.listWarning.shouldShow = this.uiSettings.list.maxCountInMemory < this._totalCountInTheDb;
        } else if (this.listWrapper) {
          this.listWrapper.listWarning.maxCountInMemory = this.uiSettings.list.maxCountInMemory;
          this.listWrapper.listWarning.shouldShow = this.uiSettings.list.maxCountInMemory < this._totalCountInTheDb;
        } else {
          // eslint-disable-next-line no-console -- ok for now
          console.warn('Tables bound to list object should include the warning: ', this.componentId);
        }
      });
    }
  }
  ngAfterViewInit() {
    super.ngAfterViewInit();
    this.recalcListWarning();

    this._reduxWrapper.dispatchGenericAction(this.tableReduxObject.default.actions.tableUpdateInit({ payload: { id: this.componentId, initialized: true } }));
  }

  get appModelList(): T[] {
    return this._appModelList;
  }

  registerInMemoryValueTransform(propName: string, fn: (row: T) => string | number | boolean) {
    this._sortFunctions.set(propName, fn);
  }

  @Input()
  set bindTo(value: T[] | Collection<T>) {
    this._removedRows = [];
    this._addedRows = [];
    if (value == null) {
      this._appModelList = null;
      return;
    }

    if (Array.isArray(value)) {
      // Old style array binding
      this._boundToSafariList = false;
      this._appModelList = (cloneDeep(value) as TableItem<T>[]).map(x => {
        if (x.__tableStatus == undefined) {
          x.__tableStatus = TableItemStatus.None;
          x.__isNew = false;
        }
        return x;
      });
    } else {
      // If it's not an array then its' a new safarlist binding
      this._boundToSafariList = true;
      this._totalCountInTheDb = value.totalCount;
      this._appModelList = (cloneDeep(value.items) as TableItem<T>[]).map(x => {
        if (x.__tableStatus == undefined) {
          x.__tableStatus = TableItemStatus.None;
          x.__isNew = false;
        }
        return x;
      });
    }

    if (this._initCalled) {
      this.callOnBound();
    }
  }
  get bindTo(): T[] {
    return this._appModelList;
  }

  // Bulk Action menu items and functions  ////////////
  private _bulkMenuItems: FlyoutItem[] = [];
  public get bulkMenuItems(): FlyoutItem[] {
    return this._bulkMenuItems;
  }
  public set bulkMenuItems(value: FlyoutItem[]) {
    this._bulkMenuItems = value;
    if (this._listWrapper) {
      this._listWrapper.bulkMenuItems = value;
    }
  }
  protected recalculateBulkActionVariables() {
    this.canMassDelete = false;

    for (const item of this.selected.filter(o => this.canBeSelected(o))) {
      // If even 1 item in the selection can be deleted then we will enable the function
      if (this.canBeDeleted(item, true)) {
        this.canMassDelete = true;
      }
    }
  }
  bulkActionsButtonClicked(addDelete = true, addUndo = true) {
    this.recalculateBulkActionVariables();
    this.bulkMenuItems = [];

    if (addDelete) {
      this.bulkMenuItems.push({
        name: this._listWrapper?.deleteActionText || 'Delete',
        description: this._listWrapper?.deleteActionDescription || 'will be deleted',
        id: BulkMenuActions.Delete,
        class: 'text-danger',
        disabled: !this.canMassDelete
      });
    }

    if (addUndo) {
      this.bulkMenuItems.push(null);

      this.bulkMenuItems.push({ name: 'Undo', description: 'undo changes', id: BulkMenuActions.Undo, disabled: !this.canMassUndo });
    }
  }
  bulkActionsMenuItemClicked(item) {
    if (item.id == BulkMenuActions.Delete) {
      this.deleteSelected();
    } else if (item.id == BulkMenuActions.Undo) {
      this.undoSelected();
    }
    this.recalculateBulkActionVariables();
  }

  shouldShowBulkCheckboxes() {
    return this.bulkMenuItems?.length > 0;
  }
  shouldShowBulkActions() {
    return this.selected?.length > 0 && this.bulkMenuItems?.length > 0;
  }

  /////////////////////////////////////////////////////

  // New menu items and functions ////////////

  private _newMenuItems: FlyoutItem[] = [];
  public get newMenuItems(): FlyoutItem[] {
    return this._newMenuItems;
  }
  public set newMenuItems(value: FlyoutItem[]) {
    this._newMenuItems = value;
    if (this._listWrapper) {
      this._listWrapper.newMenuItems = value;
    }
  }
  shouldShowNewMenu(): boolean {
    return true;
  }
  newMenuButtonClicked() {
    this.newMenuItems = [];
  }
  newMenuItemClicked($event) {}
  /////////////////////////////////////////////

  // Row Menu items and functions /////
  private _rowMenuItems: FlyoutItem[] = [];
  public get rowMenuItems(): FlyoutItem[] {
    return this._rowMenuItems;
  }
  public set rowMenuItems(value: FlyoutItem[]) {
    this._rowMenuItems = value;
    if (this._tableWrapper) {
      this._tableWrapper.rowMenuItems = value;
    }
  }
  currentRow = null;

  getHiderClass(row: any) {
    if (this.currentRow && this.currentRow.id == row.id) {
      return 'no_hider';
    }
    return 's_hider';
  }
  rowMenuButtonClicked(row: TableItem<T>, addDelete = true, addUndo = true) {
    this.currentRow = row;
    this.rowMenuItems = [];
    if (addDelete) {
      this.rowMenuItems.push({
        name: this._listWrapper?.deleteActionText?.toUpperCase() || 'DELETE',
        id: RowMenuActions.Delete,
        additionalInfo: row,
        disabled: !this.canBeDeleted(row) || this.isRowPendingDelete(row)
      });
    }
    if (addUndo) {
      this.rowMenuItems.push(null);
      this.rowMenuItems.push({ name: 'UNDO', disabled: !this.canBeUndone(row), additionalInfo: row, id: RowMenuActions.Undo });
    }
  }
  rowMenuItemClicked(item: FlyoutItem) {
    switch (item.id) {
      case RowMenuActions.Delete:
        this.deleteItem(item.additionalInfo.id);
        break;
      case RowMenuActions.Undo:
        this.undoItem(item.additionalInfo.id);
        break;
    }
  }
  shouldShowRowActions(row: any): boolean {
    return this._tableWrapper?.rowMenuType == RowMenuType.Flyout;
  }
  /////////////////////////////////////////////

  @ViewChild(SafariListWrapperComponent)
  set listWrapper(content: SafariListWrapperComponent) {
    if (this._listWrapper != content) {
      this._listWrapper = content;
      if (this._listWrapper) {
        this._listWrapper.id = this.componentId + '-list-wrapper';

        this._listWrapper.filterForm = this.filterForm;
        this._listWrapper.shouldShowBulkActionsCallback = () => this.shouldShowBulkActions();
        this._listWrapper.populateBulkItemsCallback = () => this.bulkActionsButtonClicked();
        this._listWrapper.bulkMenuItemClickedCallback = row => this.bulkActionsMenuItemClicked(row);

        this._listWrapper.bulkMenuItems = this.bulkMenuItems;

        this._listWrapper.shouldShowNewActionsCallback = () => this.shouldShowNewMenu();
        this._listWrapper.populateNewItemsCallback = () => this.newMenuButtonClicked();
        this._listWrapper.newMenuItemClickedCallback = row => this.newMenuItemClicked(row);

        this._listWrapper.newMenuItems = this.newMenuItems;
        if (this._tableWrapper) {
          this._setupColumnsAndTable();
        }
        this._cdr.detectChanges();
      }
    }
  }
  get listWrapper() {
    return this._listWrapper;
  }
  @ViewChild(DataTableWrapperComponent)
  set tableWrapper(content: DataTableWrapperComponent) {
    if (this._tableWrapper != content) {
      this._tableWrapper = content;
      if (this._tableWrapper) {
        this._tableWrapper.page = this.page;
        this._tableWrapper.totalCount = this.totalCount;
        this._tableWrapper.pageSize = this.pageSize;
        this._tableWrapper.id = this.componentId + '-table-wrapper';
        this._tableWrapper.columns = this.columns;
        this._tableWrapper.selected = this.selected;
        this._tableWrapper.onPageCallback = evt => this.onPage(evt);
        this._tableWrapper.canDisplaySelectAllCallback = () => this.canDisplaySelectAll();
        this._tableWrapper.onSelectCallback = evt => this.onSelect(evt);
        this._tableWrapper.canBeSelectedCallback = row => this.canBeSelected(row);
        this._tableWrapper.getHiderClassCallback = row => this.getHiderClass(row);
        this._tableWrapper.shouldShowRowActionsCallback = row => this.shouldShowRowActions(row);
        this._tableWrapper.rowMenuItemClickedCallback = row => this.rowMenuItemClicked(row);
        this._tableWrapper.rowMenuButtonClickedCallback = row => this.rowMenuButtonClicked(row);
        this._tableWrapper.getRowClassesFunctionCallback = row => this.getRowClasses(row);
        this._tableWrapper.canBeDeletedCallback = row => this.canBeDeleted(row);
        this._tableWrapper.rowMenuItems = this.rowMenuItems;
        if (this._listWrapper) {
          this._setupColumnsAndTable();
        }
      }
    }
  }
  private _setupColumnsAndTable() {
    if (this._listWrapper.bulkMenu) {
      this._tableWrapper.selectionType = SelectionType.checkbox;
    } else {
      this._tableWrapper.selectionType = null;
    }
    this._tableWrapper.rowMenuType = this._listWrapper.rowMenuType;
    if (this._listWrapper.rowMenuType == RowMenuType.Flyout) {
      this._tableWrapper.rowMenuItems = this.rowMenuItems;
    }
    this._tableWrapper.emptyMessage = this._listWrapper.emptyMessage;
    this._tableWrapper.totalMessage = this._listWrapper.totalMessage;
    this._tableWrapper.selectedMessage = this._listWrapper.selectedMessage;
    this._tableWrapper.hideHeadersWhenNoData = this._listWrapper.hideHeadersWhenNoData;
    this._tableWrapper.setHiddenColumns(this._hiddenColumnIndexes);
    this._tableWrapper.setColumnOrder(this._columnOrder);
    this._tableWrapper.setUpColumns();
    this._setTable(this._tableWrapper.table);
    this._tableWrapper.rows = this.rows;
    this._cdr.detectChanges();
  }
  hideColumnByIndex(index: number) {
    if (index < 0) {
      return;
    }
    if (this._hiddenColumnIndexes.find(i => i == index)) {
      return;
    }
    this._hiddenColumnIndexes.push(index);

    if (this._tableWrapper) {
      this._tableWrapper.setHiddenColumns(this._hiddenColumnIndexes);
    }
  }
  setColumnOrder(...args: any[]) {
    this._columnOrder = args;
  }
  showColumnByIndex(index: number) {
    if (this._hiddenColumnIndexes.find(i => i == index) === undefined) {
      return;
    }
    this._hiddenColumnIndexes = this._hiddenColumnIndexes.filter(i => i != index);

    if (this._tableWrapper) {
      this._tableWrapper.setHiddenColumns(this._hiddenColumnIndexes);
    }
  }
  private _setTable(content: DatatableComponent) {
    // Storing table ref (even if none of the table methods are called)
    // causes tables inside bootstrap modal to choke the entire dialog.
    // We only store table ref for some special table fixes (usually have to do with resizing)
    // so we can trade that off in dialog-hosted tables
    if (this._doNotStoreTableRef) {
      return;
    }
    const firstTime = this._table == null;

    this._table = content;
    if (firstTime) {
      if (this._table && this.page) {
        this._table.offset = this.page;
      }

      this._refreshAllRows();
      this._autoExpandAllRows();
    }
  }
  @ViewChild(DatatableComponent)
  set table(content: DatatableComponent) {
    if (this._tableWrapper) {
      return;
    }
    this._setTable(content);
  }
  get table() {
    return this._table;
  }

  constructor() {
    super();
    /** Be mindful of what you put in the constructor. In many of our UTs
     * we are bypassing injections to make them faster and we just new up the component manually.
     * For this reason the constructor should only inject via this.inject and at most set some
     * simple properties.
     *
     * If there is anything that depends on the result of injection it should be checked for NULL,
     * but even better approach might be to put that in onInit if possible.
     *
     * Observable defs should go to onInit
     */
    this.pageSize = this.uiSettings && this.uiSettings.list ? this.uiSettings.list.pageSize : 20;
    this._router = this.inject(Router);
    this._location = this.inject(Location);
    this._route = this.inject(ActivatedRoute);

    this._actions = this.inject(Actions);
    this._fb = this.inject(FormBuilder);
    this._reduxWrapper = this.inject(ReduxWrapperService);
    this.tableReduxObject = this.inject(SafariUiDataTableReduxObject);
  }

  /**
   * Sort setter and getter
   * NOTE: Tables can be driven by either direct binding via HTML or by receiving filter/sort/page messages
   * from other components (usually some table filter component).
   * Filter/sort/page setters simply dispatch the message , the actual code is in the subscribtions
   */
  @Input()
  set page(value: number) {
    if (value == this._page) {
      // Don't unecessarily dispatch page set event if the values are the same
      return;
    }

    this._page = value;
    if (this._tableWrapper) {
      this._tableWrapper.page = this._page;
    }
    if (this._initCalled) {
      this._setPage(value);
    }
  }
  get page() {
    return this._page;
  }
  private _setPage(value: number) {
    this._reduxWrapper.dispatchGenericAction(this.tableReduxObject.default.actions.tableUpdatePage({ payload: { id: this.componentId, page: value } }));
  }

  /**
   * Filter setter and getter
   * NOTE: Tables can be driven by either direct binding via HTML or by receiving filter/sort/page messages
   * from other components (usually some table filter component).
   * Filter/sort/page setters simply dispatch the message , the actual code is in the subscribtions
   */
  get filter() {
    return this._filter;
  }
  @Input()
  set filter(value) {
    if (JSON.stringify(value) == JSON.stringify(this._filter)) {
      // Don't unecessarily dispatch messages to update filter if it's already the same as what we
      // already have
      return;
    }
    // We don't want to allow setting filter to NULL as some pages might want to block on a table's
    // defaultFilter being set explicitly by the page. If we allow setting to actual NULL (as in "don't filter on anything") our
    // directive will think that nothing has been set and will block the page indefinitely
    // This is similar what we do in set sort() method
    this._filter = value || {};
    /**
     * All that filter setter does is dispatch an action so that the table state can be updated
     * However if this is set on the first call we'll just skip it, since componentID may not
     * be there yet (if it was dynamically bound via input prop for example)
     * We will make the actual call inside onInit later on
     */
    if (this._initCalled) {
      this._setFilter(this._filter);
    }
  }

  /**
   * Sort setter and getter
   * NOTE: Tables can be driven by either direct binding via HTML or by receiving filter/sort/page messages
   * from other components (usually some table filter component).
   * Filter/sort/page setters simply dispatch the message , the actual code is in the subscribtions
   */
  @Input()
  set sort(value: string) {
    if (value == this._sort) {
      // Don't unecessarily dispatch sort set event if the values are the same
      return;
    }

    // A lot of times componentId will be assigned in either oninit of the compoennt or via binding from parent
    // Since sort is also a bound property this can result in list not having ID by the time sort
    // is set. Having componentId is crucial for these events so if that happens we'll just "queue" the sort and it will
    // be reissued by componentId setter

    // Also - we don't want to allow setting sort to NULL as some pages might want to block on a table's
    // defaultSort being explicitly set by the page. If we allow setting to actual NULL (as in "don't sort on anything") our
    // directive will think that nothing has been set and will block the page indefinitely
    // This is similar what we do in set filter() method
    this._sort = value || '';

    /**
     * All that sort setter does is dispatch an action so that the table state can be updated
     * However if this is set on the first call we'll just skip it, since componentID may not
     * be there yet (if it was dynamically bound via input prop for example)
     * We will make the actual call inside onInit later on
     */
    if (this._initCalled) {
      this._setSort(value);
    }
  }
  get sort(): string {
    return this._sort;
  }

  private _getSortEntryFromSortString(sort): SortEntry {
    const sortArray = sort?.split(/\s+/);
    return { prop: sortArray[0], direction: sortArray?.length > 1 ? sortArray[1]?.toLowerCase() : 'asc' };
  }
  private _getDirectionFromSortStringArray(sortArray) {
    return sortArray?.length > 1 ? sortArray[1] : 'asc';
  }
  private _getSortStringArray(sort): string[] | undefined {
    return sort?.split(/\s+/);
  }
  shouldShowSaveToBadge(row: TableItem<T>): boolean {
    return this.hasStatus(row);
  }
  hasStatus(row: TableItem<T>): boolean {
    return !!row.__tableStatus;
  }
  getSaveToBadgeMessage(row: TableItem<T>): string {
    switch (row.__tableStatus) {
      case TableItemStatus.PendingDelete:
        return 'DELETE';
      case null:
      case undefined:
      case TableItemStatus.PendingUpload:
        return 'ADD';
      case TableItemStatus.PendingUpdate:
      default:
        return 'UPDATE';
    }
  }

  hasUnsavedChanges() {
    return (this._removedRows != null && this._removedRows.length > 0) || this.rows.find(o => o.__tableStatus != null && o.__tableStatus != TableItemStatus.None) != null;
  }

  private _goToRowId(rowId: SafariObjectId) {
    const rowIndex = this.rows.findIndex(o => o.id == rowId);
    if (rowIndex < 0) {
      return;
    }
    this.page = Math.round(rowIndex / this.pageSize);
  }
  private initializeSubscriptions() {
    this.subscribe(
      this._actions.pipe(
        ofType(this.tableReduxObject.default.actions.tableGoToByRowId),
        filter(o => o.payload.id === this._componentId)
      ),
      goToRowIdAction => {
        this._goToRowId(goToRowIdAction.payload.rowId);
      }
    );
    super.subscribe(this._reduxWrapper.getGenericSelector(this.tableReduxObject.default.selectors.getListPageState(this.componentId)), page => {
      this._page = page ?? 0;
    });
    super.subscribe(this._reduxWrapper.getGenericSelector(this.tableReduxObject.default.selectors.getListSortState(this.componentId)), sort => {
      this._sort = sort || '';
      this.onInMemorySort(this.sort);
    });
    super.subscribe(this._reduxWrapper.getGenericSelector(this.tableReduxObject.default.selectors.getListFilterState(this.componentId)), tableFilter => {
      if (tableFilter && !isEqual(this._filter, tableFilter)) {
        this._filter = { ...tableFilter };
        this.onFilterChanged();
        // If we had a filter here just call the function below - it will refilter and re-sort
        this.InMemoryFilterAndReSort(tableFilter);
      }
    });

    this.createTableRequestSubmitSubscription();
    this.createTableSubmitSubscription();

    super.subscribe(this._reduxWrapper.getGenericSelector(this.tableReduxObject.default.selectors.getListSelectedItems(this.componentId)), (selected: string[]) => {
      if (selected) {
        this.selected = [];
        // This seems like a roundabout way of doing this but we need to do it like this.
        // The way ngx-datatable works is that items in selected must be refs to actual objects
        // in the table's rows so in order for us to preserve selection in the state and then resurrect it
        // afterwards we can't just store selected rows in the state and then expand [...selected].
        // Instead we need to go through the list, find the appropriate row by id, get its actual object ref
        // and THEN insert into selected array
        for (const selectedId of selected) {
          const row = this.rows.find(o => o.id == selectedId);
          if (row != null) {
            this.selected.push(row);
          }
        }
      }
    });
  }
  onFilterChanged() {}

  readyToDisplay() {
    return this._initCalled;
  }
  private callOnBound() {
    this.onModelBound();

    this._reduxWrapper.dispatchGenericAction(
      this.tableReduxObject.default.actions.tableUpdateTotalCount({ payload: { id: this.componentId, totalCount: this._totalCount !== undefined ? this._totalCount : this.rows.length } })
    );
  }
  protected rememoize(name: string) {
    const memoizedGetterFunctionName = name + '_memoized';
    if (this[memoizedGetterFunctionName]) {
      delete this[memoizedGetterFunctionName];
      this[memoizedGetterFunctionName] = memoize(this[name]);
    }
  }
  private _setupMemoizedFunctions() {
    // As soon as we initialize go through the proto chain and collect all
    // ForDisplay functions
    this._displayPropNames = [];
    for (const key of this.getAllFuncs()) {
      // Is this "ForDisplay"
      if (this._isForDisplayOverride(key)) {
        // Also make sure that it's a function
        if (typeof this[key] == 'function') {
          this._displayPropNames.push(key);
          const memoizedGetterFunctionName = key + '_memoized';
          this[memoizedGetterFunctionName] = memoize(this[key]);
        }
      }
    }
  }
  ngOnInit() {
    super.ngOnInit();

    // By now we better have componentID set
    if (this.componentId == null) {
      throw new Error('componentId not set!');
    }
    this.createForm();
    this._setupMemoizedFunctions();
    this._initCalled = true;

    this._removedRows = [];

    // Call functions that setters usually call to set the initial state
    this._setFilter(this._filter);
    this._setSort(this._sort);
    this._setPage(this._page);

    /**
     * Now we will initialize table subs including listeners for sort/filter/page
     * These will immediately pick whatever was set during the initial binding (above) as
     * well as any changes in the future
     */
    this.initializeSubscriptions();

    this.callOnBound();
    this.bulkActionsButtonClicked();
    this.setReadyToDisplay(true);
  }

  ngOnDestroy() {
    super.ngOnDestroy();
    if (this._onPageSub) {
      this._onPageSub.unsubscribe();
      this._onPageSub = null;
    }
    if (this._onSelectSub) {
      this._onSelectSub.unsubscribe();
      this._onSelectSub = null;
    }
    if (this.clearCountsOnDestroy && this.clearFilterOnDestroy && this.clearPageOnDestroy && this.clearSelectedOnDestroy && this.clearSortOnDestroy) {
      this._reduxWrapper.dispatchGenericAction(this.tableReduxObject.default.actions.tableClearState({ id: this.componentId }));
    } else {
      if (this.clearCountsOnDestroy) {
        this._reduxWrapper.dispatchGenericAction(this.tableReduxObject.default.actions.tableClearCount({ id: this.componentId }));
      }
      if (this.clearFilterOnDestroy) {
        this._reduxWrapper.dispatchGenericAction(this.tableReduxObject.default.actions.tableClearFilter({ id: this.componentId }));
      }
      if (this.clearPageOnDestroy) {
        this._reduxWrapper.dispatchGenericAction(this.tableReduxObject.default.actions.tableClearPage({ id: this.componentId }));
      }
      if (this.clearSortOnDestroy) {
        this._reduxWrapper.dispatchGenericAction(this.tableReduxObject.default.actions.tableClearSort({ id: this.componentId }));
      }
      if (this.clearSelectedOnDestroy) {
        this._reduxWrapper.dispatchGenericAction(this.tableReduxObject.default.actions.tableClearSelected({ id: this.componentId }));
      }
    }

    this._reduxWrapper.dispatchGenericAction(this.tableReduxObject.default.actions.tableUpdateInit({ payload: { id: this.componentId, initialized: false } }));
  }
  createForm() {
    const formObj = { name: ['', Validators.required] };
    this.filterForm = this._fb.group(formObj);
  }

  getSortIcon(prop) {
    const sortEntry = this._getSortEntryFromSortString(this.sort);
    if (sortEntry && sortEntry.prop == prop) {
      if (sortEntry.direction?.toLowerCase() == 'asc') {
        return 's_sort s_ascend';
      }
      return 's_sort s_descend';
    }
    return 's_sort';
  }

  inMemorySort_HasValue(propValue: string | number): number {
    // We have to also check for "--", since some of transformations bake that into the value (rather than using noNullValues pipe)
    return propValue == null || (typeof propValue === 'string' && (String.isNullOrEmpty(propValue) || propValue == '--')) ? 0 : 1;
  }

  onInMemorySort(sort: string) {
    if (this.rows == null) {
      return;
    }
    if (!sort) {
      return;
    }
    const sortEntry = this._getSortEntryFromSortString(sort);
    const prop = sortEntry.prop;
    const func = this._sortFunctions.get(prop);

    const nullWeightOverride: NullWeight = this._nullWeightOverrides.get(sortEntry.prop);
    const nullBehavior: NullWeight = nullWeightOverride == null ? NullWeight.Low : nullWeightOverride;
    const nullLowOrHighModifier = nullBehavior === NullWeight.Low ? 1 : -1;
    const directionModifier = sortEntry.direction?.toLowerCase() === 'desc' ? -1 : 1;

    this.rows.sort((a, b) => {
      // If there are errors in any of the rows this will take precedence regardless of any other sorting
      if (a?.error && !b?.error) {
        return -1;
      }
      if (b?.error && !a?.error) {
        return 1;
      }

      let prop1 = null;
      let prop2 = null;
      if (func == null) {
        if (this._isForDisplayOverride(sortEntry.prop)) {
          prop1 = this.get(a, sortEntry.prop);
          prop2 = this.get(b, sortEntry.prop);
        } else {
          const getFromPath = (p, o) => p.reduce((xs, x) => (xs && xs[x] ? xs[x] : null), o);
          const path = sortEntry.prop.split('.');
          prop1 = getFromPath(path, a);
          prop2 = getFromPath(path, b);
        }
      } else {
        prop1 = a;
        prop2 = b;
        prop1 = func(prop1);
        prop2 = func(prop2);
      }

      const prop1HasValue = this.inMemorySort_HasValue(prop1);
      const prop2HasValue = this.inMemorySort_HasValue(prop2);

      // Special null behavior. If both properties have a value, skip this section and sort normally.
      // If one or both properties don't have a value...
      if (!(prop1HasValue && prop2HasValue)) {
        // If both properties have no value, this is 0 (the values are treated as equivalent).
        // If prop1 has a value but prop2 has no value, then 1 (sort prop1 higher).
        // If prop2 has a value but prop1 has no value, then -1 (sort prop2 higher).
        const normalSortComparison = prop1HasValue - prop2HasValue;
        // If nulls should sort to the top, nullHighOrLowModifier reverses the sort
        // If we are sorting from highest to lowest, directionModifier reverses the sort
        return normalSortComparison * nullLowOrHighModifier * directionModifier;
      }

      // Both properties had a value. Just sort normally
      if (prop1 instanceof Date) {
        let test = 1;
        if (prop1 > prop2) {
          test = -1;
        }
        return test * (sortEntry.direction?.toLowerCase() === 'desc' ? -1 : 1);
      }

      if (typeof prop1 === 'number') {
        return (prop1 < prop2 ? -1 : 1) * (sortEntry.direction?.toLowerCase() === 'desc' ? -1 : 1);
      }
      return prop1.toString().localeCompare(prop2.toString()) * (sortEntry.direction?.toLowerCase() === 'desc' ? -1 : 1);
    });

    this._reassignRows();
  }
  private _setFilter(listFilter) {
    this._reduxWrapper.dispatchGenericAction(this.tableReduxObject.default.actions.tableUpdateFilter({ payload: { id: this.componentId, filter: listFilter } }));
  }

  // onPage - called directly when a user clicks on the pager in the header
  onPage(event) {
    this._reduxWrapper.dispatchGenericAction(this.tableReduxObject.default.actions.tableUpdatePage({ payload: { id: this.componentId, page: +event.offset } }));
  }
  setNullBehavior(col: string, weight: NullWeight) {
    this._nullWeightOverrides.set(col, weight);
  }
  // onSort - called directly when a user clicks on the header in the datatable
  onSort(prop) {
    if (!prop || prop.length == 0) {
      return;
    }

    if (String.isNullOrEmpty(this.sort)) {
      this.sort = prop;
      return;
    } else {
      const currentSortEntry = this._getSortEntryFromSortString(this.sort);
      if (currentSortEntry.prop != prop) {
        this.sort = prop;
        return;
      }
      const direction = currentSortEntry.direction.toLowerCase() == 'asc' || String.isNullOrEmpty(currentSortEntry.direction) ? 'desc' : 'asc';
      // either set a new sort entry or reverse the existing one
      this.sort = currentSortEntry.prop + ' ' + direction;
      return;
    }
  }
  private _setSort(sort) {
    // broadcast the action back to self - subs will handle it
    this._reduxWrapper.dispatchGenericAction(this.tableReduxObject.default.actions.tableUpdateSort({ payload: { id: this.componentId, sort } }));
  }

  private _getListParametersObservable(): Observable<any> {
    return combineLatest([
      this._reduxWrapper.getGenericSelector(this.tableReduxObject.default.selectors.getListPageState(this.componentId)),
      this._reduxWrapper.getGenericSelector(this.tableReduxObject.default.selectors.getListSortState(this.componentId)),
      this._reduxWrapper.getGenericSelector(this.tableReduxObject.default.selectors.getListFilterState(this.componentId))
    ]);
  }

  isEditingRow(rowIndex: number) {
    return this.editRowIndex === rowIndex;
  }
  editRow(rowIndex: number, row: number) {
    this.editRowIndex = rowIndex;
    this.table.rowDetail.toggleExpandRow(row);
  }
  toggleExpandRow(row) {
    this.table.rowDetail.toggleExpandRow(row);
  }
  private _autoExpandAllRows() {
    if (this.autoExpandRowDetails && this._table && this._table.rowDetail) {
      setTimeout(() => this._table.rowDetail.expandAllRows());
    }
  }
  protected _refreshAllRows() {
    // Very annoying... But I noticed that participants table gets a bit messed up
    // when a new participant is added. It may have to do something with scrollbar showing up...
    // This gets fixed by simply assigning rows
    // back to themselves (as per some stackoverflow answers out there)
    // This will be called in CallOnBound and the first time table shows up
    // It is currently going to be called for ALL tables so we have to watch and make sure
    // nothing weird is happening with any other tables.
    if (this._table != null) {
      const currentlySelectedIds = this.selected.map(o => o.id);
      this.selected = [];
      for (const selectedId of currentlySelectedIds) {
        const row = this.rows.find(o => o.id == selectedId);
        if (row != null) {
          this.selected.push(row);
        }
      }

      setTimeout(() => {
        this._reassignRows();
      }, 5);
    }
  }

  onModelBound() {
    // We need to cloneDeep here to fully detach appModel from table's rows
    this.rows = this.appModelList as TableItem<T>[];
    this._rowsAtBinding = [...this.rows];
    this.refreshSortAndRefilter();
    this._autoExpandAllRows();
    this._refreshAllRows();
  }

  public refreshSortAndRefilter() {
    this._reduxWrapper
      .getGenericSelector(this.tableReduxObject.default.selectors.getListFilterState(this.componentId))
      .pipe(take(1))
      .subscribe(o => {
        this.InMemoryFilterAndReSort(o);
      });
  }
  private _isForDisplayOverride(o: string) {
    return o.endsWith('ForDisplay');
  }
  /**
   *
   * @returns List of all functions including inherited, and non-enumerated
   */
  getAllFuncs(): string[] {
    // eslint-disable-next-line @typescript-eslint/no-this-alias -- eslint doesn't like this low-level stuff but we need this
    const toCheck = this;
    const props = [];
    let obj = toCheck;
    do {
      props.push(...Object.getOwnPropertyNames(obj));
      // eslint-disable-next-line no-cond-assign -- eslint doesn't like this low-level stuff but we need this
    } while ((obj = Object.getPrototypeOf(obj)));

    return props.sort().filter((e, i, arr) => {
      if (e != arr[i + 1] && typeof toCheck[e] == 'function') return true;
    });
  }

  findValInDisplayProps(object, val) {
    for (const propName of this._displayPropNames) {
      // See if we can find this value for this property once we call its ForDisplay
      // If not try the next one. Otherwise return true
      if (this.findVal(this[propName](object), val)) {
        return true;
      }
    }
    return false;
  }
  findVal(object, val) {
    if (object == null) {
      return false;
    }
    // File object needs special processing. We generally can search JSON object by using Object.keys
    // but that won't work for File since Object.keys returns only object's own keys but not those from its'
    // prototype. Hence special handling for Files.
    if (object instanceof File) {
      if (this.findVal(object['name'], val)) {
        return true;
      }
      if (this.findVal(object['size'], val)) {
        return true;
      }
      return false;
    }
    if (typeof object === 'string' && object.toLowerCase().indexOf(val) !== -1) {
      return true;
    } else if (typeof object === 'number' && object.toString().toLowerCase().indexOf(val) !== -1) {
      return true;
    } else if (Array.isArray(object)) {
      for (const item of object) {
        if (this.findVal(item, val)) {
          return true;
        }
      }
    } else if (typeof object === 'object') {
      for (const k of Object.keys(object)) {
        let valueToCompare = object[k];
        const func = this._sortFunctions.get(k);
        if (typeof func === 'function') {
          valueToCompare = func(object);
        }
        if (this.findVal(valueToCompare, val)) {
          return true;
        }
      }
    }
    return false;
  }

  // filter for objects that have the matching text
  onInMemoryTextSearch(obj: any, listFilter: any) {
    if (listFilter.text) {
      let result = this.findVal(obj, listFilter.text);
      if (!result) {
        result = this.findValInDisplayProps(obj, listFilter.text);
      }
      return result;
    } else {
      return true;
    }
  }
  // filter for objects that match as defined by the sub-class
  onInMemoryFilter(obj: any, listFilter: any) {
    return true;
  }
  get hasRows() {
    return (this._rowsAtBinding == null && this.rows?.length > 0) || this._rowsAtBinding?.length > 0;
  }
  private InMemoryFilterAndReSort(listFilter) {
    const temp = this._rowsAtBinding.filter(d => this.onInMemoryTextSearch(d, listFilter || {}) && this.onInMemoryFilter(d, listFilter || {}));
    this.rows = [...temp];
    this.onInMemorySort(this.sort);
    this.recalcListWarning();
  }

  getEditRoute(row: any) {
    if (this.listItemUrl == null || this.listItemUrl.length === 0) {
      // Ideally we'd just do throw new Error here but for some reason if A link has [routerLink] blank
      // and click handler blows up with an exception (as it would be the case if we were to throw Error)
      // A links default handler kicks in and redirects to /null. So instead of throwing an error we just dispatch it
      // super.dispatchGenericError('preserveListParameters: listItemURL is null! Did you forget to set it in the constructor ?', 'SafariListComponent.preserveListParameters');
      return;
    } else {
      return this.listItemUrl + (row.id as string);
    }
  }
  preserveListParameters() {
    this.clearFilterOnDestroy = false;
    this.clearPageOnDestroy = false;
    this.clearSortOnDestroy = false;
    this.clearSelectedOnDestroy = false;
    this.clearCountsOnDestroy = false;
  }

  /**
   * This function will either return the column IF it exsits in the row OR
   * it will return the result of getXYZForDisplay function. It will also memoize
   * the function to improve performance
   * Use if you need to call some calculated properties and make sure they follow the pattern
   * where the column in HTML ends with ForDisplay and it corresponds to the function named
   * the same way with "get" prefix. For example - addressForDisplay in HTML will expect
   * a function named getAddressForDisplay in the TS file.
   *
   * Also should be used if we are not sure whether the column exists in the row or if it's calculated
   * (this will be the case )
   * @param row
   * @param column
   * @returns
   */
  get(row: TableItem<T>, column: string) {
    if (this._isForDisplayOverride(column)) {
      const getterFunctionName = 'get' + column.charAt(0).toUpperCase() + column.slice(1);
      // Do not blindly assume that ForDisplay function has a getter function
      // (getters are recommended to avoid modifying the original dataset but in some
      // cases a parent component might add some "ForDisplay" rows to the dataset before binding
      // to the table)
      if (typeof this[getterFunctionName] == 'function') {
        const memoizedGetterFunctionName = getterFunctionName + '_memoized';
        return this[memoizedGetterFunctionName](row);
      }
    }
    return row[column];
  }

  getTableSubmitInfo(additionalInfo = null): TableSubmitInfo {
    return { id: this.componentId, add: [...this.getItemsToAdd()], remove: [...this.getItemsToRemove()], update: [...this.getItemsToUpdate()], additionalInfo } as TableSubmitInfo;
  }
  protected getItemsToAdd(): T[] {
    this._addedRows = [];
    for (const row of this.rows) {
      if (row.__tableStatus === TableItemStatus.PendingUpload) {
        this._addedRows.push(row);
      }
    }

    return this._addedRows;
  }
  protected getItemsToRemove(): T[] {
    this._removedRows = [];
    for (const row of this.rows) {
      if (this.isRowPendingDelete(row)) {
        this._removedRows.push(row);
      }
    }

    return this._removedRows;
  }
  protected getItemsToUpdate(): T[] {
    const rowsToUpdate = [];
    for (const row of this.rows) {
      if (row.__tableStatus === TableItemStatus.PendingUpdate) {
        rowsToUpdate.push(row);
      }
    }

    return rowsToUpdate;
  }
  requestSubmit(additionalInfo = null) {
    this._store.dispatch(this.tableReduxObject.default.actions.tableSubmit({ payload: this.getTableSubmitInfo(additionalInfo) }));
  }
  createTableSubmitSubscription() {
    this.subscribe(
      this._actions.pipe(
        ofType(this.tableReduxObject.default.actions.tableSubmit),
        mergeMap(action =>
          of(action).pipe(
            filter(o => o.payload.id === this.componentId),
            catchError(err => {
              this._store.dispatch(
                reduxActionFail({
                  payload: action.payload,

                  originalPayload: action.payload,
                  reduxErrorOptions: { mustResolve: true, url: window.location.href, source: 'SafariTableComponent.createTableSubmitSubscription()' },
                  error: err
                })
              );
              return of(err);
            })
          )
        )
      ),
      action => {
        try {
          this._store.dispatch(this.tableReduxObject.default.actions.tableSubmitSuccess({ payload: { ...action.payload } as TableSubmitInfo }));
        } catch (o) {
          this._store.dispatch(this.tableReduxObject.default.actions.tableSubmitFail({ payload: action.payload, error: o }));
          this._store.dispatch(
            reduxActionFail({
              payload: action.payload,
              originalPayload: action.payload,
              originalOptions: action.options,
              context: action.additionalInfo,
              error: o,
              reduxErrorOptions: { mustResolve: true, url: window.location.href, source: 'SafariFormComponent.createTableSubmitSubscription()' }
            })
          );
        }
      }
    );
  }
  createTableRequestSubmitSubscription() {
    this.subscribe(
      this._actions.pipe(
        ofType(this.tableReduxObject.default.actions.tableRequestSubmit),
        mergeMap(o =>
          of(o).pipe(
            filter((payload: { id: string; additionalInfo: any }) => payload.id === this.componentId),
            catchError(err => {
              this._store.dispatch(
                reduxActionFail({
                  payload: o,
                  originalPayload: o,

                  error: err,
                  reduxErrorOptions: { mustResolve: true, source: 'SafariListComponent.createtableRequestSubmitSubscription()' }
                })
              );
              return of(o);
            })
          )
        )
      ),
      payload => {
        try {
          this.requestSubmit(payload.additionalInfo);
        } catch (o) {
          this._store.dispatch(
            reduxActionFail({
              payload,
              originalPayload: o.payload,

              error: o,
              reduxErrorOptions: { mustResolve: true, source: 'SafariListComponent.createtableRequestSubmitSubscription()' }
            })
          );
        }
      }
    );
  }
  onSelect({ selected }) {
    // Similar to how we deal with paging, sorting, etc, instead of directly
    // changing table props will dispatch a message. The sub that listens to this message
    // will make appropriate changes. This allows us to also preserve state , as well as issue select
    // commands remotely  if we ever need to
    this._reduxWrapper.dispatchGenericAction(this.tableReduxObject.default.actions.tableUpdateSelected({ payload: { id: this.componentId, selected: selected.map(o => o.id) } }));
  }
  canBeSelected(row: TableItem<T>) {
    return this.shouldShowBulkCheckboxes();
  }
  canDisplaySelectAll() {
    // Only display selection if there are rows and at least one can be selected and bulk actions are available
    return this.shouldShowBulkCheckboxes() && this.hasRows && this.rows.find(x => this.canBeSelected(x)) != null;
  }
  deleteSelected() {
    /**
     *  CALLABLE BY BULK ACTIONS. AVOID setTimeout OR async sendMessage
     */
    for (const selected of this.selected) {
      const row = this.rows.find(o => SafariObject.idEqual(o.id, selected.id));
      if (this.canBeDeleted(row, true)) {
        this.deleteItem(SafariObject.id(row.id));
        if (row.__isNew) {
          this.selected = this.selected.filter(o => !SafariObject.idEqual(o.id, row.id));
        }
      }
    }
  }
  canBeModifiedByUser(row: any, massSelect = false) {
    return !massSelect || this.canBeSelected(row);
  }

  canBeDeleted(row: TableItem<T>, massSelect = false): boolean {
    return true;
  }

  private _reassignRows() {
    this.rows = [...this.rows];
    if (this._tableWrapper) {
      this._tableWrapper.rows = this.rows;
    }
  }
}
class SortEntry {
  prop: string;
  direction: string;
}
export enum NullWeight {
  Low,
  High
}
