/* eslint-disable no-restricted-syntax -- This is a framework level file so it's ok */
import { Component, inject, Input, OnInit, ViewChild } from '@angular/core';
import { cloneDeep } from 'lodash-es';
import { take } from 'rxjs/operators';
import { PageUiService, SafariSmartComponent } from '../../../../forms/components/safari-base/safari-base.component';
import { SafariUiDataTableReduxObject } from '../../../../state/actions/layout-actions';

@Component({
  imports: [],
  selector: 'sl-ui-kit-text-filter',
  templateUrl: './text-filter.component.html',
  styleUrls: ['./text-filter.component.scss']
})
export class TextFilterComponent<PageServiceType extends PageUiService = PageUiService> extends SafariSmartComponent<PageServiceType> implements OnInit {
  initialValue = '';
  @Input()
  listId: string;
  @Input()
  disabled: boolean;

  @ViewChild('searchText') searchText;

  private _tableReduxObject: SafariUiDataTableReduxObject;
  constructor() {
    super();

    this._tableReduxObject = inject(SafariUiDataTableReduxObject);
  }

  ngOnInit() {
    super.ngOnInit();
    super.subscribe(this._reduxWrapper.getGenericSelector(this._tableReduxObject.default.selectors.getListFilterState(this.listId)).pipe(take(1)), o => {
      if (o != null && o.text) {
        this.initialValue = o.text;
      }
    });
  }

  hasSearchText() {
    return this.initialValue != '';
  }

  clearText() {
    this.searchText.nativeElement.value = '';
    this.updateTextFilter('');
    this.searchText.nativeElement.focus();
  }

  updateTextFilter(value) {
    this.subscribe(
      this._reduxWrapper
        .getGenericSelector(this._tableReduxObject.default.selectors.getListFilterState(this.listId))
        // skip the first entry in store. That's existing entry that would have been read by
        // the parent. We don't want to fire event change on that one
        .pipe(take(1)),
      o => {
        const filter = o != null ? cloneDeep(o) : {};
        filter.text = value.toLowerCase();
        this.initialValue = value;
        this._reduxWrapper.dispatchGenericAction(
          this._tableReduxObject.default.actions.tableUpdateFilter({
            payload: {
              id: this.listId,
              filter
            }
          })
        );
      }
    );
  }
}
