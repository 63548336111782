<sl-ui-kit-list-warning  ></sl-ui-kit-list-warning>

@if(tableMenuMode == TableMenuMode.Extended ) {
  @if  (bulkMenu || newMenu) {
    <sl-ui-kit-actions-bar >
      <ng-container [ngTemplateOutlet]="tableMenu" ></ng-container>
    </sl-ui-kit-actions-bar>
  } 

}

<!-- [disabled]="folderLocked" -->
@if(shouldShowTopSection) {
    <form  class="needs-validation" [formGroup]="filterForm">
        <div class="row s_tbl-topper" [class.s_tbl-nogap]="tableMenuMode == TableMenuMode.Extended && (bulkMenu || newMenu)" [class.pb-2]="tableMenuMode == TableMenuMode.Compact">
          @if(tableMenuMode == TableMenuMode.Compact) {
            <ng-container [ngTemplateOutlet]="tableMenu" ></ng-container>
          }

           
            @if(hasFilter) {
              <div [class]="textFilterClass">
                <sl-ui-kit-text-filter [listId]="id.replace('-list-wrapper', '')"></sl-ui-kit-text-filter>
              </div>
            }
            
            <ng-content select=".custom-table-filter"></ng-content>
 
          </div>
      </form>
}


<ng-content></ng-content> 
 
<!-- -->


<ng-template #tableMenu >

  @if(newMenu && shouldShowNewActionsCallback && shouldShowNewActionsCallback()) {
    <sl-ui-kit-flyout
    outerWrapperStyle="display: inline-block"
    [class.mt-2]="tableMenuMode == TableMenuMode.Compact"
    [flyoutClass]="'mt-1 px-0'"
    mainButtonClass="btn btn-outline-success btn-sm px-4 mx-1"
    mainButtonStyle="margin-top: -6px !important"
    name="+ NEW"

    [mainMenuTooltip]="newMenuTooltip"
    [disabled]="newMenuDisabled"
    [id]="id + '-new-item-button'"
   




    (flyoutToggled)="populateNewItemsCallback()"
    (flyoutItemClicked)="newMenuItemClickedCallback($event)"
    [list]="newMenuItems">
</sl-ui-kit-flyout>
}
@if(bulkMenu && shouldShowBulkActionsCallback && shouldShowBulkActionsCallback()) {
    <sl-ui-kit-flyout [id]="id + '-bulk-actions-dropdown'"
   outerWrapperStyle="display: inline-block"
    [class.mt-2]="tableMenuMode == TableMenuMode.Compact"
    [flyoutClass]="'mt-1 px-0'"
    mainButtonClass="btn btn-outline-info btn-sm px-4 mx-1"
    mainButtonStyle="margin-top: -6px !important"

    name="BULK ACTIONS"
 
   
    (flyoutToggled)="populateBulkItemsCallback()"
    (flyoutItemClicked)="bulkMenuItemClickedCallback($event)"
    [list]="bulkMenuItems">
</sl-ui-kit-flyout>
}
</ng-template>
