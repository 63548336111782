import { Injectable } from '@angular/core';
import { PreloadingStrategy, Route } from '@angular/router';
import { AuthService } from '@safarilaw-webapp/shared/auth/store-access';
import { Observable, ObservableInput, of } from 'rxjs';
import { filter, mergeMap } from 'rxjs/operators';
@Injectable({ providedIn: 'root', deps: [AuthService] })
export class AppCustomPreloader implements PreloadingStrategy {
  constructor(private _authService: AuthService) {}
  preload(route: Route, load: () => ObservableInput<any>): Observable<any> {
    return this._authService.initializationComplete.pipe(
      filter(o => o === true),
      mergeMap(() => (route.data && route.data.suppressPreload ? of(null) : load()))
    );
  }
}
