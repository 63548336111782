<div class="modal fade" bsModal #modal="bs-modal"
  tabindex="-1" role="dialog" [attr.data-test-id]="componentId" >
  <div class="modal-dialog modal-xl w-100" >
    <div class="modal-content">
      <div class="modal-body">
        @if (filesToAdd.length > 0 || filesToRemove.length > 0 || filesToMove.length > 0) {
          <div>
            @if (hasFilesToUpload) {
              <div>
                <div ><span class="text-primary">{{ getUploadMessage() }}</span> @if (numUploadErrors > 0) {
                <span class="text-danger" [attr.data-test-id]="componentId+'-upload-error-message'">{{ getUploadErrorsMessage() }}</span>
              } </div>
              <div class="progress my-2">
                @if (percentUploaded >= 0) {
                  <div class="progress-bar" [class.bg-danger]="numUploadErrors > 0"
                    role="progressbar" [attr.aria-valuenow]="percentUploaded" aria-valuemin="0" aria-valuemax="100"
                    [style.width.%]="percentUploaded">
                    <span class="sr-only">
                    {{ percentUploaded }}</span>
                  </div>
                }
                @if (percentUploaded < 0) {
                  <div class="progress-line"></div>
                }
              </div>
            </div>
          }
          @if (hasFilesToUpdate) {
            <div>
              <div ><span class="text-primary">Update progress: {{ updatedSoFar }} of {{ totalFilesToUpdate }} files ({{percentUpdated}}%)</span> @if (numUpdateErrors > 0) {
              <span class="text-danger" [attr.data-test-id]="componentId+'-update-error-message'">{{ getUpdateErrorsMessage() }}</span>
            } </div>
            <div class="progress my-2">
              @if (percentUpdated >= 0) {
                <div class="progress-bar" [class.bg-danger]="numUpdateErrors > 0"
                  role="progressbar" [attr.aria-valuenow]="percentUpdated" aria-valuemin="0" aria-valuemax="100"
                  [style.width.%]="percentUpdated">
                  <span class="sr-only">
                  {{ percentUpdated }}</span>
                </div>
              }
              @if (percentUpdated < 0) {
                <div class="progress-line"></div>
              }
            </div>
          </div>
        }
        @if (filesToRemove.length > 0) {
          <div>
            <div ><span class="text-primary">Remove progress: {{ removedSoFar }} of {{ totalFilesToRemove }} files ({{percentRemoved}}%) </span> @if (numRemoveErrors > 0) {
            <span class="text-danger"  [attr.data-test-id]="componentId+'-delete-error-message'">{{ getRemoveErrorsMessage() }}</span>
          } </div>
          <div class="progress my-2">
            @if (percentRemoved >= 0) {
              <div class="progress-bar" [class.bg-danger]="numRemoveErrors > 0"
                role="progressbar" [attr.aria-valuenow]="percentRemoved" aria-valuemin="0" aria-valuemax="100"
                [style.width.%]="percentRemoved">
                <span class="sr-only">
                {{ percentRemoved }}</span>
              </div>
            }
            @if (percentRemoved < 0) {
              <div class="progress-line"></div>
            }
          </div>
        </div>
      }
      @if (filesToMove.length > 0) {
        <div>
          <div ><span class="text-primary">Move progress: {{ movedSoFar }} of {{ totalFilesToMove }} files ({{percentMoved}}%) </span> @if (numMoveErrors > 0) {
          <span class="text-danger">{{ getMoveErrorsMessage() }}</span>
        } </div>
        <div class="progress my-2">
          @if (percentMoved >= 0) {
            <div class="progress-bar" [class.bg-danger]="numMoveErrors > 0"
              role="progressbar" [attr.aria-valuenow]="percentMoved" aria-valuemin="0" aria-valuemax="100"
              [style.width.%]="percentMoved">
              <span class="sr-only">
              {{ percentMoved }}</span>
            </div>
          }
          @if (percentMoved < 0) {
            <div class="progress-line"></div>
          }
        </div>
      </div>
    }
    <div class="my-3">
      <button type="button" class="link-button s_ico " (click)="toggleDetails()" >{{detailButtonName}}</button>
    </div>
  </div>
}

@if (showDetails) {
  <div class="my-3">
    <sl-ui-kit-file-transfer-table [componentId]="componentId+'-table'" [filter]="defaultFilter" [bindTo]="allFiles" [hasPreviewFiles]="false"></sl-ui-kit-file-transfer-table>
  </div>
}

<div class="text-center">

  <button [attr.data-test-id]="componentId+'-ok-button'" [class.disabled]="uploadCompleteWithoutErrors || isHiding" type="button" class="btn btn-outline-secondary" (click)="cancel()">
    {{ getButtonName() }}
  </button>
</div>
</div>
</div>
</div>
</div>