<ng-container>
  <div [class]="flyoutClass" [style]="outerWrapperStyle">
    <ng-container>

      <div [class]="getMainMenuClass()">
        <!--
        NOTE: This looks weird but we need it like this. We used to have hostlistener attribute in the
        TS file, but the mere presence of the listener was slowing down ngxdatatable to the point where
        it was barely usable. Not sure how or why but there was some weird interaction between the two.

        To solve the issue we are now only attach window.click when the main menu is actually visible.
        This seems to fix performance issues and still does what the flyout needs it to do

        Also note that we're using ngIf to take it out completely. With that it will take out window:click as well,
        but while the main menu is visible window: click will be active
        -->
        @if (isMainMenuVisible) {
          <div (window:click)="collapseAll()" ></div>
        }
        @if (!name) {
          <span [tooltip]="mainMenuTooltip"  container="body">
            @if (!name) {
              <button  [disabled]="disabled"  [id]="id + '-main-menu-button'" [class]="mainButtonClass" [style]="mainButtonStyle"  (click)="toggleMainMenuVisibility($event)">
                <ng-content>
                </ng-content>
              </button>
            }
          </span>
        }

        @if (name) {
          <span [tooltip]="mainMenuTooltip"  container="body">
            @if (name) {
              <button   [disabled]="disabled" [id]="id + '-main-menu-button'"
                (click)="toggleMainMenuVisibility($event)" type="button"
              [class]="mainButtonClass"  [style]="mainButtonStyle">{{name}}</button>
            }</span>
          }


          <div class="s_fld-act-content" [style.display]="mainMenuVisibility">

            <div class="s_fld-act-divider"></div>
            <ng-container>
              <ng-container *ngTemplateOutlet="recursiveListTmpl; context:{ list: list, idx:0 }">

              </ng-container>
            </ng-container>


          </div>
        </div>

      </ng-container>
    </div>

  </ng-container>

  <ng-template #recursiveListTmpl let-list="list" let-idx="idx">


    @for (item of list; track $index; let i = $index) {
      @if (item) {
        @if (item.children?.length > 0) {
          <div class="s_fld-act-submenu">
            <div [style.left]="getStyle(item.childGroupOffset)" class="s_fld-act-content s_fld-act-content-lg"
              [style.display]="menuItemClass(item.id)">
              <div class="s_fld-act-scroll">
                @if (item.childGroupName) {
                  <div class="s_fld-act-item s_fld-header">
                    <strong>{{item.childGroupName}}</strong></div>
                  }
                  @if (item.children?.length > 0) {
                    <div>
                      <ng-container
                      *ngTemplateOutlet="recursiveListTmpl; context:{ list: item.children, idx: idx+1 }"></ng-container>
                    </div>
                  }
                </div>
              </div>
              @if (item.children?.length) {
                <button [tooltip]="item.tooltip" [disabled]="isMenuItemDisabled(item.id)"
                  [id]="id + '-' + item.id + '-button' "
                  [attr.data-test-id]="item.dataTestId"
                  (click)="toggleMenuItemClass($event,item.id)" [class]="getItemClass(item)" [style]="getItemStyle(item)"  class="s_fld-act-item " type="button" tabindex="-1">
                  {{item.name}}<br>
                  @if (item.description) {
                    <div class="s_fld-act-item-sub">{{item.description}}</div>
                  }
                </button>
              }
            </div>
          }
          @if (!item.children?.length) {
            <button
              [tooltip]="item.tooltip"
              [attr.data-test-id]="item.dataTestId"
              [id]="id + '-' + item.id + '-button' "
              type="button" class="s_fld-act-item"  [class]="getItemClass(item)" [style]="getItemStyle(item)" [disabled]="isMenuItemDisabled(item.id)"
              (click)="itemClicked(item)">{{item.name}}
              <br>
                @if (item.description) {
                  <div class="s_fld-act-item-sub">{{item.description}}</div>
                }
              </button>
            }
          } @else {
            <div class="s_fld-act-divider"></div>
          }
        }

      </ng-template>


