import { InjectionToken, NgModule } from '@angular/core';
import { ActionReducerMap, StoreModule } from '@ngrx/store';

import { IAppControlState } from './state/interfaces/layout-interface';
import { UiReducerService } from './state/state.reducer';

const REDUCER_TOKEN = new InjectionToken<ActionReducerMap<IAppControlState>>('Ui  Reducers');
/**
 * This module registers redux for the shared ui kit, so any app that uses anything from our shared ui kit will need to import this.
 *
 * Note: This can't be imported by the controls themselves as they would keep blasting it away and recreating it. We don't want that
 * because sometimes we want to preserve state of a page on the component after it is destroyed (for example last table sort, filter,
 * before navigating to another page and coming back).
 *
 * In summary - this is best imported at the top of the main.ts with other main imports. The module justs created the state and a few messages, actions, etc.
 * DOES NOT import the controls themselves , so it's fairly small and shouldn't significantly impact the size of the initial app module
 */
@NgModule({
  imports: [StoreModule.forFeature('shared', REDUCER_TOKEN)],
  providers: [
    {
      provide: REDUCER_TOKEN,
      deps: [UiReducerService],
      useFactory: (service: UiReducerService) => service.reducers
    }
  ]
})
export class SharedUiKitStoreAccessModule {
  constructor() {}
}
