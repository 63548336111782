import { createSelector, DefaultProjectorFn, MemoizedSelector, Selector } from '@ngrx/store';
import { DefaultFileSelectorConverter, TransferDialogMessage } from '@safarilaw-webapp/shared/common-objects-models';

import { IFileUploadState } from '../interface';

const createGetFileOperationSelector = (fileUploadState: Selector<object, IFileUploadState>): MemoizedSelector<object, TransferDialogMessage[], DefaultProjectorFn<TransferDialogMessage[]>> =>
  createSelector(fileUploadState, state => state.files);

export const createDefaultFileOperationSelectors = (myFileUploadState: Selector<object, IFileUploadState>) =>
  new DefaultFileSelectorConverter({
    fileOperations: createGetFileOperationSelector(myFileUploadState)
  });
