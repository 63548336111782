import { NgTemplateOutlet } from '@angular/common';
import { Component, EventEmitter, Input, Output } from '@angular/core';
import { IdName } from '@safarilaw-webapp/shared/common-objects-models';
import { TooltipModule } from 'ngx-bootstrap/tooltip';
import { v4 as uuidv4 } from 'uuid';
export class FlyoutItem<T = any> extends IdName {
  dataTestId?: string;
  children?: FlyoutItem[];
  parentId?: string;
  disabled?: boolean;
  tooltip?: string;
  description?: string;
  childGroupName?: string;
  childGroupOffset?: number;
  class?: string;
  style?: string;
  additionalInfo?: T;
}
export enum FlyoutDirection {
  Left,
  Right
}
enum FlyoutMenuVisibility {
  Off = 'none',
  On = 'block'
}
@Component({
  imports: [NgTemplateOutlet, TooltipModule],
  selector: 'sl-ui-kit-flyout',
  templateUrl: './flyout.component.html',
  styleUrls: ['./flyout.component.scss']
})
export class FlyoutComponent {
  mainMenuVisibility = FlyoutMenuVisibility.Off;

  subItemClasses: Map<string, string> = new Map<string, string>();
  private _list: FlyoutItem[];
  @Input()
  outerWrapperStyle = '';
  @Input()
  disabled = false;
  /**
   * It'd be nice if we can call this just "tooltip", but then
   * it would conflict with the tooltip directive attribute
   * and ngx-bootstrap would try to append an additional tooltip to the entire
   * flyout element. Therefore we'll just call it "mainMenuTooltip"
   */

  @Input()
  mainMenuTooltip = '';
  @Input()
  flyoutClass = 'my-2 px-0';
  @Input()
  name: string;
  @Input()
  mainButtonClass = 'btn btn-outline-success btn-sm mx-1 px-4';
  @Input()
  mainButtonStyle = '';
  @Input()
  mainMenuClass = 's_fld-act-wrap';
  @Input()
  flow = FlyoutDirection.Right;
  @Input()
  id: string;
  @Output()
  flyoutItemClicked = new EventEmitter<FlyoutItem>();
  @Output()
  flyoutToggled = new EventEmitter();

  private _appendIds(list: FlyoutItem[], parentId = null) {
    list.forEach(element => {
      if (element) {
        if (!element.id) {
          element.id = uuidv4();
        }

        element.parentId = parentId;
        if (element.children?.length) {
          this._appendIds(element.children, element.id);
        }
      }
    });
  }

  getItemStyle(item) {
    return item.style;
  }
  getItemClass(item) {
    let arrowClassName = '';
    if (item.children?.length && !item.disabled) {
      const defaultArrowClassName: string = this.flow == FlyoutDirection.Left ? 'arrow-left' : 'arrow-right';
      arrowClassName = defaultArrowClassName + (item.description ? '-desc' : '-no-desc');
    }

    return (item.class as string) + ' ' + arrowClassName;
  }
  getMainMenuClass() {
    return this.mainMenuClass + ' s_fld-act-wrap' + (this.flow == FlyoutDirection.Left ? ' s_fld-wrap-left  float-right' : '');
  }
  private _getById(list: FlyoutItem[], id: string) {
    const item = list.find(o => o?.id === id);
    if (item) return item;
    // eslint-disable-next-line @typescript-eslint/prefer-for-of -- will fix later
    for (let i = 0; i < list.length; i++) {
      const children = list[i]?.children;
      if (children) {
        const found = this._getById(children, id);
        if (found) return found;
      }
    }
  }
  getParents(arr, childId): string[] {
    const parents = [];
    const findParents = (id, items) => {
      items.forEach(item => {
        if (item) {
          if (item.id === id) {
            parents.push(item.id);
            if (item.parentId !== null) {
              findParents(item.parentId, arr);
            }
          } else if (item.children) {
            findParents(id, item.children);
          }
        }
      });
    };
    findParents(childId, arr);
    return parents;
  }

  public get list(): FlyoutItem[] {
    return this._list;
  }
  @Input()
  public set list(value: FlyoutItem[]) {
    this._list = [...value];
    this._appendIds(this._list);
  }

  menuItemClass(id: string) {
    return this.subItemClasses.get(id) || FlyoutMenuVisibility.Off;
  }
  isMenuItemDisabled(id: string) {
    const item = this._getById(this.list, id);
    return item?.disabled;
  }
  get isMainMenuVisible() {
    return this.mainMenuVisibility == FlyoutMenuVisibility.On;
  }
  toggleMainMenuVisibility(event) {
    // Get the current menu class
    const mainMenuClass = this.mainMenuVisibility;
    // Always broadcast window.click. That will make sure that any OTHER
    // flyouts close their menus, making sure that only one flyout is open
    // at any given time
    window.dispatchEvent(new Event('click'));
    // Only if the main menu was already OFF should we turn it on. If it was already ON
    // we'll skip this code (dispatchevent that closes other flyouts will close this one as well)
    if (mainMenuClass == FlyoutMenuVisibility.Off) {
      // Using setTimeout here to make sure there is no racing between global window.dispatchEvent
      // and this particular control. This is invisible to the user (no flicker)
      setTimeout(() => {
        this.mainMenuVisibility = this._flipDisplay(event, this.mainMenuVisibility);
        this.collapseAll(this.mainMenuVisibility == FlyoutMenuVisibility.On ? 'MAIN' : null);
        this.flyoutToggled.emit();
      });
    }
  }
  toggleMenuItemClass(event, id: string) {
    const subItemClass = this.menuItemClass(id);
    const classAfterFlip = this._flipDisplay(event, subItemClass);
    this.subItemClasses.set(id, classAfterFlip);
    this.collapseAll(id, classAfterFlip == FlyoutMenuVisibility.Off);
  }
  private _flipDisplay(event, variable) {
    event.preventDefault();
    event.stopPropagation();
    if (variable == FlyoutMenuVisibility.Off) {
      variable = FlyoutMenuVisibility.On;
    } else {
      variable = FlyoutMenuVisibility.Off;
    }
    return variable;
  }
  /**
   * This function is called on click of either main menu or any child menu
   * It always collapses all the menus except the one in "exclude" param and its parents
   * @param id - Id of the menu that was clicked.
   */
  collapseAll(id: string = null, collapseSelf = false) {
    this.subItemClasses = new Map<string, string>();
    if (id != null) {
      this.mainMenuVisibility = FlyoutMenuVisibility.On;
      const parentsAndSelf = this.getParents(this.list, id);
      parentsAndSelf.forEach(elementId => {
        this.subItemClasses.set(elementId, elementId != id || !collapseSelf ? FlyoutMenuVisibility.On : FlyoutMenuVisibility.Off);
      });
    } else {
      this.mainMenuVisibility = FlyoutMenuVisibility.Off;
    }
  }

  getStyle(offset: number) {
    if (offset) {
      return offset.toString() + 'px';
    }

    return '185px';
  }

  itemClicked(item) {
    this.flyoutItemClicked.emit(item);
  }
}
