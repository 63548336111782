 
    <ngx-datatable 
    [id]="id + '-ngx-datatable'"
    [rows]="rows" 
    [columns]="cols"
    [rowClass]="getRowClassesFunctionCallback  != null ? getRowClassesFunctionCallback : null" 
    [offset]="page" 
    [limit]="pageSize" 
    [selected]="selected"
    [headerHeight]="headerHeight"
    [footerHeight]="footerHeight" 
    [rowHeight]="rowHeight" 
    [selectionType]="selectionType"
    [columnMode]="columnMode"
    [reorderable]="reorderable" 
    (select)="onSelectCallback != null ? onSelectCallback($event) : null"
    (page)="onPageCallback != null ? onPageCallback($event) : null"
    ></ngx-datatable> 
    
    <ng-content></ng-content> 
    <ng-template #checkboxHeaderTemplate let-column="column" let-value="value" let-allRowsSelected="allRowsSelected" let-selectFn="selectFn">
      <div class="s_top s_txt-sm">
        @if(canDisplaySelectAllCallback()) {
            <div class="custom-control custom-checkbox custom-checkbox-sm" style="margin-top:0px" >
                <input  type="checkbox" class="custom-control-input" [id]="'customFileCheckAll' + '-' + id " [checked]="allRowsSelected"
                    (change)="selectFn(!allRowsSelected)">
                <label class="custom-control-label s_txt-sm checkbox-inline" [attr.for]="'customFileCheckAll' + '-' + id "></label>
            </div>
        }

      </div>
    </ng-template>
    
    <ng-template #checkboxCellTemplate let-row="row" let-rowIndex='rowIndex' let-value="value"
      let-isSelected="isSelected" let-onCheckboxChangeFn="onCheckboxChangeFn">
      <div class="s_txt-sm s_top" [class.s_status-or-check]="canBeSelectedCallback(row)">
        @let checkboxOverride =  getCheckboxOverride(isSelected, row);
        @if (checkboxOverride) {
          <span [class]="checkboxOverride.class">
            {{ checkboxOverride.value }}
          </span>
         
        } @else if (canBeSelectedCallback(row)) {
            <div class="s_highlight" style="background-color:unset" >
                <div class="custom-control custom-checkbox custom-checkbox-sm" [class.s_checked]="isSelected">
                    <input [checked]="isSelected" (change)="onCheckboxChangeFn($event)" type="checkbox"
                        class="custom-control-input" [id]="'customFileCheck' + '-' + id + '-' + rowIndex">
                    <label class="custom-control-label s_txt-sm checkbox-inline"
                        [attr.for]="'customFileCheck' + '-' + id + '-' + rowIndex"></label>
                </div>
            </div>
        }
        

      </div>
     
    </ng-template>
    <ng-template #fileActionsHeaderTemplate let-column="column">
      <div class="s_top">&nbsp;</div>
  
  </ng-template>
  <ng-template #fileActionsCellTemplate let-row="row"  let-rowIndex='rowIndex' >
  
     @if(shouldShowRowActionsCallback(row)) {
      <sl-ui-kit-flyout [id]="id + '-row-menu-' + rowIndex" 
      [class]="getHiderClassCallback(row)"
      [flow]="FlyoutDirection.Left"
      flyoutClass=""
  
      mainMenuClass="s_ellipsis-menu"
      mainButtonClass="link-button"
      (flyoutToggled)="rowMenuButtonClickedCallback(row)"
      (flyoutItemClicked)="rowMenuItemClickedCallback($event)"
      [list]="rowMenuItems">
   
           
              <ins  class="s_ico s_ico-more_fill"></ins>
   
    
  </sl-ui-kit-flyout>
    } @else if (shouldShowSingleDelete(row)) {
      <button type="button" class="link-button" [disabled]="!canBeDeletedCallback(row)"   (click)="rowMenuItemClickedCallback({id: RowMenuActions.Delete, additionalInfo: row})"    >
        <span class="s_ico s_ico-remove_fill"></span>
    </button>
    }

   
  </ng-template>
    