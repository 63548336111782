import { Directive, Input, OnInit } from '@angular/core';
import { IdName } from '@safarilaw-webapp/shared/common-objects-models';
import { ReduxWrapperService } from '@safarilaw-webapp/shared/redux';
import { cloneDeep } from 'lodash-es';
import { combineLatest } from 'rxjs';
import { take } from 'rxjs/operators';
import { PageUiService, SafariSmartComponent } from '../../../../forms/components/safari-base/safari-base.component';
import { SafariUiDataTableReduxObject } from '../../../../state/actions/layout-actions';

@Directive()
export class OnChangeFilterBase<PageServiceType extends PageUiService = PageUiService> extends SafariSmartComponent<PageServiceType> implements OnInit {
  @Input()
  listId: string;
  @Input()
  filterValues: IdName[];
  @Input()
  propName: string;

  private _labelName = 'View';
  @Input()
  set labelName(val: string) {
    this._labelName = val;
  }
  get labelName(): string {
    return this._labelName;
  }

  private _selectedFilterValue = null;
  @Input()
  set selectedFilterValue(value: any) {
    this._selectedFilterValue = value;
  }
  get selectedFilterValue(): any {
    return this._selectedFilterValue;
  }

  private _tableReduxObject: SafariUiDataTableReduxObject;
  updateFilter(filterValue) {
    // TODO: 9480
    // For some reason we are trying to cast to number by using +, meaning non-number strings will be Nan
    // For now just pass the string if it can't convert to number, then we need to follow up in 9480 and
    // remove this restriction
    if (isNaN(+filterValue)) {
      this.selectedFilterValue = filterValue;
    } else {
      this.selectedFilterValue = +filterValue;
    }
    // Updated the selected filter to the new value

    this.updateFilterState();
    // now filter the results.  this applies all filters.
  }
  updateFilterState() {
    this.subscribe(
      this._reduxWrapper
        .getGenericSelector(this._tableReduxObject.default.selectors.getListFilterState(this.listId))
        // skip the first entry in store. That's existing entry that would have been read by
        // the parent. We don't want to fire event change on that one
        .pipe(take(1)),
      o => {
        const filter = cloneDeep(o);
        filter[this.propName] = this.selectedFilterValue;
        this._reduxWrapper.dispatchGenericAction(
          this._tableReduxObject.default.actions.tableUpdateFilter({
            payload: {
              id: this.listId,
              filter
            }
          })
        );
      }
    );

    combineLatest([
      this._reduxWrapper.getGenericSelector(this._tableReduxObject.default.selectors.getListPageState(this.listId)),
      this._reduxWrapper.getGenericSelector(this._tableReduxObject.default.selectors.getListSortState(this.listId)),
      this._reduxWrapper.getGenericSelector(this._tableReduxObject.default.selectors.getListFilterState(this.listId))
    ])
      .pipe(take(1))
      .subscribe(([page, sort, filter]) => {
        this._reduxWrapper.dispatchGenericAction(
          this._tableReduxObject.default.actions.tableParameterUserChange({
            payload: { id: this.listId, page, sort, filter }
          })
        );
      });
  }
  constructor() {
    super();
    this._tableReduxObject = this.inject(SafariUiDataTableReduxObject);
    this._reduxWrapper = this.inject(ReduxWrapperService);
  }

  ngOnInit() {
    super.ngOnInit();
    super.subscribe(this._reduxWrapper.getGenericSelector(this._tableReduxObject.default.selectors.getListFilterState(this.listId)), o => {
      if (o && o[this.propName] != null) {
        this.selectedFilterValue = o[this.propName];
      } else {
        this.selectedFilterValue = null;
      }
    });
  }
}
