import { InjectionToken, NgModule } from '@angular/core';
import { ActionReducerMap, StoreModule } from '@ngrx/store';
import { ISharedState } from '@safarilaw-webapp/shared/dialog/store-access';

import { AppReducerService } from './state/state.reducer';
const REDUCER_TOKEN = new InjectionToken<ActionReducerMap<ISharedState>>('App State Reducers');

@NgModule({
  declarations: [],
  imports: [StoreModule.forFeature('appState', REDUCER_TOKEN)],
  providers: [
    {
      provide: REDUCER_TOKEN,
      deps: [AppReducerService],
      useFactory: (service: AppReducerService) => service.reducers
    }
  ]
})
export class SharedAppBootstrapStoreAccessModule {}
