// Don't want to create index files, etc, for what is essentially a dev file so just importing directly...
/* eslint-disable @nx/enforce-module-boundaries  -- comments above */

import { Injectable } from '@angular/core';
import { Actions, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { AppDialogUiReduxObject, ConfirmationDialogRequest, ConfirmationDialogResponse, ModalDialogService } from '@safarilaw-webapp/shared/dialog/store-access';
import { AppConfigurationService } from '@safarilaw-webapp/shared/environment';
import { AbortSave } from '@safarilaw-webapp/shared/redux';
import { Hotkey, HotkeysService } from 'angular2-hotkeys';
import { ErrorComponent } from 'libs/feature/dev-tools/src/lib/components/error/error.component';
import { SettingsOverrideComponent } from 'libs/feature/dev-tools/src/lib/components/settings-override/settings-override.component';
import { DevtoolsDialogComponent } from 'libs/feature/dev-tools/src/lib/devtools-dialog/index';
import { Observable, filter, map, mergeMap, of, take, throwError } from 'rxjs';
import { v4 as uuidv4 } from 'uuid';

import { AppUiReduxObject } from '@safarilaw-webapp/shared/app-bootstrap/data-access';
import { HotKeyRegistrationServiceCommon } from 'libs/shared/app-bootstrap/src/lib/app/hotkey-registration-service.common';
import { ToastrService } from 'ngx-toastr';

@Injectable({
  providedIn: 'root'
})
export class HotKeyRegistrationService {
  observeDialogResponse$(id: string): Observable<ConfirmationDialogResponse> {
    return this._getDialogResponseObservable$(id).pipe(take(1));
  }

  private _getDialogResponseObservable$(id: string = null): Observable<ConfirmationDialogResponse> {
    return this._actions
      .pipe(
        ofType(this._appDialogUiReduxObject.default.actions.modalDialogDismissed),
        filter(o => o.payload.id == id)
      )
      .pipe(
        map(o => o.payload),
        take(1)
      );
  }

  showDialogOnce$(
    dialogRequest: ConfirmationDialogRequest,
    options: {
      shouldAbortSaveFn: (o: ConfirmationDialogResponse) => boolean;
      abortSaveReturns?: any;
    } = null
  ) {
    const id = dialogRequest.id || uuidv4();
    // To see why we're creating an observable that dispatches an action look at createOrUpdateObjectOnce$ comments
    const func = new Observable<void>(observer => {
      setTimeout(() =>
        this._store.dispatch(
          this._appDialogUiReduxObject.default.actions.openModalDialog({
            payload: { ...dialogRequest, id, modalClass: 'modal-xl' }
          })
        )
      );

      observer.next();
      observer.complete();
    }).pipe(mergeMap(() => this.observeDialogResponse$(id)));

    return options?.shouldAbortSaveFn
      ? func.pipe(
          mergeMap(o => {
            if (options.shouldAbortSaveFn(o) == true) {
              this._store.dispatch(this._appUiReduxObject.default.actions.toggleBlockUi({ payload: { value: false, transparent: false } }));
              return throwError(() => new AbortSave(options?.abortSaveReturns));
            }
            return of(o);
          })
        )
      : func;
  }

  constructor(
    private _appConfigurationService: AppConfigurationService,
    private _actions: Actions<unknown>,
    private _appDialogUiReduxObject: AppDialogUiReduxObject,
    private _hotkeysService: HotkeysService,
    private _appUiReduxObject: AppUiReduxObject,
    private _store: Store<any>,
    private _toastrService: ToastrService,
    dialogService: ModalDialogService
  ) {
    dialogService.registerComponent(DevtoolsDialogComponent.ClassId, DevtoolsDialogComponent);
  }
  registerHotkeys() {
    HotKeyRegistrationServiceCommon.registerHotkeys(this._hotkeysService, this._appUiReduxObject, this._store);

    SettingsOverrideComponent.applyOverrides(this._appConfigurationService, this._toastrService);
    ErrorComponent.verifySettings(this._toastrService);
    this._hotkeysService.add(
      new Hotkey(
        'alt+t',
        (): boolean => {
          this.showDialogOnce$({
            component: DevtoolsDialogComponent.ClassId,
            parentData: null,
            noUiBlock: true
          }).subscribe(x => {});
          return false; // Prevent bubbling
        },
        ['INPUT', 'SELECT', 'TEXTAREA']
      )
    );
  }
}
