import { Injectable } from '@angular/core';
import { AppUiReduxObject } from '@safarilaw-webapp/shared/app-bootstrap/data-access';
import { ReduxWrapperService } from '@safarilaw-webapp/shared/redux';
import { filter } from 'rxjs/operators';
import { SafariUiAccordionGroupReduxObject, SafariUiAccordionReduxObject, SafariUiComponentReduxObject } from '../../../state/actions/layout-actions';

@Injectable({
  providedIn: 'root'
})
export class AccordionGroupControlService {
  constructor(
    private _reduxWrapperService: ReduxWrapperService,

    private _accordionGroupReduxObject: SafariUiAccordionGroupReduxObject
  ) {}
  getState$(id: string) {
    return this._reduxWrapperService.getGenericSelector(this._accordionGroupReduxObject.default.selectors.getState(id));
  }
}
@Injectable({
  providedIn: 'root'
})
export class AccordionControlService {
  constructor(
    private _reduxWrapperService: ReduxWrapperService,

    private _accordionReduxObject: SafariUiAccordionReduxObject
  ) {}

  expandAccordion(id: string) {
    this._reduxWrapperService.dispatchGenericAction(this._accordionReduxObject.default.actions.accordionExpand({ payload: { id } }));
  }
  collapseAccordion(id: string) {
    this._reduxWrapperService.dispatchGenericAction(this._accordionReduxObject.default.actions.accordionCollapse({ payload: { id } }));
  }
  toggleAccordion(id: string) {
    this._reduxWrapperService.dispatchGenericAction(this._accordionReduxObject.default.actions.accordionToggle({ payload: { id } }));
  }
  clearAccordionState(id: string) {
    this._reduxWrapperService.dispatchGenericAction(this._accordionReduxObject.default.actions.clearState({ payload: { id } }));
  }

  listenAccordionExpanded(id: string = null) {
    return this._reduxWrapperService.listenForAction(this._accordionReduxObject.default.actions.accordionExpanded).pipe(filter(o => id == null || o.payload.id == id));
  }
  listenAccordionCollapsed(id: string = null) {
    return this._reduxWrapperService.listenForAction(this._accordionReduxObject.default.actions.accordionCollapsed);
  }
  listenAccordionToggled(id: string = null) {
    return this._reduxWrapperService.listenForAction(this._accordionReduxObject.default.actions.accordionToggled);
  }
}
@Injectable({
  providedIn: 'root'
})
export class HotkeyControllerService {
  constructor(
    private _reduxWrapperService: ReduxWrapperService,
    private _appUiReduxObject: AppUiReduxObject,
    private _uiComponentReduxObject: SafariUiComponentReduxObject
  ) {}

  getState$() {
    return this._reduxWrapperService.getGenericSelector(this._appUiReduxObject.default.selectors.getHotkey);
  }
}
