import { HttpErrorResponse, HttpResponse } from '@angular/common/http';
import { AfterViewInit, ApplicationRef, ComponentRef, Directive, DoCheck, HostListener, OnDestroy, OnInit, ViewContainerRef } from '@angular/core';
import { ActivatedRoute, Data, NavigationEnd, ParamMap, RouterEvent, RouterStateSnapshot } from '@angular/router';
import { Actions, ofType } from '@ngrx/effects';
import { Action, ActionsSubject, DefaultProjectorFn, MemoizedSelector, createFeatureSelector, createSelector } from '@ngrx/store';
import { ActionCreator, NotAllowedCheck } from '@ngrx/store/src/models';
import { AppUiReduxObject, SafariHotkey } from '@safarilaw-webapp/shared/app-bootstrap/data-access';
import {
  AttachmentLink,
  AuthReduxObject,
  BROWSERVIEWER,
  ClearFileInfoPayload,
  FileDownloadRequest,
  FileObjectForTransferDialog,
  FileOperationType,
  FilePreviewRequest,
  SafariObject,
  SafariObjectId,
  TransferDialogMessage,
  TransferDialogOptions,
  clearAllFileStores
} from '@safarilaw-webapp/shared/common-objects-models';
import { FileDownloadService } from '@safarilaw-webapp/shared/crud';
import { CHUNK_RETRY_COUNT_PARAM } from '@safarilaw-webapp/shared/error-handling';
import { FailedObjectsService } from '@safarilaw-webapp/shared/failed-objects';
import { ApplicationInsightsService } from '@safarilaw-webapp/shared/logging';
import {
  AbortSave,
  ActionErrorBase,
  ActionSilenceErrorMode,
  HttpMethodOverride,
  IReduxProvider,
  ISafariObjectState,
  LoadObjectActionFailInfo,
  NotifcationRequestType,
  ReduxDataAccessObject,
  ReduxWrapperService,
  SafariReduxApiObject,
  UpdateObjectListActionFailInfo,
  UpdateOrCreateActionInfo,
  dataAccessMixin
} from '@safarilaw-webapp/shared/redux';
import { SafariRouterReduxObject } from '@safarilaw-webapp/shared/routing-utility/store-access';
import { PreviousRouteService } from '@safarilaw-webapp/shared/utils';

import { IndividualConfig, ToastrService } from 'ngx-toastr';
import { Observable, Subscription, combineLatest, of, throwError } from 'rxjs';
import { filter, map, mergeMap, skip, take, tap } from 'rxjs/operators';
import { v4 as uuidv4 } from 'uuid';
import { SafariUiDataTableReduxObject, SafariUiFilePreviewReduxObject, SafariUiFormReduxObject } from '../../../state/actions/layout-actions';
import { FormSubmitInfo } from '../../../state/interfaces/layout-interface';
import { AccordionControlService, AccordionGroupControlService, HotkeyControllerService } from '../safari-accordion/accordion-controller.service';

import { FileObject } from '@safarilaw-webapp/shared/common-objects-models';
import { DialogSize, FileTransferDialogComponent } from '../../../dialog/components/file-transfer/file-transfer-dialog/file-transfer-dialog.component';

import { saveAs } from '../../../file-download/file-saver';
import { TableControlService } from '../../../list/services/table-control.service';
import { FormControlService } from '../../services/form-control/form-control.service';
import { PageUiService, SafariSmartComponent } from '../safari-base/safari-base.component';
import { dialogControlMixin } from './dialog-control-mixin';
/*
  Safari Base Page Component

  This should be an entry page to all pages. Should not be directly inherited from except by the feature's base page. 
  Then other pages of the feature will inherit from that page. Example : LpmsBasePageComponent
*/
class PrepareForBulkTransferResponse<T extends SafariObject> {
  actionId: string;
  transferDialogOptions: TransferDialogOptions;
  operationType: FileOperationType;
  object: T;
}
@Directive()
export class SafariBasePageComponent<PageServiceType extends PageUiService = PageUiService>
  extends dialogControlMixin(dataAccessMixin(SafariSmartComponent))<PageServiceType>
  implements DoCheck, OnInit, OnDestroy, AfterViewInit
{
  private static _fileObservable$: Observable<TransferDialogMessage[]>;

  // Set this to NULL if we don't want to use file transfer dialog
  protected fileTransferDialogOptions: {
    id: string;
    dialogSize: DialogSize;
  } = {
    id: 'fileTransferDialog', // <-- I don't even think we need to customize these. There is no reason to have multiple file transfer dialogs (nor is it designed for it).
    // But keeping it here for now
    dialogSize: DialogSize.ExtraLarge
  };
  private _fileTransferDialogComponentRef: ComponentRef<FileTransferDialogComponent>;
  private _listFilterSubscriptions = new Map<any, Subscription>();
  private _formSubmitSubscriptionSubmit: Subscription;
  private _formSubmitSubscriptionSuccess: Subscription;
  private _formSubmitSubscriptionFail: Subscription;
  protected _failedObjectsService: FailedObjectsService;
  protected _appInsights: ApplicationInsightsService;

  public fileDialogDismissed$: Observable<ClearFileInfoPayload>;

  private _fileDownloadService: FileDownloadService;
  private _viewContainerRef: ViewContainerRef;
  protected dispatcher: ActionsSubject;
  protected _formReduxObject: SafariUiFormReduxObject;
  private _filePreviewUiReduxObject: SafariUiFilePreviewReduxObject;
  private _tableReduxObject: SafariUiDataTableReduxObject;
  private _safariRouterReduxObject: SafariRouterReduxObject;
  private _route: ActivatedRoute;
  private _previousRouteService: PreviousRouteService;
  protected toastrService: ToastrService;
  protected currentToastrId = 0;
  protected appRef: ApplicationRef;

  public hotKeyApi: HotkeyControllerService;
  public tableApi: TableControlService;
  public accordionApi: AccordionControlService;
  public accordionGroupApi: AccordionGroupControlService;
  public formApi: FormControlService;

  routeParams: ParamMap;
  routeQueryParams: ParamMap;
  routeData: Data;
  isSaveButtonDisabled = false;

  protected toastrConfig = {
    positionClass: 'toast-bottom-right',
    easeTime: 0,
    timeOut: 2000,
    disableTimeOut: true
  } as IndividualConfig;
  protected toastrConfigTimeout = {
    positionClass: 'toast-bottom-right',
    easeTime: 0
  } as IndividualConfig;

  constructor() {
    super();
    /** Be mindful of what you put in the constructor. In many of our UTs
     * we are bypassing injections to make them faster and we just new up the component manually.
     * For this reason the constructor should only inject via this.inject and at most set some
     * simple properties.
     *
     * If there is anything that depends on the result of injection it should be checked for NULL,
     * but even better approach might be to put that in onInit if possible.
     *
     * Observable defs should go to onInit
     */

    window['safari-top-page'] = this;
    this.tableApi = this.inject(TableControlService);

    this.formApi = this.inject(FormControlService);
    this.accordionApi = this.inject(AccordionControlService);
    this.accordionGroupApi = this.inject(AccordionGroupControlService);
    this.dispatcher = this.inject(ActionsSubject);
    this._appUiReduxObject = this.inject(AppUiReduxObject);
    this._authReduxObject = this.inject(AuthReduxObject);
    this.hotKeyApi = this.inject(HotkeyControllerService);
    this._reduxWrapper = this.inject(ReduxWrapperService);
    this._failedObjectsService = this.inject(FailedObjectsService);
    this._filePreviewUiReduxObject = this.inject(SafariUiFilePreviewReduxObject);
    this._appInsights = this.inject(ApplicationInsightsService);
    this._fileDownloadService = this.inject(FileDownloadService);
    this._formReduxObject = this.inject(SafariUiFormReduxObject);

    this._tableReduxObject = this.inject(SafariUiDataTableReduxObject);
    this._previousRouteService = this.inject(PreviousRouteService);
    this.appRef = this.inject(ApplicationRef);
    this._safariRouterReduxObject = this.inject(SafariRouterReduxObject);

    this._actions = this.inject(Actions);
    this._viewContainerRef = this.inject(ViewContainerRef);

    this._route = this.inject(ActivatedRoute);
    this.toastrService = this.inject(ToastrService);
    if (this._route) {
      this.routeParams = this._route.snapshot.paramMap;
      this.routeQueryParams = this._route.snapshot.queryParamMap;
      this.routeData = this._route.snapshot.data;
    }
    if (this.___router) {
      this.subscribe(this.___router.events.pipe(filter(evt => evt instanceof NavigationEnd)), evt => {
        this.onNavigationEnd(evt as RouterEvent);
      });
    }
    // The idea here is that file event updates, which are being broadcast from each projects file transfer service,
    // are common constructs accross all data access projects. They only differ in where they store their file state.
    // So as the page spins up for the first time it will go through the store root and find all data access projects
    // then check each one of them if they define fileCombinedState, and if so it will create selectors. This will
    // be done only the first time the page spins up and from there all pages will use that shared observable.
    // This works well, but there is one gotcha with it. This states are created as part of importing various FeatureDataAccess
    // modules, and that is currently done in the feature module (for example LPMS imports LPMSDataAccess and CoManageDataAccess, while Rms imports RmsDataAccess).
    // So that means that this page constructor better be called from the feature module and not before that. Currently that is the case, so no issue there.
    // BUT if we were ever to extend SafariBasePageComponet from the appbootstrap module then that could be an issue.
    // Couple of possible solutions to this:
    // 1. Have the app , not module, import those DataAccessModules. We may in the end want to do that anyway and get rid of modules alltogether. Just not sure what size impact that will have, we'll have to see
    // 2. Don't do this in the constructor. Instead, since both of our projects define top-level Rms/LpmsBasePage which extends this and all their components extend project-base page, we could have project-base page
    // call this as a separate function in its own constructor.

    if (SafariBasePageComponent._fileObservable$ == null && this._store) {
      const fileSelectorObservables: Observable<TransferDialogMessage[]>[] = [];
      // First let's build the array of all selectors of each project's file state.
      // We'll do that by naming convention knowing that each project is at the top of the store,
      // and it starts with data-access- prefix
      this._store
        // Let's select the root state in the store. We don't care about the values. We just want to know the keys
        .select(state => state)
        .pipe(take(1))
        .subscribe(o => {
          // Now go through each top level key. Some of them will start with data-access- and those refer to data access
          // store of each individual project
          for (const key in o as any) {
            if (key.startsWith('data-access-')) {
              // We are now in data access store of a project. Now let's just make sure it does have files (squirrel doesn't for example)
              // and if it does let's set up the selector and push an observable to it into the array
              if (o[key].fileCombinedState != null) {
                const getSharedFeatureState = createFeatureSelector<any>(key);
                const getMyState = createSelector(getSharedFeatureState, state => state.fileCombinedState.fileState);
                const selector = createSelector(getMyState, state => state.files);
                fileSelectorObservables.push(this._store.select(selector));
              }
            }
          }
        });
      // Now that we built selector obervables for each file data access state we're going to combine them and flatten
      // them so they can be sent to file transfer dialog
      if (fileSelectorObservables.length > 0) {
        // This will combine observables for all data acces states available in this project into one observable
        // results of which will be sent to file transfer dialog component
        SafariBasePageComponent._fileObservable$ = combineLatest(fileSelectorObservables).pipe(
          map(arrayOfInputs => {
            const flattened: TransferDialogMessage[] = [];
            for (const input of arrayOfInputs) {
              for (const file of input) {
                flattened.push(file);
              }
            }
            return flattened;
          })
        );
      } else {
        // If there are no projects with file states just set the observable to empty array. Anything non-null really,
        // just so we don't have to keep checking for nulls
        SafariBasePageComponent._fileObservable$ = of([]);
      }
    }
  }
  observeFiles$() {
    return SafariBasePageComponent._fileObservable$;
  }
  observeFilePreview$() {
    return this._reduxWrapper.getGenericSelector(this._filePreviewUiReduxObject.previewFileEdit.selector);
  }
  protected setupFileListener() {
    if (this.fileTransferDialogOptions) {
      this.subscribe(
        this.observeFiles$().pipe(
          tap(filesFromStore => {
            this.dispatchAction(this._authReduxObject.default.actions.updateLastActivityTimestamp());
            if (this._fileTransferDialogComponentRef) {
              this._fileTransferDialogComponentRef.instance.filesFromStore = filesFromStore;
            }
          })
        )
      );
    }
  }

  get previousUrl(): string {
    return this._previousRouteService.previousUrl;
  }
  get currentUrl(): string {
    return this.___router.url;
  }
  /**
   *
   * @param args all data access objects
   * Registers data access objects so that the framework can track, abort, and clear errors
   */
  protected registerDataAccessObjects(...args) {
    this._reduxDataAccessObjects = [...args];
    // Once PT2 of 6692 is done safaribasepage won't have cached dropdown/search object
    // anymore (since there could be dropdowns from different data access objects)
    // and the next two lines will be removed
    // But for now we'll just use the first one - it should be the same as it is today
    if (this._reduxDataAccessObjects[0]) {
      this._searchReduxObject = this._reduxDataAccessObjects[0].Search;
    }

    ////////////////////////
  }
  @HostListener('window:beforeunload')
  canDeactivate(): Observable<boolean> | boolean {
    // We can't just check for router dirtyCheck since we can have some raw html navigate
    // events. So let's check for global window['safari_dirtyCheck'] object first
    if (window['safari_dirtyCheck'] === false) {
      return true;
    }
    let checkDirty = false;

    // This might look confusing but it's ok. Inherently observables are synchroneouos unless written not to be. This simple selector
    // will just check the state variable so the callback to subscribe will populate checkDirty before it gets tested in the next line
    this._store
      .select(this._safariRouterReduxObject.default.selectors.checkDirty())
      .pipe(take(1))
      .subscribe(o => (checkDirty = o));
    if (checkDirty && this.hasUnsavedChanges()) {
      return false;
    }
  }

  setPageTitle(title: string) {
    this._store.dispatch(this._appUiReduxObject.default.actions.setPageTitle({ payload: title }));
  }
  setBackgroundImageClass(bgClass: string) {
    this._store.dispatch(this._appUiReduxObject.default.actions.setBackgroundImageClass({ payload: bgClass }));
  }
  setCompanyLogoUrl(companyLogoUrl: string) {
    this._store.dispatch(this._appUiReduxObject.default.actions.setCompanyLogoUrl({ payload: companyLogoUrl }));
  }
  setCompanyLabel(companyLabel: string) {
    this._store.dispatch(this._appUiReduxObject.default.actions.setCompanyLabel({ payload: companyLabel }));
  }

  protected pageAccessedByReload(): boolean {
    if (sessionStorage.getItem('reload_pathname') == window.location.pathname) {
      sessionStorage.removeItem('reload_pathname');
      return true;
    }
    sessionStorage.removeItem('reload_pathname');
    return false;
  }
  getGenericSelector<T>(selector: MemoizedSelector<object, T, DefaultProjectorFn<T>>, filterNull = false): Observable<T> {
    return this._reduxWrapper.getGenericSelector(selector, filterNull);
  }
  /**
   * Use for dispatching UI actions.
   * Right now the function can't recognize whether the action is UI or Data so we rely
   * on devs making the right call. Eventually we can try to figure out if we can
   * change redux-generator to create some unique action types so that the function can query it
   *
   * @param action
   * @param async
   */
  dispatchAction<P extends object>(action: P & Action<any>, async = false) {
    this._reduxWrapper.dispatchGenericAction(action, async);
  }

  /**
   * @deprecated
   * @param action
   */
  listenForDataAction<T extends string, P extends object>(action: ActionCreator<T, (props: P & NotAllowedCheck<P>) => P & Action<T>>): Observable<P & Action<T>>;
  listenForDataAction<T extends string>(action: ActionCreator<T, () => Action<T>>): Observable<Action<T>>;
  listenForDataAction(action: Action) {
    return this._reduxWrapper.listenForAction(action as any);
  }
  // this._reduxWrapper.listenForAction(
  ngDoCheck(): void {
    this.isSaveButtonDisabled = !this.hasUnsavedChanges();
  }
  onNavigationEnd(evt: RouterEvent) {}

  /**
   * On every Init of the base page component the framework calls clearState
   * for all registered objects. Override this function to skip clearing state
   * of a particular object
   *
   * @param name Unique name of the object
   * @returns
   */
  protected canClearState(obj: SafariReduxApiObject<ISafariObjectState<any>, any>) {
    return true;
  }
  private _clearAllStates() {
    for (const reduxDataAccessObject of this._reduxDataAccessObjects) {
      this._clearDataAccessObjectStates(reduxDataAccessObject);
    }
  }

  /**
   * This function can be called either by the base page directly on itself OR, more commonly,
   * via the subscription to page UI action requestFileDownload which any child component can send.
   * @param linkInfo Info regarding what we want to preview and how
   * @returns
   */

  saveFile(linkInfo: FileDownloadRequest, callback: any = null) {
    // NULL in file property represents a clipboard item
    if (linkInfo.objectToDownload == null) {
      throw new Error('Downloading clipboard items is not supported');
    }
    const fileName = linkInfo.fileName;
    if (linkInfo.objectToDownload instanceof File) {
      saveAs(linkInfo.objectToDownload, fileName || linkInfo.objectToDownload.name, { target: this.appConfiguration.uiSettings.file.downloadTarget });
    } else {
      this._disableOrEnableControlsById(linkInfo.callerId, true);

      const attachmentType = this.findObjectByString(linkInfo.objectToDownload.attachmentType);
      this.createOrUpdateObjectOnce$<AttachmentLink>(
        attachmentType,
        {
          id: SafariObject.id(linkInfo.objectToDownload.id, uuidv4()),

          openInBrowser: false,
          ...linkInfo.objectToDownload.apiProps
        },
        null,
        false
      ).subscribe(result => {
        this._disableOrEnableControlsById(linkInfo.callerId, false);
        if (result && !result.error) {
          saveAs(result.linkUrl, fileName || result.name, { target: this.appConfiguration.uiSettings.file.downloadTarget });
          if (callback && typeof callback === 'function') {
            callback();
          }
        }
      });
    }
  }
  private _disableOrEnableControlsById(controlIds: SafariObjectId | SafariObjectId[], disable: boolean) {
    if (controlIds == null) {
      return;
    }
    const array = Array.isArray(controlIds) ? controlIds : [controlIds];
    for (const id of array) {
      if (!String.isNullOrEmpty(id)) {
        try {
          const element = document.getElementById(id.toString());
          if (element) {
            if (!disable) {
              element.removeAttribute('disabled');
            } else {
              element.setAttribute('disabled', 'disabled');
            }
          }
        } catch {
          // Do nothing, we just don't want a simple disable/enable to break the whole page
        }
      }
    }
  }

  /**
   * This function can be called either by the base page directly on itself OR, more commonly,
   * via the subscription to page UI action requestFilePreview which any child component can send.
   * @param linkInfo Info regarding what we want to preview and how
   * @returns
   */
  previewFile(linkInfo: FilePreviewRequest) {
    // NULL in file property represents a clipboard item
    if (linkInfo.objectToPreview == null) {
      if (linkInfo.viewerId == BROWSERVIEWER) {
        throw new Error('Previewing clipboard in a new browser window is not supported');
      }
      this._reduxWrapper.dispatchGenericAction(this._filePreviewUiReduxObject.requestFilePreview.action(linkInfo));

      return;
    }
    // direct URL or file instance.
    if (linkInfo.objectToPreview instanceof File || typeof linkInfo.objectToPreview == 'string') {
      if (linkInfo.viewerId == BROWSERVIEWER) {
        if (linkInfo.objectToPreview instanceof File) {
          const fileURL = URL.createObjectURL(linkInfo.objectToPreview);
          window.open(fileURL, '_blank');
          URL.revokeObjectURL(fileURL);
        } else {
          window.open(linkInfo.objectToPreview, '_blank');
        }
      } else {
        this._reduxWrapper.dispatchGenericAction(this._filePreviewUiReduxObject.requestFilePreview.action(linkInfo));
      }
    } else {
      // This is the most standard - templink.
      // We first need to call the API to generate a URL for us, then we dispatch to fileviewer (or browser) as if it were
      // just a direct URL preview request
      const linkOriginInfo = {
        id: linkInfo.objectToPreview.id,
        attachmentType: linkInfo.objectToPreview.attachmentType.toString()
      };
      this._disableOrEnableControlsById(linkInfo.callerId, true);
      const objectToPreview = this.findObjectByString(linkInfo.objectToPreview.attachmentType);

      this.createOrUpdateObjectOnce$<AttachmentLink>(
        objectToPreview,
        {
          id: SafariObject.id(linkInfo.objectToPreview.id, uuidv4()),
          openInBrowser: true
        },
        null,
        false
      ).subscribe(result => {
        this._disableOrEnableControlsById(linkInfo.callerId, false);
        if (result && !result.error) {
          if (linkInfo.viewerId == BROWSERVIEWER) {
            window.open(result.linkUrl, '_blank');
          } else {
            this._reduxWrapper.dispatchGenericAction(
              this._filePreviewUiReduxObject.requestFilePreview.action({
                ...linkInfo,
                linkOriginInfo,
                objectToPreview: result.linkUrl,
                fileType: result.contentType,
                fileName: linkInfo.fileName || result.name
              })
            );
          }
        }
      });
    }
  }

  protected setupRequestFilePreviewListener() {
    if (this.messages?.requestFilePreview) {
      super.subscribe(this.observeMessage$(this.messages.requestFilePreview, true), x => {
        this.previewFile(x);
      });
    }
    if (this.messages?.requestFilePreviewClose) {
      super.subscribe(this.observeMessage$(this.messages.requestFilePreviewClose, true), x => {
        this._reduxWrapper.dispatchGenericAction(this._filePreviewUiReduxObject.requestFilePreview.action(null));
      });
    }
  }
  protected setupRequestFileDownloadListener() {
    if (this.messages?.requestFileDownload) {
      super.subscribe(this.observeMessage$(this.messages.requestFileDownload, true), x => {
        this.saveFile(x);
      });
    }
  }
  /**
   * Default requestSave listener. Save can be requested by lower level components
   * and this just calls default save function.
   * If you want to do something different override this and use this as a starting template
   */
  protected setupRequestSaveListener() {
    if (this.messages?.requestSave) {
      super.subscribe(this.observeMessage$(this.messages.requestSave, true), () => {
        this.save();
      });
    }
  }
  /**
   * Default requestNotify listener. Toastr notiication can be requested by lower level components
   * and this just calls default show toastr functions.
   * If you want to do something different override this and use this as a starting template
   */
  protected setupRequestNotifyListener() {
    if (this.messages?.requestNotification) {
      super.subscribe(this.observeMessage$(this.messages.requestNotification, true), o => {
        if (o.notificationType == NotifcationRequestType.Info) {
          this.toastrInfo(o.message, o.title, { ...this.toastrConfig, ...o.configOverride });
        } else if (o.notificationType == NotifcationRequestType.Error) {
          this.toastrError(o.message, o.title, { ...this.toastrConfig, ...o.configOverride });
        } else if (o.notificationType == NotifcationRequestType.Success) {
          this.toastrSuccess(o.message, o.title, { ...this.toastrConfig, ...o.configOverride });
        }
      });
    }
  }
  ngOnInit() {
    super.ngOnInit();
    this.setupFileListener();
    this.setupRequestNotifyListener();
    this.setupRequestSaveListener();
    this.setupRequestFileDownloadListener();
    this.setupRequestFilePreviewListener();

    this.fileDialogDismissed$ = this.dispatcher.pipe(ofType(clearAllFileStores));

    this._clearAllStates();

    if (this.fileTransferDialogOptions) {
      this._fileTransferDialogComponentRef = this._viewContainerRef.createComponent(FileTransferDialogComponent);
      // I m not even sure why we have ID on this component, but keeping it here for now
      this._fileTransferDialogComponentRef.instance.id = this.fileTransferDialogOptions.id;
    }
    if (this._failedObjectsService.hasFailedObjects) {
      const messageAndTitle = this.getFailedObjectsMessage();
      // only surface the dialog if there is a sensible message associated with it
      if (messageAndTitle) {
        super.showDialog1ButtonOnce$(messageAndTitle.title, messageAndTitle.message).subscribe();
      }
    }
    this._listenForHotkeys();
  }
  throwAbortSave() {
    return throwError(() => new AbortSave());
  }
  handleSaveError(err) {
    if (!(err instanceof AbortSave)) {
      return throwError(() => err);
    }

    return of(null);
  }
  handleSaveErrorExt(err) {
    if (!(err instanceof AbortSave)) {
      return throwError(() => err);
    }

    return of(err.returnValue);
  }
  ngAfterViewInit() {
    super.ngAfterViewInit();
    const url = new URL(window.location.href);
    const chunkCount = parseInt(url.searchParams.get(CHUNK_RETRY_COUNT_PARAM), 10);
    if (!isNaN(chunkCount)) {
      this._logger.LogChunkErrorRecoveryToElmah('Page recovered from chunk load error: ' + window.location.href, chunkCount);
    }
  }

  fileBodyFromFileObservable(fileObservable$: Observable<HttpResponse<Blob> | HttpErrorResponse>) {
    return this._fileDownloadService.fileBodyFromFileObservable(fileObservable$);
  }

  /*
    createLoadFailSubscriptions - Listens for ANY failures from ANY of the objects that this container is supposed to load
  */

  /*
    onLoadObjectFail - Called when LoadObjectFail action fires.
                       Can be overriden in child classes to bypass or suppliment default behavior.
  */
  onLoadObjectFail(action: LoadObjectActionFailInfo & Action<string>) {
    // This is not easily pipeable in rxjs flow, so use this only if you're tracking general
    // object failures, and want to do some special logging, events, etc.
    //
    // For actual error handling related to a specific
    // loadObject call you should just observe "failed state" of the object.
  }
  /*
    onCreateOrObjectFail - Called when CreateOrUpdateFail action fires.
                       Can be overriden in child classes to bypass or supplement default behavior.
                       NOTE: This is meant for informing about any general update/create action failure
                       but most likely your failure implementation will be in code that calls createOrUpdateObject
                       in your save method. This would be mostly useful if you want to add some general stuff like
                       metrics, etc
  */
  onCreateOrUpdateObjectFail(action: LoadObjectActionFailInfo & Action<string>) {}
  /*
    onUpdateObjectListFail - Called when CreateOrUpdateFail action fires.
                       Can be overriden in child classes to bypass or supplement default behavior.
                       NOTE: This is meant for informing about any general update/create action failure
                       but most likely your failure implementation will be in code that calls createOrUpdateObject
                       in your save method. This would be mostly useful if you want to add some general stuff like
                       metrics, etc
  */
  onUpdateObjectListFail(action: UpdateObjectListActionFailInfo<any> & Action<string>) {}

  /*
    onLoadObjectsFail - Called when LoadObjectsFail action fires.
                        Can be overriden in child classes to bypass or suppliment default behavior.
  */
  onLoadObjectListFail(action: ActionErrorBase & Action<string>) {}

  observeFileDialogDismissed$(id): Observable<ClearFileInfoPayload> {
    return this.fileDialogDismissed$.pipe(
      filter(result => id == null || result.fileTransferDialogId == id),
      take(1)
    );
  }

  protected clearState(includePersistent = true, includeErrors = true) {
    if (this.messages?.clearState) {
      this.sendMessage(this.messages.clearState, {
        includePersistent,
        includeErrors
      });
    }
  }
  onPageLeave(nextState: RouterStateSnapshot): void {
    super.onPageLeave(nextState);
    this._failedObjectsService.removeAllFailedObjects();
    this.clearState();
  }
  private _findObjectByStringInReduxDataAccessObjects(objectToFind: string, reduxDataAccessObject: ReduxDataAccessObject) {
    for (const key of Object.keys(reduxDataAccessObject)) {
      if (reduxDataAccessObject[key] instanceof ReduxDataAccessObject) {
        const val = this._findObjectByStringInReduxDataAccessObjects(objectToFind, reduxDataAccessObject[key]);
        if (val) {
          return val;
        }
      } else {
        if (reduxDataAccessObject[key].toString() === objectToFind) {
          return reduxDataAccessObject[key];
        }
      }
    }
    return null;
  }
  protected findObjectByString(objectToFind: string) {
    let result = null;

    for (const reduxDataAccessObject of this._reduxDataAccessObjects) {
      result = this._findObjectByStringInReduxDataAccessObjects(objectToFind, reduxDataAccessObject);
      if (result != null) {
        return result;
      }
    }

    return null;
  }
  private _clearDataAccessObjectStates(reduxDataAccessObject: ReduxDataAccessObject) {
    for (const key of Object.keys(reduxDataAccessObject)) {
      if (reduxDataAccessObject[key] instanceof ReduxDataAccessObject) {
        this._clearDataAccessObjectStates(reduxDataAccessObject[key]);
      } else {
        if (!this.canClearState(reduxDataAccessObject[key])) {
          continue;
        }

        // For some reason dropdown doesn't have clearState but instead it has clearDropdownState
        // that is called in a separate function. We should probably unify that
        if (typeof reduxDataAccessObject[key].default.actions.clearState === 'function') {
          this._store.dispatch(reduxDataAccessObject[key].default.actions.clearState());
        }

        if (reduxDataAccessObject[key].default.actions.clearObjectError) {
          this._store.dispatch(reduxDataAccessObject[key].default.actions.clearObjectError({ id: null }));
        }
      }
    }
  }
  ngOnDestroy() {
    super.ngOnDestroy();

    this.abortActions();

    this._clearAllStates();

    if (this._formSubmitSubscriptionSuccess) {
      this._formSubmitSubscriptionSuccess.unsubscribe();
    }
    if (this._formSubmitSubscriptionFail) {
      this._formSubmitSubscriptionFail.unsubscribe();
    }
    if (this._formSubmitSubscriptionSubmit) {
      this._formSubmitSubscriptionSubmit.unsubscribe();
    }

    for (const key of Array.from(this._listFilterSubscriptions.keys())) {
      const sub = this._listFilterSubscriptions.get(key);
      if (sub != null) {
        sub.unsubscribe();
      }
    }
    if (this.messages && this.messages['clearState']) {
      this.sendMessage(this.messages['clearState'], {
        includePersistent: false,
        includeErrors: true
      });
    }
  }

  /**
   * This function will "pre-queue" all objects that are about to be transferred. What it really does is just dispatches the very first
   * transfer dialog message for each one of these objects that they are about to be transferred. Then it returns those same objects that were
   * send to it, together with a unique actionId that was generated for each one of them. This actionId is used to track the progress of the transfer
   * so make sure to pass that as an option when you call your createOrUpdate/delete etc
   * Why is this function needed ?
   * If you were not using this function and try to send, say 20 files, what would happen is that you'd see the dialog pop up and dissapear in batches of
   * 5 which would be a very ugly insperience.
   * And why would this happen ?
   * It's because we hard-limit our effects to 5 at a time. The way that the dialog works is that it wakes up when the messages come in, and gets out of the way
   * when the last message has been processed. Well, since the messages are coming in batches of 5, it could  process the first 5 at the same time, then close, and then immediately the effect
   * would release the next batch of messages and the dialog would pop up again. And it might do that up to 4 times possibly. Or it might stay open but you'd see additional files popping up in the dialog
   * as the effect releases more messages. It's just not a good experience.
   *
   * So by calling this function with all the files you are planning to transfer you are effectively telling the dialog to wake up once, and send all 20 initial messages.
   * Then just use this function to get the actionId for each object and use that actionId when you call createOrUpdate/delete etc
   *
   * This will ensure that the dialog will stay there all the time
   *
   * Note: If you're using standard uploadFile function you don't have to worry about it, it already does this for you
   * @param object the usual this.LpmsObject.XYZ
   * @param objectsToTransfer all objects we are planning to transfer , combined with the desired transfer dialog options and operation type (add/remove/etc)
   * @returns array of the original objects, trasnferdialooptions, etc, AND the actionId that was generated for each object
   */
  prepareForBulkTransfer<T extends SafariObject>(
    object: SafariReduxApiObject<ISafariObjectState<T>, T>,
    objectsToTransfer: (T & { transferDialogOptions?: TransferDialogOptions; operationType: FileOperationType })[]
  ): PrepareForBulkTransferResponse<T>[] {
    const result: PrepareForBulkTransferResponse<T>[] = objectsToTransfer.map(x => ({
      object: x,
      operationType: x.operationType,
      transferDialogOptions: x.transferDialogOptions,
      actionId: uuidv4()
    }));

    this.dispatchAction(
      object['_service']['safariReduxFileTransferObject'].default.actions.prepareForBulkTransfer({
        payload: {
          files: objectsToTransfer.map(
            (x, index): TransferDialogMessage => ({
              actionId: result[index].actionId,
              fileOperationType: result[index].operationType,
              // object requesting to be show in transfer dialog can be anything, but usually it's a file.
              // So, we'll check if it has a file property, and if so, set up the size property
              totalSize: (result[index].object as FileObject).file?.size,
              displayFilename: result[index].transferDialogOptions?.displayName
            })
          )
        }
      })
    );
    return result;
  }

  removeFileOnce$<T extends SafariObject>(
    object: SafariReduxApiObject<ISafariObjectState<T>, T>,
    filesToRemove: FileObjectForTransferDialog | FileObjectForTransferDialog[],
    options?: { waitForTransferDialog?: boolean }
  ): Observable<ClearFileInfoPayload> {
    const waitForTransferDialog = options?.waitForTransferDialog == null ? true : options.waitForTransferDialog;
    // This is a one-off take(1) observable, not a long running observable like loadObject$ etc.
    // However, unlike form submit this does not need setTimeout. The reason is that once effects are hooked to
    // actions dispatch method starts dispatching asyncroneously, meaning this will already have setTimeout internally
    if (filesToRemove == null || (Array.isArray(filesToRemove) && filesToRemove.length == 0)) {
      // If nothing passed in or array of files is empty immediately return generic "success" code
      return of({
        correlationId: null,
        fileTransferDialogId: null,
        hadErrors: false,
        id: null,
        wasCancelled: false
      }) as Observable<ClearFileInfoPayload>;
    }
    const files = Array.isArray(filesToRemove) ? filesToRemove : [filesToRemove];
    if (files.any(f => f.transferDialogOptions == null)) {
      throw new Error('When using removeFileOnce$ you must provide transferDialogOptions for each object');
    }

    // To see why we're creating an observable that dispatches an action look at createOrUpdateObjectOnce$ comments
    const func = new Observable<void>(observer => {
      const bulkTransferResult = this.prepareForBulkTransfer(object, files.map(x => ({ ...x, operationType: FileOperationType.Remove })) as any);

      for (const bulkTransferObject of bulkTransferResult) {
        this.deleteObject(object, bulkTransferObject.object.id, {
          actionId: bulkTransferObject.actionId,
          options: {
            transferDialogOptions: bulkTransferObject.transferDialogOptions
          }
        });
      }

      observer.next();
      observer.complete();
    }).pipe(mergeMap(() => (waitForTransferDialog ? this.observeFileDialogDismissed$(null) : of(null))));

    return func;
  }

  uploadFile(object: SafariReduxApiObject<ISafariObjectState<FileObject>, FileObject>, fileObject: FileObject, overrides?: UpdateOrCreateActionInfo<FileObject>) {
    const actionId = overrides?.actionId || uuidv4();
    this.createOrUpdateObject(object, fileObject, {
      actionId,
      options: {
        silenceErrors: {
          mode: ActionSilenceErrorMode.All
        },
        transferDialogOptions: {
          originalContent: overrides?.options?.transferDialogOptions?.originalContent,
          additionalInfo: overrides?.options?.transferDialogOptions?.additionalInfo,
          displayName: overrides?.options?.transferDialogOptions?.displayName || fileObject.file.name,
          secondsUntilTransferDialogShown: overrides?.options?.transferDialogOptions?.secondsUntilTransferDialogShown || 0
        },
        // We need to override the method because the framework will think that it should use PUT because of presence of etag that we sometimes have to add to file objects (which really is just a parent's etag)
        methodOverride: HttpMethodOverride.Post
      }
    });
  }
  /**
   * This function should be used for general file uploading. It is a wrapper around createOrUpdateObjectOnce$ for a file object, that
   * also sets up all thc common defaults for files such as sending "prepare bulk transfer" messages, setting up default transfer dialog options, if not present etc
   * NOTE: There is nothing preventing you from using createOrUpdateObjectOnce$ on the file object. That's what this function does anyway.
   * @param object - the usual this.LpmsObject.XYZ
   * @param files - single file object or array of file objects to upload
   * @param options - optional overrides for the actionId and httpMethodOverride. If actionId is not passed in a unique one will be generated. This is the most common use
   * @returns
   */
  uploadFileOnce$<T extends FileObject>(
    object: SafariReduxApiObject<ISafariObjectState<T>, T>,
    filesToUpload: FileObjectForTransferDialog<T> | FileObjectForTransferDialog<T>[],
    options?: { httpMethodOverride?: HttpMethodOverride; waitForTransferDialog?: boolean }
  ): Observable<ClearFileInfoPayload> {
    const httpMethodOverride = options?.httpMethodOverride || HttpMethodOverride.Post;
    const waitForTransferDialog = options?.waitForTransferDialog == null ? true : options.waitForTransferDialog;
    // This is a one-off take(1) observable, not a long running observable like loadObject$ etc.
    // However, unlike form submit this does not need setTimeout. The reason is that once effects are hooked to
    // actions dispatch method starts dispatching asyncroneously, meaning this will already have setTimeout internally
    if (filesToUpload == null || (Array.isArray(filesToUpload) && filesToUpload.length == 0)) {
      // If nothing passed in or array of files is empty immediately return generic "success" code
      return of({
        correlationId: null,
        fileTransferDialogId: null,
        hadErrors: false,
        id: null,
        wasCancelled: false
      }) as Observable<ClearFileInfoPayload>;
    }

    const files = Array.isArray(filesToUpload) ? filesToUpload : [filesToUpload];

    // To see why we're creating an observable that dispatches an action look at createOrUpdateObjectOnce$ comments
    const func = new Observable<void>(observer => {
      const bulkResult = this.prepareForBulkTransfer(
        object,
        files.map(x => ({
          ...x,
          operationType: FileOperationType.Add,
          transferDialogOptions: x.transferDialogOptions
            ? x.transferDialogOptions
            : {
                displayName: x.file.name
              }
        }))
      );

      for (const objectToTransferWithOptions of bulkResult) {
        // NOTE: Casting to any here because we are adding actionId property that is not officially part of the payload
        // More info about this is in crud.service.ts,  create() method
        // eslint-disable-next-line @typescript-eslint/naming-convention -- don't want to bother adding to lint exceptions
        this.createOrUpdateObject(object, objectToTransferWithOptions.object, {
          actionId: objectToTransferWithOptions.actionId,
          options: {
            silenceErrors: {
              mode: ActionSilenceErrorMode.All
            },

            transferDialogOptions: objectToTransferWithOptions.transferDialogOptions,
            // We need to override the method because the framework will think that it should use PUT because of presence of etag that we sometimes have to add to file objects (which really is just a parent's etag)
            methodOverride: httpMethodOverride
          }
        });
      }

      observer.next();
      observer.complete();
    }).pipe(
      take(1),
      mergeMap(() => (waitForTransferDialog ? this.observeFileDialogDismissed$(null) : of(null)))
    );

    return func;
  }
  moveLoadedToCurrent<T extends SafariObject>(object: IReduxProvider<ISafariObjectState<T>, T>, id: string) {
    if (id != null) {
      this._store.dispatch(object.default.actions.moveLoadedToCurrent({ payload: { id } }));
    }
  }
  moveLoadedListToCurrent<T extends SafariObject>(object: IReduxProvider<ISafariObjectState<T>, T>, id: string) {
    if (id != null) {
      this._store.dispatch(object.default.actions.moveLoadedListToCurrent({ payload: { id } }));
    }
  }
  private _dispatchCancelEdit() {
    this._reduxWrapper.dispatchGenericAction(this._formReduxObject.default.actions.cancelEdit());
  }
  /*
    cancel           - Usually called by child's Cancel button. It clears object errors and optionally navigates to the
                       specified route
  */
  cancel(commands: any[]) {
    this.dispatchAllowNavigation(true);
    this._dispatchCancelEdit();
    if (commands && commands.length > 0) {
      this.dispatchToggleBlockUi(true);
      void this.___router.navigate(commands);
    }
  }

  // Let's see how this goes - it should be a better replacement for cancel method above but starting slow
  // with just user edit page and matter (that one already had a custom implementation of goBack())
  /**
   *
   * @param checkDirty - whether going back should result in dirty page check. When we use this
   * in context of cancel we should keep it at true, when in context of save we should pass false
   */
  goBack(checkDirty = true, defaultUrl: string = null) {
    this.dispatchAllowNavigation(true);
    this.dispatchToggleBlockUi(true);

    this._dispatchCancelEdit();
    this.dispatchNavigate({ path: this.previousUrl || defaultUrl || '/', checkDirty });
  }
  refreshPage(checkDirty = true) {
    this.dispatchToggleBlockUi(true);
    this.dispatchNavigate({ path: this.___router.url, checkDirty });
  }
  getFormStateObservable$<T>(formId: string) {
    return this.getGenericSelector(this._formReduxObject.default.selectors.formState(formId), true) as Observable<FormSubmitInfo<T>>;
  }
  getFormStateOnce$<T>(formId: string) {
    return this.getFormStateObservable$<T>(formId).pipe(take(1));
  }
  getFormState<T>(formId: string) {
    // This is just query into the store without any async calls so it should be ok to assign to formState,
    // then return
    let formState = null;
    this.getFormStateObservable$(formId)
      .pipe(take(1))
      .subscribe(o => {
        formState = o;
      });
    return formState as FormSubmitInfo<T>;
  }

  // autoClearState is a temporary flag, mainly to hotfix 8034.
  // Report needs to have a compliant state definition (we probably need to set up standard state base for UI pages)
  // but that's not something we want to do in a hotfix
  protected save(autoClearState = true) {
    if (autoClearState && this.messages && this.messages['clearState']) {
      this.sendMessage(this.messages['clearState'], {
        includePersistent: false,
        includeErrors: true
      });
    }

    // Make sure to trigger change detection so that save button (which binds to "hasUnsavedChanges")
    // doesnt throw "expression checked after changed" error (which is not visible in prod, but we shouldn't let it happen anyway)
    this._cdr.detectChanges();
    this.dispatchToggleBlockUi(true);
  }

  clearToastr() {
    this.toastrService.clear(this.currentToastrId);
  }
  toastrInfo(message: string, title: string = '', config: IndividualConfig = null) {
    this.clearToastr();
    this.currentToastrId = this.toastrService.info(message, title, config ? config : this.toastrConfig).toastId;
  }
  toastrError(message: string, title: string = '', config: IndividualConfig = null) {
    this.clearToastr();
    this.currentToastrId = this.toastrService.error(message, title, config ? config : this.toastrConfigTimeout).toastId;
  }
  toastrSuccess(message: string, title: string = '', config: IndividualConfig = null) {
    this.clearToastr();
    this.currentToastrId = this.toastrService.success(message, title, config ? config : this.toastrConfigTimeout).toastId;
  }

  getFailedObjectsMessage() {
    // None of the pages except matter edit use failedObjectService. So default the message to null.
    // If a page uses this concept it should provide a proper message.
    return null;
  }

  get squirrelAppName() {
    const squirrelAppName = 'lpms-web';
    // A bit of a hack, but we originally released squirrel key with "appName": "lpms-web",
    // so if we apply this logic the customer will lose their existing settings. So if we are in
    // prod we'll just keep lpms-web
    if (this.appConfiguration.name.toLowerCase() == 'production') {
      return squirrelAppName;
    }
    return squirrelAppName + '-' + this.appConfiguration.name + (this.appConfiguration.machineName ? '-' + this.appConfiguration.machineName : '');
  }
  private _listenForHotkeys() {
    this.subscribe(this.hotKeyApi.getState$().pipe(skip(1)), hotkey => {
      if (hotkey.value === SafariHotkey.Save) {
        if (this.hasUnsavedChanges()) {
          this.save();
        }
      } else if (hotkey.value === SafariHotkey.Close) {
        this.goBack();
      }
    });
  }
}
