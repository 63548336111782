
<div class="input-group">
  <div class="input-group-prepend" >
    <label class="pt-2 mr-2">{{this.labelName}}</label>
  </div>
  <select [id]="'dropdown-filter-' + propName + '-' + listId" class="form-control" (change)="updateFilter($event.target['value'])" >
    @for (filter of filterValues; track filter.id) {
      <option value={{filter.id}} [selected]="selectedFilterValue == filter.id">
        {{filter.name}}
      </option>
    }
  </select>
</div>