 
@if (readyToDisplay()) {
  

  <sl-ui-kit-safarilist [newMenu]="false" [hasFilter]="true"    [tableMenuMode]="TableMenuMode.Compact" >

    <ng-container class="custom-table-filter">
      <div class="form-group col-md-6 pt-1">
        <sl-ui-kit-dropdown-filter propName='status' [listId]="this.componentId"   [filterValues]="[
          { id: 1, name: 'All' },
          { id: 2, name: 'Error Only' },
          { id: 3, name: 'Success Only' } ]   ">
        </sl-ui-kit-dropdown-filter>
      </div>
    </ng-container>
     

    <sl-ui-kit-datatable>

      <ng-template #fileNameHeaderTemplate let-column="column">
        <div (click)="onSort('displayFilename')" class="s_top ngx_header" [ngClass]="getSortIcon('displayFilename')">
          Name
        </div>
        <div class="s_bot">
          &nbsp;
        </div>
      </ng-template>
      <ng-template #fileNameCellTemplate let-row="row" let-rowIndex="rowIndex">
        <div class="s_top">
          <strong [attr.data-test-id]="componentId + '-filename-row-' + rowIndex" [class.text-danger]="row.isError">
            {{ row.displayFilename }}
          </strong>
        </div>
        <div class="s_bot">
          <em [attr.data-test-id]="componentId + '-error-row-' + rowIndex" [class.text-danger]="row.isError">{{
            row.message
          }}</em>
        </div>
      </ng-template>
      
      <ng-template #progressHeaderTemplate let-column="column">
      
        <div (click)="onSort('percentComplete')" class="s_top ngx_header" [ngClass]="getSortIcon('percentComplete')">
          Progress
        </div>
        <div class="s_bot">
          &nbsp;
        </div>
      </ng-template>
      
      <ng-template #progressCellTemplate let-row="row">
        <div class="s_span">
          <div class="progress my-2 mr-2">
            @if (row.percentComplete >= 0) {
              <div class="progress-bar" [class.bg-danger]="row.isError"
                role="progressbar" [attr.aria-valuenow]="row.percentComplete" aria-valuemin="0" aria-valuemax="100"
                [style.width.%]="row.percentComplete">
                <span class="sr-only">
                {{ row.percentComplete }}</span>
              </div>
            }
            @if (row.percentComplete < 0) {
              <div class="progress-line"></div>
            }
          </div>
        </div>
      </ng-template>
      

    </sl-ui-kit-datatable>

  </sl-ui-kit-safarilist>

}




