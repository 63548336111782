/* eslint-disable no-restricted-syntax -- framework use */
/**
 * DO NOT EXPORT THIS FILE IN INDEX.TS
 * THERE IS NO REASON FOR WEBAPP OR ANYTHING ELSE TO KNOW ABOUT THIS
 */
import { inject, InjectionToken } from '@angular/core';

import {
  AttachmentLinkAdapter,
  CrudService,
  DropdownService,
  FileObjectAdapter,
  HelperObjectAdapterPOST,
  HelperObjectAdapterPUT,
  SafariObjectAdapter,
  ServiceConfiguration
} from '@safarilaw-webapp/shared/crud';
import { User } from '../co-user/models/app/user';
import { CompanySettings } from '../company-settings/models/app/company-settings';
import { Company } from '../company/models/app/company';
import { InboundEmailSetting } from '../inbound-email-setting/models/app/inbound-email-setting';
import { NotificationPreference } from '../notification-preferences/models/app/notification-preference';
import { RegisteredAgentSettings } from '../registered-agent-settings/models/app/registered-agent-settings';

import { HttpClient } from '@angular/common/http';
import { AttachmentLink, FileObject, SafariObject } from '@safarilaw-webapp/shared/common-objects-models';
import { AppConfigurationService } from '@safarilaw-webapp/shared/environment';
import { ApplicationInsightsService } from '@safarilaw-webapp/shared/logging';
import { UserAdapter } from '../co-user/adapters/co-user-adapter';
import { CompanySettingsAdapter } from '../company-settings/adapters/company-settings-adapter';
import { CompanyAdapter } from '../company/adapters/company-adapter';
import { DropdownApiAdapter } from '../dropdown/adapter/dropdown-adapter';
import { COMANAGE_TRANSFER_DIALOG_OBJECT } from '../feature-co-manage-data-access.module';
import { InboundEmailSettingAdapter } from '../inbound-email-setting/adapters/inbound-email-setting-adapter';
import { MyCompaniesAdapter } from '../my-companies/adapters/my-companies-adapter';
import { MyCompanies } from '../my-companies/models/app/my-companies';
import { NotificationPreferenceAdapter } from '../notification-preferences/adapters/notification-preference-adapter';
import { RegisteredAgentSettingsAdapter } from '../registered-agent-settings/adapters/registered-agent-settings-adapter';
import { Team } from '../team/models/app/team';
import { TeamAdapter } from '../team/services/team/team.adapter';

export const MY_INBOUND_EMAIL_SETTINGS_SERVICE_TOKEN = new InjectionToken<CrudService<InboundEmailSetting>>('MY_INBOUND_EMAIL_SETTINGS_SERVICE_TOKEN', {
  providedIn: 'root',
  factory: () =>
    new CrudService<InboundEmailSetting>(
      inject(InboundEmailSettingAdapter),
      inject(ApplicationInsightsService),
      new ServiceConfiguration(inject(AppConfigurationService).urls.apiRootCoManage, 'company/inboundEmailSettings')
    )
});
export const REGISTERED_AGENT_SETTINGS_SERVICE_TOKEN = new InjectionToken<CrudService<RegisteredAgentSettings>>('REGISTERED_AGENT_SETTINGS_SERVICE_TOKEN', {
  providedIn: 'root',
  factory: () =>
    new CrudService<RegisteredAgentSettings>(
      inject(RegisteredAgentSettingsAdapter),
      inject(ApplicationInsightsService),
      new ServiceConfiguration(inject(AppConfigurationService).urls.apiRootCoManage, 'company/registeredAgentSettings')
    )
});
export const NOTIFICATION_PREFERENCE_SERVICE_TOKEN = new InjectionToken<CrudService<NotificationPreference>>('NOTIFICATION_PREFERENCE_SERVICE_TOKEN', {
  providedIn: 'root',
  factory: () =>
    new CrudService<NotificationPreference>(
      inject(NotificationPreferenceAdapter),
      inject(ApplicationInsightsService),
      new ServiceConfiguration(inject(AppConfigurationService).urls.apiRootLpms, 'my/notificationPreferences')
    )
});
export const MY_COMPANY_SETTINGS_SERVICE_TOKEN = new InjectionToken<CrudService<CompanySettings>>('MY_COMPANY_SETTINGS_SERVICE_TOKEN', {
  providedIn: 'root',
  factory: () =>
    new CrudService<CompanySettings>(
      inject(CompanySettingsAdapter),
      inject(ApplicationInsightsService),
      new ServiceConfiguration(inject(AppConfigurationService).urls.apiRootCoManage, 'company/settings')
    )
});
export const DROPDOWN_SERVICE_TOKEN = new InjectionToken<DropdownService>('DROPDOWN_SERVICE_TOKEN', {
  providedIn: 'root',
  factory: () => new DropdownService(inject(HttpClient), inject(AppConfigurationService).urls.apiRootCoManage, inject(DropdownApiAdapter))
});
export const MY_COMPANY_SERVICE_TOKEN = new InjectionToken<CrudService<Company>>('MY_COMPANY_SERVICE_TOKEN', {
  providedIn: 'root',
  factory: () =>
    new CrudService<Company>(
      inject(CompanyAdapter),
      inject(ApplicationInsightsService),
      new ServiceConfiguration(
        inject(AppConfigurationService).urls.apiRootCoManage,
        null,
        [],
        // Company does not have an "endpoint" base like most of other services
        // Instead both get and put endpoints do not expect ID and they are completely
        // different.
        { get: 'my/company', put: 'company/profile' }
      )
    )
});
export const MY_USER_SERVICE_TOKEN = new InjectionToken<CrudService<User>>('MY_USER_SERVICE_TOKEN', {
  providedIn: 'root',
  factory: () =>
    new CrudService<User>(
      inject(UserAdapter),
      inject(ApplicationInsightsService),
      new ServiceConfiguration(inject(AppConfigurationService).urls.apiRootCoManage, 'my/user', ['authUserId', 'lastLoginDate'])
    )
});
export const MY_COMPANIES_SERVICE_TOKEN = new InjectionToken<CrudService<MyCompanies>>('MY_COMPANIES_SERVICE_TOKEN', {
  providedIn: 'root',
  factory: () =>
    new CrudService<MyCompanies>(inject(MyCompaniesAdapter), inject(ApplicationInsightsService), new ServiceConfiguration(inject(AppConfigurationService).urls.apiRootCoManage, 'my/companies'))
});
export const COMPANY_LOGO_SERVICE_TOKEN = new InjectionToken<CrudService<CompanySettings>>('COMPANY_LOGO_SERVICE_TOKEN', {
  providedIn: 'root',
  factory: () =>
    new CrudService<CompanySettings>(inject(CompanySettingsAdapter), inject(ApplicationInsightsService), new ServiceConfiguration(inject(AppConfigurationService).urls.apiRootCoManage, 'logo'))
});
export const TEAM_SERVICE_TOKEN = new InjectionToken<CrudService<Team>>('TEAM_SERVICE_TOKEN', {
  providedIn: 'root',
  factory: () => new CrudService<Team>(inject(TeamAdapter), inject(ApplicationInsightsService), new ServiceConfiguration(inject(AppConfigurationService).urls.apiRootCoManage, 'teams'))
});
export const CO_USER_SIGNATURE_SERVICE_TOKEN = new InjectionToken<CrudService<FileObject>>('CO_USER_SIGNATURE_SERVICE_TOKEN', {
  providedIn: 'root',
  factory: () =>
    new CrudService<FileObject>(
      inject(FileObjectAdapter),
      inject(ApplicationInsightsService),
      new ServiceConfiguration(inject(AppConfigurationService).urls.apiRootCoManage, 'users/{0}/signature'),
      inject(COMANAGE_TRANSFER_DIALOG_OBJECT)
    )
});
export const USER_WELCOME_EMAIL_SERVICE_TOKEN = new InjectionToken<CrudService<SafariObject>>('USER_WELCOME_EMAIL_SERVICE_TOKEN', {
  providedIn: 'root',
  factory: () =>
    new CrudService<SafariObject>(
      inject(HelperObjectAdapterPUT),
      inject(ApplicationInsightsService),
      new ServiceConfiguration(inject(AppConfigurationService).urls.apiRootCoManage, 'users/{0}/sendWelcomeEmail')
    )
});
export const USER_SERVICE_TOKEN = new InjectionToken<CrudService<User>>('USER_SERVICE_TOKEN', {
  providedIn: 'root',
  factory: () =>
    new CrudService<User>(
      inject(UserAdapter),
      inject(ApplicationInsightsService),
      new ServiceConfiguration(inject(AppConfigurationService).urls.apiRootCoManage, 'users', ['authUserId', 'lastLoginDate'])
    )
});
export const GENERATE_REPORTS_ACCOUNTS_TEMPLINK_SERVICE_TOKEN = new InjectionToken<CrudService<AttachmentLink>>('GENERATE_REPORTS_ACCOUNTS_TEMPLINK_SERVICE_TOKEN', {
  providedIn: 'root',
  factory: () =>
    new CrudService<AttachmentLink>(
      inject(AttachmentLinkAdapter),
      inject(ApplicationInsightsService),
      new ServiceConfiguration(inject(AppConfigurationService).urls.apiRootCoManage, 'reports/accounts/link')
    )
});
export const GENERATE_REPORTS_LOGINS_TEMPLINK_SERVICE_TOKEN = new InjectionToken<CrudService<AttachmentLink>>('GENERATE_REPORTS_LOGINS_TEMPLINK_SERVICE_TOKEN', {
  providedIn: 'root',
  factory: () =>
    new CrudService<AttachmentLink>(
      inject(AttachmentLinkAdapter),
      inject(ApplicationInsightsService),
      new ServiceConfiguration(inject(AppConfigurationService).urls.apiRootCoManage, 'reports/logins/link')
    )
});
export const MY_SIGNATURE_FILE_OBJECT_SERVICE_TOKEN = new InjectionToken<CrudService<FileObject>>('MY_SIGNATURE_FILE_OBJECT_SERVICE_TOKEN', {
  providedIn: 'root',
  factory: () =>
    new CrudService<FileObject>(
      inject(FileObjectAdapter),
      inject(ApplicationInsightsService),
      new ServiceConfiguration(inject(AppConfigurationService).urls.apiRootCoManage, 'my/user/signature'),
      inject(COMANAGE_TRANSFER_DIALOG_OBJECT)
    )
});
export const COMPANY_LOGO_FILE_OBJECT_SERVICE_TOKEN = new InjectionToken<CrudService<FileObject>>('COMPANY_LOGO_FILE_OBJECT_SERVICE_TOKEN', {
  providedIn: 'root',
  factory: () =>
    new CrudService<FileObject>(
      inject(FileObjectAdapter),
      inject(ApplicationInsightsService),
      new ServiceConfiguration(inject(AppConfigurationService).urls.apiRootCoManage, 'company/logo'),
      inject(COMANAGE_TRANSFER_DIALOG_OBJECT)
    )
});
export const SSO_USER_RETRIEVE_HELPER_SERVICE_TOKEN = new InjectionToken<CrudService<SafariObject>>('SSO_USER_RETRIEVE_HELPER_SERVICE_TOKEN', {
  providedIn: 'root',
  factory: () =>
    new CrudService<SafariObject>(inject(SafariObjectAdapter), inject(ApplicationInsightsService), new ServiceConfiguration(inject(AppConfigurationService).urls.apiRootCoManage, 'users/{0}'))
});
export const USER_AUTH_SETUP_HELPER_SERVICE_TOKEN = new InjectionToken<CrudService<SafariObject>>('USER_AUTH_SETUP_HELPER_SERVICE_TOKEN', {
  providedIn: 'root',
  factory: () =>
    new CrudService<SafariObject>(
      inject(HelperObjectAdapterPOST),
      inject(ApplicationInsightsService),
      new ServiceConfiguration(inject(AppConfigurationService).urls.apiRootCoManage, 'users/{0}/setupAuth')
    )
});
/*** BEWARE OF DOG - DO NOT DELETE OR PUT ANYTHING ELSE BELOW THIS ***/
