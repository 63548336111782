import { Injectable } from '@angular/core';
import { Actions, ofType } from '@ngrx/effects';
import { select, Store } from '@ngrx/store';
import { AppUiReduxObject } from '@safarilaw-webapp/shared/app-bootstrap/data-access';
import { AppDialogUiReduxObject, ConfirmationDialogButton, ConfirmationDialogButtonProps, ConfirmationDialogRequest } from '@safarilaw-webapp/shared/dialog/store-access';
import { reduxActionFail } from '@safarilaw-webapp/shared/redux';
import { RouterNavigationInfo, SafariRouterReduxObject } from '@safarilaw-webapp/shared/routing-utility/store-access';
import { Observable } from 'rxjs';
import { v4 as uuidv4 } from 'uuid';

export interface IUiManager {
  dispatchOpenDialog1Btn(id: string, title: string, content: string, okBtnName: string | ConfirmationDialogButtonProps, initState: any): string;
  dispatchOpenDialog2Btn(id: string, title: string, content: string, okBtnName: string | ConfirmationDialogButtonProps, cancelBtnName: string | ConfirmationDialogButtonProps, initState: any): string;
  dispatchOpenDialog3Btn(
    id: string,
    title: string,
    content: string,
    okBtnName: string | ConfirmationDialogButtonProps,
    cancelBtnName: string | ConfirmationDialogButtonProps,
    auxBtnName: string | ConfirmationDialogButtonProps,
    initState: any
  ): string;

  dispatchGenericError(error: any, source: string, payload: any, silent: boolean, mustResolve: boolean): void;
  dispatchNavigate(navInfo: RouterNavigationInfo): void;
  dispatchToggleBlockUi(block: boolean, transparent: boolean): void;

  dispatchAllowNavigation(allow: boolean): void;
  getGenericReduxAction(action: any): Observable<any>;
}
@Injectable({
  providedIn: 'root'
})
export class UiManagerService implements IUiManager {
  constructor(
    private ___store: Store<any>,
    private ___actions: Actions,
    private _routerReduxObject: SafariRouterReduxObject,
    private _appUiReduxObject: AppUiReduxObject,
    private _appDialogUiReduxObject: AppDialogUiReduxObject
  ) {}

  dispatchOpenDialogNoButtons(id: string, title: string, content: string, initState = {}): string {
    return this.dispatchOpenDialog(id, title, content, null, null, null, initState);
  }
  dispatchOpenDialog1Btn(id: string, title: string, content: string, okBtnName: string | ConfirmationDialogButtonProps = { class: null, name: 'OK' }, initState = {}): string {
    return this.dispatchOpenDialog(id, title, content, okBtnName, null, null, initState);
  }
  dispatchOpenDialog2Btn(
    id: string,
    title: string,
    content: string,
    okBtnName: string | ConfirmationDialogButtonProps = { class: null, name: 'OK' },
    cancelBtnName: string | ConfirmationDialogButtonProps = { class: null, name: 'CANCEL' },
    initState = {}
  ): string {
    return this.dispatchOpenDialog(id, title, content, okBtnName, cancelBtnName, null, initState);
  }
  dispatchOpenDialog3Btn(
    id: string,
    title: string,
    content: string,
    okBtnName: string | ConfirmationDialogButtonProps = { class: null, name: 'OK' },
    cancelBtnName: string | ConfirmationDialogButtonProps = { class: null, name: 'CANCEL' },
    auxBtnName: string | ConfirmationDialogButtonProps,
    initState = {}
  ): string {
    return this.dispatchOpenDialog(id, title, content, okBtnName, cancelBtnName, auxBtnName, initState);
  }

  private dispatchOpenDialog(
    id: string,
    title: string,
    content: string,
    okBtnProps: string | ConfirmationDialogButtonProps,
    cancelBtnProps: string | ConfirmationDialogButtonProps,
    aux1BtnProps: string | ConfirmationDialogButtonProps,
    initState: any
  ): string {
    // If no ID is passed in we'll autogenerate and return that to the caller
    if (id == null) {
      id = uuidv4();
    }
    let buttons = null;
    if (okBtnProps != null) {
      buttons = ConfirmationDialogButton.Ok;
    }
    if (cancelBtnProps != null) {
      // eslint-disable-next-line no-bitwise -- intended
      buttons |= ConfirmationDialogButton.Cancel;
    }
    if (aux1BtnProps != null) {
      // eslint-disable-next-line no-bitwise -- intended
      buttons |= ConfirmationDialogButton.Aux1;
    }
    const request = {
      id,
      title,
      content,
      buttons,
      okBtnName: okBtnProps ? (typeof okBtnProps === 'string' ? okBtnProps : okBtnProps.name) : null,
      okBtnClass: okBtnProps && typeof okBtnProps !== 'string' ? okBtnProps.class : null,
      okBtnSubtext: okBtnProps && typeof okBtnProps !== 'string' ? okBtnProps.subtext : null,
      cancelBtnName: cancelBtnProps ? (typeof cancelBtnProps === 'string' ? cancelBtnProps : cancelBtnProps.name) : null,
      cancelBtnClass: cancelBtnProps && typeof cancelBtnProps !== 'string' ? cancelBtnProps.class : null,
      cancelBtnSubtext: cancelBtnProps && typeof cancelBtnProps !== 'string' ? cancelBtnProps.subtext : null,
      aux1BtnName: aux1BtnProps ? (typeof aux1BtnProps === 'string' ? aux1BtnProps : aux1BtnProps.name) : null,
      aux1BtnClass: aux1BtnProps && typeof aux1BtnProps !== 'string' ? aux1BtnProps.class : null,
      aux1BtnSubtext: aux1BtnProps && typeof aux1BtnProps !== 'string' ? aux1BtnProps.subtext : null,
      component: null,
      initState
    } as ConfirmationDialogRequest;
    this.___store.dispatch(this._appDialogUiReduxObject.default.actions.openModalDialog({ payload: request }));
    return id;
  }
  dispatchGenericError(error: any, source: string, payload: any = null, silent: boolean = false, mustResolve: boolean = false): void {
    this.___store.dispatch(reduxActionFail({ originalPayload: payload, error, payload, reduxErrorOptions: { source, silent, mustResolve } }));
  }
  dispatchNavigate(navInfo: RouterNavigationInfo): void {
    this.___store.dispatch(this._routerReduxObject.default.actions.routerNavigate({ payload: navInfo }));
  }
  dispatchToggleBlockUi(value: boolean, transparent: boolean): void {
    this.___store.dispatch(this._appUiReduxObject.default.actions.toggleBlockUi({ payload: { value, transparent } }));
  }

  dispatchAllowNavigation(allow: boolean): void {
    this.___store.dispatch(this._appUiReduxObject.default.actions.allowNavigation({ payload: { value: allow } }));
  }

  dispatchGenericAction(action: any): void {
    this.___store.dispatch(action);
  }

  getGenericSelector(selector: any, props: any = null): Observable<any> {
    if (props != null) {
      return this.___store.pipe(select(selector, props));
    } else {
      return this.___store.pipe(select(selector));
    }
  }
  getGenericReduxAction(action: any): Observable<any> {
    return this.___actions.pipe(ofType(action));
  }
}
