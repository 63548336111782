// this seems to be a bug in lint parser. It thinks we are importing the whole library and throws 'Static imports of lazy-loaded libraries are forbidden' error.
// But we're not importing the whole library, just the definittion of the base PageNotFoundComponent, which is a standalone index file.
/* eslint-disable @nx/enforce-module-boundaries  -- comments above */
/* eslint-disable no-restricted-syntax -- global inject OK in main.ts */
import { HTTP_INTERCEPTORS, provideHttpClient, withInterceptorsFromDi } from '@angular/common/http';
import { ErrorHandler, importProvidersFrom, inject, InjectionToken, provideAppInitializer, provideEnvironmentInitializer } from '@angular/core';
import { bootstrapApplication, BrowserModule } from '@angular/platform-browser';
import { provideRouter, Router, RouteReuseStrategy, withDisabledInitialNavigation, withInMemoryScrolling, withPreloading, withRouterConfig } from '@angular/router';

import { ActionReducerMap, StoreModule } from '@ngrx/store';
import { RmsAuthService, RmsNavbarService, RmsUserVerificationService } from '@safarilaw-webapp/feature/rms/shared';
import { AppCustomPreloader, NavbarPermissionsService, NavbarService, SharedAppBootstrapStoreAccessModule } from '@safarilaw-webapp/shared/app-bootstrap/data-access';
import { AuthInterceptorService, AuthService, SharedAuthStoreAccessModule, UserVerificationService } from '@safarilaw-webapp/shared/auth/store-access';

import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

import { FeatureRmsDataAccessModule } from '@safarilaw-webapp/feature/rms/data-access';

import { ModalDialogService, SharedDialogStoreAccessModule } from '@safarilaw-webapp/shared/dialog/store-access';
import { AppConfigurationService } from '@safarilaw-webapp/shared/environment';
import { CHUNK_RETRY_COUNT_PARAM, ErrorDialogComponent, ErrorHandlerService, HttpErrorInterceptorService } from '@safarilaw-webapp/shared/error-handling';
import { LoggerService } from '@safarilaw-webapp/shared/logging';
import { SafariDefaultReuseStategy, SharedRoutingUtilityStoreAccessModule } from '@safarilaw-webapp/shared/routing-utility/store-access';
import { SharedUiKitStoreAccessModule } from '@safarilaw-webapp/shared/ui-kit/store-access';
import { BlockUIModule } from 'ng-block-ui';
import { Observable, tap } from 'rxjs';
import { AppComponent } from './app/app.component';
import { environmentImports } from './app/app.module.env.imports';
import { routes } from './app/app.routing';
import { RmsConfigurationService } from './app/configuration/rms-configuration.service';
import { RmsNavbarPermissionsService } from './app/navbar/services/rms-navbar-permissions/rms-navbar-permissions.service';

import { EffectsModule } from '@ngrx/effects';
import { GATEWAY_TIMEOUT_URLS } from '@safarilaw-webapp/common-pages/gateway-timeout';
import { SUPPORT_EMAIL } from '@safarilaw-webapp/common-pages/page-error';
import { FeatureCoManageDataAccessModule } from '@safarilaw-webapp/feature/co-manage/data-access';
import { IRmsUiState, RmsUiReducerService } from '@safarilaw-webapp/feature/rms/ui/redux';
import { HotkeyModule } from 'angular2-hotkeys';
import { ModalModule } from 'ngx-bootstrap/modal';
import { ToastrModule } from 'ngx-toastr';

export const REDUCER_TOKEN = new InjectionToken<ActionReducerMap<IRmsUiState>>('Rms Ui Reducers');

const initialAppConfigFactory =
  (appConfigService: AppConfigurationService, loggerService: LoggerService): (() => Observable<boolean>) =>
  (): Observable<boolean> =>
    appConfigService.overrideConfiguration().pipe(tap(() => loggerService.initializeElmahLogging()));

bootstrapApplication(AppComponent, {
  providers: [
    provideRouter(
      routes,
      withRouterConfig({
        paramsInheritanceStrategy: 'always',
        onSameUrlNavigation: 'reload'
      }),
      withPreloading(AppCustomPreloader),
      withInMemoryScrolling({
        anchorScrolling: 'enabled',
        scrollPositionRestoration: 'top'
      }),
      withDisabledInitialNavigation()
    ),
    importProvidersFrom(
      /////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

      ////////////////////////////////////////////////////////////
      // Angular imports for browser functionality
      ////////////////////////////////////////////////////////////
      BrowserModule,
      BrowserAnimationsModule,
      ////////////////////////////////////////////////////////////
      // NGX Modal module (base of our dialogs)
      // It can potentially pop up during bootstrapping
      // So we'll add it here
      ////////////////////////////////////////////////////////////
      ModalModule.forRoot(),
      ////////////////////////////////////////////////////////////
      // NGRX required root modules for store and effects
      ////////////////////////////////////////////////////////////
      StoreModule.forRoot(
        {},
        {
          runtimeChecks: {
            strictStateImmutability: true,
            strictActionImmutability: true
          }
        }
      ),
      EffectsModule.forRoot([]),
      ////////////////////////////////////////////////////////////
      // Other 3RD party potentially needed during bootstrapping
      ////////////////////////////////////////////////////////////
      BlockUIModule.forRoot(),
      ToastrModule.forRoot(),
      HotkeyModule.forRoot(),

      /////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

      ////////////////////////////////////////////////////////////
      // Safari common modules needed for bootstrapping
      ////////////////////////////////////////////////////////////
      SharedDialogStoreAccessModule,
      SharedAuthStoreAccessModule,
      SharedRoutingUtilityStoreAccessModule,
      FeatureCoManageDataAccessModule,
      SharedAppBootstrapStoreAccessModule,
      SharedUiKitStoreAccessModule,
      ////////////////////////////////////////////////////////////
      // Safari project specific modules
      ////////////////////////////////////////////////////////////
      FeatureRmsDataAccessModule,
      StoreModule.forFeature('rms', REDUCER_TOKEN),
      [...environmentImports]
    ),
    provideHttpClient(withInterceptorsFromDi()),
    RmsUiReducerService,

    {
      provide: REDUCER_TOKEN,
      deps: [RmsUiReducerService],
      useFactory: (service: RmsUiReducerService) => service.reducers
    },
    {
      provide: GATEWAY_TIMEOUT_URLS,
      useValue: 'serveport.com / *.serveport.com'
    },
    {
      provide: SUPPORT_EMAIL,
      useValue: 'support@serveport.com'
    },

    { provide: UserVerificationService, useClass: RmsUserVerificationService },
    { provide: AuthService, useClass: RmsAuthService },
    { provide: NavbarService, useClass: RmsNavbarService },
    provideAppInitializer(() => {
      const initializerFn = initialAppConfigFactory(inject(AppConfigurationService), inject(LoggerService));
      return initializerFn();
    }),
    provideEnvironmentInitializer(() => {
      inject(ModalDialogService).registerComponent(ErrorDialogComponent.ClassId, ErrorDialogComponent);

      const router = inject(Router);

      const url = new URL(window.location.href);

      // Don't blindly navigate to init. In normal circumstances the module should never load with
      // "init" as its startup path, but it could if this happened during chunkload error. So if that's
      // the case make sure you pass the chunk count
      if (url.pathname.toLowerCase() == '/init') {
        const chunkCount = parseInt(url.searchParams.get(CHUNK_RETRY_COUNT_PARAM), 10);
        if (!isNaN(chunkCount)) {
          void router.navigate(['init'], {
            queryParams: {
              // eslint-disable-next-line @typescript-eslint/naming-convention -- used seldomly, so I m just skipping eslint here, instead of config
              __chcn: chunkCount
            }
          });
          return;
        }
      }
      // Otherwise this is a normal flow so go to init component but don't change the location
      void router.navigate(['/init'], { skipLocationChange: true });
    }),
    {
      provide: AppConfigurationService,
      useClass: RmsConfigurationService
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AuthInterceptorService,
      multi: true
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: HttpErrorInterceptorService,
      multi: true
    },
    { provide: ErrorHandler, useClass: ErrorHandlerService },
    {
      provide: NavbarPermissionsService,
      useClass: RmsNavbarPermissionsService
    },
    {
      provide: RouteReuseStrategy,
      useClass: SafariDefaultReuseStategy
    }
  ]
}).catch(err => {
  throw err;
});
