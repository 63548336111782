import { Component } from '@angular/core';
import { OnChangeFilterBase } from '../on-change-filter-base/on-change-filter-base';

@Component({
  imports: [],
  selector: 'sl-ui-kit-dropdown-filter',
  templateUrl: './dropdown-filter.component.html',
  styleUrls: ['./dropdown-filter.component.scss']
})
export class DropdownFilterComponent extends OnChangeFilterBase {}
