import { Injectable } from '@angular/core';
import { Actions, ofType } from '@ngrx/effects';
import { ReduxWrapperService, abortOnError } from '@safarilaw-webapp/shared/redux';
import { Observable, filter, forkJoin, mergeMap, of, race, take } from 'rxjs';
import { SafariUiFormReduxObject } from '../../../state/actions/layout-actions';
import { FormSubmitInfo } from '../../../state/interfaces/layout-interface';

@Injectable({
  providedIn: 'root'
})
export class FormControlService {
  constructor(
    private _reduxWrapper: ReduxWrapperService,
    private _actions: Actions,
    private _formReduxObject: SafariUiFormReduxObject
  ) {}
  // Once the rest of the forms follow pattern from company-entity-edit and settings-general-edit
  // we can change throwAbortOnError to default to TRUE as that will be the most common case
  submitOnce$<T>(params: { id: string; additionalInfo?: any }[], throwAbortOnError = false) {
    // This is a one-off take(1) observable, not a long running observable like loadObject$ etc.
    // Here we HAVE TO setTimeout on the dispatch in order to ensure that the action is caught. Otherwise
    // the action would be dispatched and completed before the observable even returns to the caller

    const paramIds: string[] = params.map(o => o.id.toString());

    // To see why we're creating an observable that dispatches an action look at createOrUpdateObjectOnce$ comments
    return new Observable<void>(observer => {
      for (const param of params) {
        setTimeout(() =>
          this._reduxWrapper.dispatchGenericAction(
            this._formReduxObject.default.actions.formRequestSubmit({
              id: param.id,
              additionalInfo: param.additionalInfo
            })
          )
        );
      }
      observer.next();
      observer.complete();
    }).pipe(mergeMap(() => this._observeFormSubmit$<T>(paramIds, throwAbortOnError)));
  }
  /**

   *
   * @param idPararms - One or many form IDS
   * @returns Mapped array of success/fail forms + hasErrors property for easy querying
   *
   * Note: If you have more than one form here you will need to pass a union type and then do your own casting
   * if you need to. Most of the time, however, your main page will only be interested in hasErrors property
   * and success[0].appModel so it can dispatch update
   */
  private _observeFormSubmit$<T>(ids: string[], throwAbortOnError = true): Observable<FormSubmitInfo<T>[]> {
    const forms: Observable<FormSubmitInfo<T>>[] = [];
    for (const id of ids) {
      const func = this._getFormSubmitObservable$<T>(id);
      forms.push(throwAbortOnError ? func.pipe(abortOnError()) : func);
    }
    return forkJoin(forms).pipe(take(1));
  }
  getState$<T1 = any, T2 = any>(id: string): Observable<FormSubmitInfo<T1, T2>> {
    return this._reduxWrapper.getGenericSelector(this._formReduxObject.default.selectors.formState(id));
  }
  requestPatch(id: string, formValues: any) {
    this._reduxWrapper.dispatchGenericAction(this._formReduxObject.default.actions.formRequestPatch({ id, formValues }));
  }
  private _getFormSubmitObservable$<T>(id: string): Observable<FormSubmitInfo<T>> {
    const ok = this._actions.pipe(
      ofType(this._formReduxObject.default.actions.formSubmitSuccess),
      filter(o => o.payload.id == id)
    );
    const fail = this._actions.pipe(
      ofType(this._formReduxObject.default.actions.formSubmitFail),
      filter(o => o.payload.id == id)
    );
    return race(ok, fail).pipe(
      mergeMap(o => {
        if (o.type === this._formReduxObject.default.actions.formSubmitFail.type) {
          return of({ ...o.payload, ...{ error: o['error'] } }) as Observable<FormSubmitInfo<T>>;
        } else {
          return of(o.payload) as Observable<FormSubmitInfo<T>>;
        }
      }),
      take(1)
    );
  }

  /**
   * A long-running observable that listens for success of a particular form.
   * It may be useful but if you do use it there's probably a good chance that the component
   * hierarchy/flow needs to be reworked. Most of the time using submitOnce$ is what you want
   * @param id Form Id
   * @returns FormSubmitInfo once it hears success
   */
  observeFormSuccess$(id: string) {
    return this._reduxWrapper.listenForAction(this._formReduxObject.default.actions.formSubmitSuccess).pipe(filter(o => o.payload.id === id));
  }
  /**
   * A long-running observable that listens for fail of a particular form.
   * It may be useful but if you do use it there's probably a good chance that the component
   * hierarchy/flow needs to be reworked. Most of the time using submitOnce$ is what you want
   * @param id Form Id
   * @returns FormSubmitInfo once it hears fail
   */
  observeFormFail$(id: string) {
    return this._reduxWrapper.listenForAction(this._formReduxObject.default.actions.formSubmitFail).pipe(filter(o => o.payload.id === id));
  }
}
