import { Store } from '@ngrx/store';
import { AuthService } from '@safarilaw-webapp/shared/auth/store-access';
import { AppConfigurationService, FeatureFlag, FeatureFlagsService } from '@safarilaw-webapp/shared/environment';
import { AppAccess } from '../../models/app-access';

export class NavbarPermissionsService {
  protected _appAccess: AppAccess;

  constructor(
    protected _store: Store<any>,
    protected _featureFlags: FeatureFlagsService,
    private _authService: AuthService,
    protected _appConfig: AppConfigurationService
  ) {}

  isAuthenticated() {
    return this._authService.isAuthenticated();
  }
  isFeatureFlagEnabled(featureFlag: FeatureFlag) {
    return this._featureFlags.isFeatureFlagEnabled(featureFlag);
  }
}
