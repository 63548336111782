import { Component } from '@angular/core';
import { SafariRouterReduxObject } from '@safarilaw-webapp/shared/routing-utility/store-access';

import { NgClass } from '@angular/common';
import { Actions } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { AppDialogUiReduxObject, DialogBaseComponent } from '@safarilaw-webapp/shared/dialog/store-access';
import { FeatureFlagsService } from '@safarilaw-webapp/shared/environment';
import { ErrorObject, ErrorType } from '@safarilaw-webapp/shared/error-handling-message-parser';
import { CollapseModule } from 'ngx-bootstrap/collapse';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';

@Component({
  selector: 'safarilaw-webapp-error-dialog',
  templateUrl: './error-dialog.component.html',
  styleUrls: ['./error-dialog.component.scss'],
  imports: [NgClass, CollapseModule]
})
export class ErrorDialogComponent extends DialogBaseComponent {
  static ClassId = 'ERRORDIALOG_70c43f09-d69f-4ced-8288-8471551edd5f';
  isCollapsed = true;
  // Why is this not using our modal service but instead goes straight to BsModal ? Maybe we wanted to be
  // super safe and not add more depdenencies since this is in error handler ?
  constructor(
    dialogReduxObject: AppDialogUiReduxObject,
    bsModalService: BsModalService,
    store: Store<any>,
    actions: Actions,
    bsModalRef: BsModalRef,
    private _featureFlags: FeatureFlagsService,
    private _safariRouterReduxObject: SafariRouterReduxObject
  ) {
    super(dialogReduxObject, bsModalService, store, actions, bsModalRef);
  }
  get errorObject(): ErrorObject {
    return this.data.errorObject;
  }
  get url(): string {
    return this.data.url;
  }

  get maxValidationErrors(): number {
    if (this.errorObject.maxValidationErrors == null || this.errorObject.maxValidationErrors < 1) {
      return 1;
    }
    return this.errorObject.maxValidationErrors;
  }
  getMessageHeaderFromErrorObject(): string {
    let message = '';
    if (this.errorObject.errorType == ErrorType.Navigation) {
      message += 'Unable to navigate to ' + this.url;
    } else if (this.errorObject.errorType == ErrorType.Validation && this.errorObject.safariApiError && this.errorObject.maxValidationErrors > 1) {
      message += 'Please correct the errors below and retry.';
    }
    return message;
  }
  getErrorText() {
    return this.errorObject.message;
  }
  getErrorTitle() {
    if (this.isSafariValidationError()) {
      return 'Validation Error';
    } else if (this.isNavigationError()) {
      return 'Navigation Error';
    } else if (this.isConflictError()) {
      return 'UNABLE TO SAVE';
    }
    return 'Error';
  }
  isKnownError() {
    return this.isSafariValidationError() || this.isConflictError() || this.isNavigationError();
  }
  isSafariValidationError() {
    return this.errorObject.errorType == ErrorType.Validation;
  }
  isNavigationError() {
    return this.errorObject.errorType == ErrorType.Navigation;
  }
  isConflictError() {
    return this.errorObject.errorType == ErrorType.Conflict;
  }
  closeModal() {
    // this._bsModalRef.hide();
    // this.onClose.next();
    if (this.isConflictError()) {
      // Set checkDiry to false. Right after this there will be a global unload check
      // that will take a look at this flag first in order to decide whether it should pop
      // browsers "are you sure you want to leave". We don't want to give that choice here.
      // If they hit "another user" error we refresh the page automatically and they lose their changes by design
      this._store.dispatch(
        this._safariRouterReduxObject.default.actions.setState({
          payload: {
            path: [window.location.href],
            checkDirty: false
          }
        })
      );
      // hard refresh - forces angular to reload the route from scratch
      window.location.reload();
    } else {
      super.closeModal();
    }
  }
  showDeveloperExceptions() {
    return this._featureFlags.devExceptions;
  }
  getDialogClass() {
    return {
      // eslint-disable-next-line @typescript-eslint/naming-convention -- CSS name
      'mb-0': true,
      // eslint-disable-next-line @typescript-eslint/naming-convention -- CSS name
      alert: this.isKnownError(),
      // eslint-disable-next-line @typescript-eslint/naming-convention -- CSS name
      'alert-danger': !this.isKnownError()
    };
  }
}
