import { CommonModule } from '@angular/common';
import { InjectionToken, NgModule } from '@angular/core';
import { ActionReducerMap, StoreModule } from '@ngrx/store';
import { RouterReducerService } from './state/state.reducer';

import { EffectsModule } from '@ngrx/effects';
import { IRouterFeatureState } from '@safarilaw-webapp/shared/common-objects-models';
import { RouterEffects } from './router/redux/effect';

const REDUCER_TOKEN = new InjectionToken<ActionReducerMap<IRouterFeatureState>>('Router State Reducers');
/**
 * This module is used to provide store feature for our routing utility. Like some other "StoreAccess" modules its' probably best to import it
 * in main.ts. It's fairly small and shouldn't contribute too much to the overall size of the app module.
 *
 * NOTE: At some point we should look into NGRX's own routing module and see if it can replace this one
 */
@NgModule({
  imports: [EffectsModule.forRoot([]), StoreModule.forFeature('navigation', REDUCER_TOKEN), EffectsModule.forFeature([RouterEffects]), CommonModule],
  providers: [
    {
      provide: REDUCER_TOKEN,
      deps: [RouterReducerService],
      useFactory: (service: RouterReducerService) => service.reducers
    }
  ]
})
export class SharedRoutingUtilityStoreAccessModule {}
