import { Actions, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { AppUiReduxObject } from '@safarilaw-webapp/shared/app-bootstrap/data-access';
import { AuthReduxObject, MixinConstructor, SafariObjectId } from '@safarilaw-webapp/shared/common-objects-models';
import { AppDialogUiReduxObject, ConfirmationDialogButton, ConfirmationDialogButtonProps, ConfirmationDialogRequest, ConfirmationDialogResponse } from '@safarilaw-webapp/shared/dialog/store-access';
import { AbortSave } from '@safarilaw-webapp/shared/redux';
import { Observable, filter, map, mergeMap, of, take, throwError } from 'rxjs';
import { v4 as uuidv4 } from 'uuid';
/**
 *
 * This mixin is supposed to be used sparingly and mostly for framework level OR
 * smart-ish components. But most of the time when a lower-level control wants to show a dialog
 * a better solution might be sending an event to the main page and let the main page use its
 * dialog control features to do that.
 */
export const dialogControlMixin = <B extends MixinConstructor>(Base: B) =>
  class DialogControlMixin extends Base {
    protected _appDialogUiReduxObject: AppDialogUiReduxObject;
    protected _actions: Actions<unknown>;
    protected _store: Store<unknown>;
    protected _appUiReduxObject: AppUiReduxObject;
    protected _authReduxObject: AuthReduxObject;

    _toDialogRequestObject(
      id: string,
      title: string,
      content: string,
      okBtnProps: string | ConfirmationDialogButtonProps,
      cancelBtnProps: string | ConfirmationDialogButtonProps,
      aux1BtnProps: string | ConfirmationDialogButtonProps,
      parentData: any,
      noUiBlock: boolean
    ): ConfirmationDialogRequest {
      if (id == null) {
        id = uuidv4();
      }

      let buttons = null;
      if (okBtnProps != null) {
        buttons = ConfirmationDialogButton.Ok;
      }
      if (cancelBtnProps != null) {
        // eslint-disable-next-line no-bitwise -- intended
        buttons |= ConfirmationDialogButton.Cancel;
      }
      if (aux1BtnProps != null) {
        // eslint-disable-next-line no-bitwise -- intended
        buttons |= ConfirmationDialogButton.Aux1;
      }
      return {
        id,
        title,
        content,
        buttons,
        okBtnName: okBtnProps ? (typeof okBtnProps === 'string' ? okBtnProps : okBtnProps.name) : null,
        okBtnClass: okBtnProps && typeof okBtnProps !== 'string' ? okBtnProps.class : null,
        okBtnSubtext: okBtnProps && typeof okBtnProps !== 'string' ? okBtnProps.subtext : null,
        cancelBtnName: cancelBtnProps ? (typeof cancelBtnProps === 'string' ? cancelBtnProps : cancelBtnProps.name) : null,
        cancelBtnClass: cancelBtnProps && typeof cancelBtnProps !== 'string' ? cancelBtnProps.class : null,
        cancelBtnSubtext: cancelBtnProps && typeof cancelBtnProps !== 'string' ? cancelBtnProps.subtext : null,
        aux1BtnName: aux1BtnProps ? (typeof aux1BtnProps === 'string' ? aux1BtnProps : aux1BtnProps.name) : null,
        aux1BtnClass: aux1BtnProps && typeof aux1BtnProps !== 'string' ? aux1BtnProps.class : null,
        aux1BtnSubtext: aux1BtnProps && typeof aux1BtnProps !== 'string' ? aux1BtnProps.subtext : null,
        component: null,
        parentData,
        noUiBlock
      } as ConfirmationDialogRequest;
    }
    /**
     * Simplified call to showDialogOnce$. Shows a standard alert dialog (OK button only)
     * @param title
     * @param content
     * @param okBtnProps
     * @param parentData
     * @returns
     */
    showDialog1ButtonOnce$(
      title: string,
      content: string,
      optionsOrOkBtnName:
        | { okBtnProps?: string | ConfirmationDialogButtonProps; parentData?: any; shouldAbortSaveFn: (o: ConfirmationDialogResponse) => boolean; abortSaveReturns?: any; noUiBlock?: boolean }
        | string = null
    ) {
      const defaultShouldAbort = o => o.buttonType != ConfirmationDialogButton.Ok;
      if (typeof optionsOrOkBtnName === 'string' || optionsOrOkBtnName == null) {
        const okButtonName = optionsOrOkBtnName as string;
        return this.showDialogOnce$(this._toDialogRequestObject(null, title, content, { class: null, name: okButtonName || 'OK' }, null, null, null, false), { shouldAbortSaveFn: defaultShouldAbort });
      } else {
        const options = optionsOrOkBtnName;
        return this.showDialogOnce$(
          this._toDialogRequestObject(null, title, content, options?.okBtnProps || { class: null, name: 'OK' }, null, null, options?.parentData || {}, !!options?.noUiBlock),
          { shouldAbortSaveFn: options.shouldAbortSaveFn || defaultShouldAbort, abortSaveReturns: options?.abortSaveReturns }
        );
      }
    }
    /**
     * Simplified call to showDialogOnce$. Shows a standard confirmation dialog (Ok/Cancel)
     * @param title
     * @param content
     * @param okBtnProps
     * @param parentData
     * @returns
     */
    showDialog2ButtonsOnce$(
      title: string,
      content: string,
      optionsOrOkBtnName:
        | {
            okBtnProps?: string | ConfirmationDialogButtonProps;
            cancelBtnProps?: string | ConfirmationDialogButtonProps;
            parentData?: any;
            noUiBlock?: boolean;
            shouldAbortSaveFn?: (o: ConfirmationDialogResponse) => boolean;
            abortSaveReturns?: any;
          }
        | string = null,
      cancelButtonName: string = null
    ) {
      const defaultShouldAbort = o => o.buttonType != ConfirmationDialogButton.Ok;
      if (typeof optionsOrOkBtnName === 'string' || optionsOrOkBtnName == null) {
        const okButtonName = optionsOrOkBtnName as string;
        return this.showDialogOnce$(
          this._toDialogRequestObject(null, title, content, { class: null, name: okButtonName || 'OK' }, { class: null, name: cancelButtonName || 'CANCEL' }, null, {}, true),
          {
            shouldAbortSaveFn: defaultShouldAbort
          }
        );
      }
      const options = optionsOrOkBtnName;
      return this.showDialogOnce$(
        this._toDialogRequestObject(
          null,
          title,
          content,
          options?.okBtnProps || { class: null, name: 'OK' },
          options?.cancelBtnProps || { class: null, name: 'CANCEL' },
          null,
          options?.parentData || {},
          !!options?.noUiBlock
        ),
        { shouldAbortSaveFn: options.shouldAbortSaveFn || defaultShouldAbort, abortSaveReturns: options?.abortSaveReturns }
      );
    }
    /**
     * Dispatches a dialog as defined in the payload and listens for response for the dialog with the same ID
     * @param dialogRequest
     * @returns
     */
    showDialogOnce$(
      dialogRequest: ConfirmationDialogRequest,
      options: {
        shouldAbortSaveFn: (o: ConfirmationDialogResponse) => boolean;
        abortSaveReturns?: any;
      } = null
    ) {
      const id = dialogRequest.id || uuidv4();
      // To see why we're creating an observable that dispatches an action look at createOrUpdateObjectOnce$ comments
      const func = new Observable<void>(observer => {
        setTimeout(() =>
          this._store.dispatch(
            this._appDialogUiReduxObject.default.actions.openModalDialog({
              payload: { ...dialogRequest, id }
            })
          )
        );

        observer.next();
        observer.complete();
      }).pipe(mergeMap(() => this.observeDialogResponse$(id)));

      return options?.shouldAbortSaveFn
        ? func.pipe(
            mergeMap(o => {
              if (options.shouldAbortSaveFn(o) == true) {
                this._store.dispatch(this._appUiReduxObject.default.actions.toggleBlockUi({ payload: { value: false, transparent: false } }));
                return throwError(() => new AbortSave(options?.abortSaveReturns));
              }
              return of(o);
            })
          )
        : func;
    }
    closeDialog(id: SafariObjectId | SafariObjectId[]) {
      this._store.dispatch(this._appDialogUiReduxObject.default.actions.closeModalDialog({ payload: { id } }));
    }
    observeDialogResponse$(id: string): Observable<ConfirmationDialogResponse> {
      return this._getDialogResponseObservable$(id).pipe(take(1));
    }

    private _getDialogResponseObservable$(id: string = null): Observable<ConfirmationDialogResponse> {
      return this._actions
        .pipe(
          ofType(this._appDialogUiReduxObject.default.actions.modalDialogDismissed),
          filter(o => o.payload.id == id)
        )
        .pipe(
          map(o => o.payload),
          take(1)
        );
    }
  };
