import { Directive, DoCheck, Input, TemplateRef, ViewContainerRef } from '@angular/core';
import { DateTime } from 'luxon';
import { IfNotReadyBaseDirective } from '../if-not-ready-base/if-not-ready-base.directive';

@Directive({
  selector: '[slUiKitSharedObservables]'
})
export class SharedObservablesDirective extends IfNotReadyBaseDirective implements DoCheck {
  private _notRequired: string[] = [];
  private _noSpinner = false;
  private _lastCheckDateTime = null;
  @Input('slUiKitSharedObservablesNotRequired') set notRequired(val: string[]) {
    this._notRequired = val != null ? [...val] : [];
  }
  @Input('slUiKitSharedObservablesNoSpinner') set noSpinner(val: boolean) {
    this._noSpinner = val == true;
  }
  private _sharedObservables;
  @Input() set slUiKitSharedObservables(sharedObservables: { [key: string]: any }) {
    this._sharedObservables = sharedObservables;
  }
  ngDoCheck(): void {
    if (!this._lastCheckDateTime) {
      this._lastCheckDateTime = DateTime.now().toMillis();
    }

    let result = true;
    for (const key in this._sharedObservables) {
      if (this._notRequired.includes(key)) {
        continue;
      }
      if (this._sharedObservables[key] == null) {
        result = false;
        const currentDate = DateTime.now().toMillis();
        const diffInSeconds = Math.abs(currentDate - this._lastCheckDateTime) / 1000;
        if (diffInSeconds > 4) {
          // DO NOT remove the comment below. This is useful for debugging pages that get stuck waiting for a particular observable
          // console.log('SharedObservables directive missing observable ' + key);
          this._lastCheckDateTime = currentDate;
        }
        break;
      }
    }

    this._context.slUiKitSharedObservables = this._context.$implicit = result == true ? this._sharedObservables : null;
    this._processCondition(this._noSpinner);
  }
  constructor(templateRef: TemplateRef<any>, viewContainer: ViewContainerRef) {
    super(templateRef, viewContainer);
    this.propName = 'slUiKitSharedObservables';
    this._context = { slUiKitSharedObservables: null, $implicit: null };
  }
}
