import { Injectable } from '@angular/core';
import { RmsDataAccessObject } from '@safarilaw-webapp/feature/rms/data-access';
import { UserVerificationService } from '@safarilaw-webapp/shared/auth/store-access';
import { HttpMethodOverride } from '@safarilaw-webapp/shared/redux';
import { Observable, map, of } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class RmsUserVerificationService extends UserVerificationService {
  private RmsObject: RmsDataAccessObject;

  constructor() {
    super();
    this.RmsObject = this.inject(RmsDataAccessObject);
  }

  override checkForUser(email: string): Observable<boolean> {
    return this.createOrUpdateObjectOnce$(
      this.RmsObject.MyAuth,
      {
        id: '',
        email
      },
      {
        // This is a helper object and as such does not have neither an etag or
        // a regular object ID, so the framework can't automatically deduce which method
        // it should use. We'll help it a bit here.
        options: {
          methodOverride: HttpMethodOverride.Put
        }
      },
      false
    ).pipe(map(o => true));
  }
  override checkForCompanies(): Observable<boolean> {
    return of(true);
  }
  override getCompanies(): Observable<any[]> {
    return of([]);
  }
}
