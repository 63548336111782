import { DOCUMENT } from '@angular/common';
import { inject, provideEnvironmentInitializer } from '@angular/core';
import { Routes } from '@angular/router';

import { environmentImports } from './app.routing.env.imports';

import { NgxCurrencyInputMode, provideEnvironmentNgxCurrency } from 'ngx-currency';
import { NgxMaskOptions, provideEnvironmentNgxMask } from 'ngx-mask';

export const options: Partial<NgxMaskOptions> | (() => Partial<NgxMaskOptions>) = null;
export const customCurrencyMaskConfig = {
  align: 'left',
  allowNegative: true,
  allowZero: true,
  decimal: '.',
  precision: 2,
  prefix: '$ ',
  suffix: '',
  thousands: ',',
  nullable: true,
  min: null,
  max: null,
  inputMode: NgxCurrencyInputMode.Financial
};

export const routes: Routes = [
  // This path used to point to "legal-process" which in turn used to load feature module
  // Now we are going to go straight to feature module, and that one internally will have
  // children that represent previous top level URLS (legal-process vs settings)
  {
    path: '',
    providers: [
      provideEnvironmentInitializer(() => {
        /* eslint-disable no-restricted-syntax -- we can't use this.inject since this is a bootstrap call */
        const document = inject(DOCUMENT);
        const head = document.getElementsByTagName('head')[0];
        const styleName = 'rms-feature';

        const themeLink = document.getElementById('client-theme') as HTMLLinkElement;
        if (themeLink) {
          themeLink.href = styleName;
        } else {
          const style = document.createElement('link');
          style.id = 'client-theme';
          style.type = 'text/css';
          style.rel = 'stylesheet';
          style.href = `${styleName}.css`;

          head.appendChild(style);
        }
      }),
      provideEnvironmentNgxCurrency(customCurrencyMaskConfig),
      provideEnvironmentNgxMask(options)
    ],
    loadChildren: () => import('@safarilaw-webapp/feature/rms/ui').then(m => m.routes)
    //canActivate: [AuthGuardService]
  },

  ...environmentImports,
  // COPY THIS FOR ALL NEW APPS
  // Routes for shared-common-pages
  /// ///////////////////////////////////////////////////////////////////////////////////////////////////////
  {
    path: 'interstitial',

    loadComponent: () => import('@safarilaw-webapp/common-pages/interstitial').then(a => a.InterstitialComponent),
    data: { hideNavbar: true, hideHeader: true, operationName: 'Interstitial' }
  },
  {
    path: 'auth-callback',

    loadComponent: () => import('@safarilaw-webapp/common-pages/auth-callback').then(a => a.AuthCallbackComponent),
    data: { hideNavbar: false, hideHeader: true, operationName: 'Auth Callback' }
  },
  {
    path: 'logout',
    loadComponent: () => import('@safarilaw-webapp/common-pages/logout').then(a => a.LogoutComponent),
    data: { hideHeader: true, suppressContainer: true, operationName: 'Logout' }
  },
  {
    path: 'clear',
    loadComponent: () => import('@safarilaw-webapp/common-pages/clear-storage').then(a => a.ClearStorageComponent),

    data: { operationName: 'Clear Local Storage' }
  },
  {
    path: 'login-error',
    loadComponent: () => import('@safarilaw-webapp/common-pages/login-error').then(a => a.LoginErrorComponent),

    data: { hideHeader: true, suppressContainer: true, hideNavbar: true, operationName: 'Login - Error' }
  },
  {
    path: 'login-no-user',
    loadComponent: () => import('@safarilaw-webapp/common-pages/login-no-user').then(a => a.LoginNoUserComponent),

    data: { hideHeader: true, suppressContainer: true, hideNavbar: true, operationName: 'Login - No User' }
  },
  {
    path: 'error',
    loadComponent: () => import('@safarilaw-webapp/common-pages/page-error').then(a => a.PageErrorComponent),

    data: { pageTitle: 'Request Failure', operationName: 'Error' }
  },
  {
    path: 'login',
    loadComponent: () => import('@safarilaw-webapp/common-pages/login').then(a => a.LoginComponent),

    data: { hideNavbar: true, hideHeader: true, suppressContainer: true, operationName: 'Login' }
  },
  {
    path: '403',
    loadComponent: () => import('./error/components/unauthorized/unauthorized.component').then(a => a.UnauthorizedComponent),
    data: { hideHeader: true, suppressContainer: true, operationName: 'Not Authorized' }
  },
  {
    path: '404',
    loadComponent: () => import('./error/components/page-not-found/page-not-found.component').then(a => a.PageNotFoundComponent),
    data: { hideHeader: true, suppressContainer: true, operationName: 'Page Not Found' }
  },
  {
    path: '504',
    loadComponent: () => import('@safarilaw-webapp/common-pages/gateway-timeout').then(a => a.GatewayTimeoutComponent),

    data: { hideHeader: true, suppressContainer: true, operationName: 'Gateway Timeout' }
  },

  { path: 'init', loadComponent: () => import('./init/init.component').then(a => a.InitComponent), data: { hideNavbar: true, hideHeader: true } },
  {
    path: '**',
    loadComponent: () => import('./error/components/page-not-found/page-not-found.component').then(a => a.PageNotFoundComponent),
    data: { hideHeader: true, suppressContainer: true, operationName: 'Page Not Found' }
  }
];
