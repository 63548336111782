<div class="s_filter input-group">
  <div class="input-group-prepend">
    <label class="pt-2 mr-2">Filter</label>
  </div>
  <input
    #searchText
    [id]="'search-box-' + listId"
    [value]="initialValue"
    [disabled]="disabled"
    class="form-control"
    type="text"
    (keyup)="updateTextFilter($event.target['value'])"
    />
    @if (hasSearchText()) {
      <div class="input-group-append s_filter-clear">
        <a (click)="clearText()" class="s_search-clear" type="button"></a>
      </div>
    }
  </div>
