/* eslint-disable no-restricted-syntax -- framework file. Ok */
// /* eslint-disable no-restricted-syntax -- framework use */
// /**
//  * DO NOT EXPORT THIS FILE IN INDEX.TS
//  * THERE IS NO REASON FOR WEBAPP OR ANYTHING ELSE TO KNOW ABOUT THIS
//  */
import { inject, InjectionToken } from '@angular/core';

import { HttpClient } from '@angular/common/http';
import { AttachmentLink, FileObject, SafariObject } from '@safarilaw-webapp/shared/common-objects-models';
import { AttachmentLinkAdapter, CrudService, DropdownService, FileObjectAdapter, HelperObjectAdapterPOST, ServiceConfiguration } from '@safarilaw-webapp/shared/crud';
import { AppConfigurationService } from '@safarilaw-webapp/shared/environment';
import { ApplicationInsightsService } from '@safarilaw-webapp/shared/logging';
import { AcknowledgmentAdapter } from '../acknowledgment/adapters/acknowledgment-adapter';
import { Acknowledgment } from '../acknowledgment/models/app/acknowledgment';
import { AuthAdapter } from '../auth/adapters/auth-adapter';
import { MyAuth } from '../auth/models/my-auth';
import { DeliveryPortalAdapter } from '../delivery-portal/adapters/delivery-portal-adapter';

import { DeliveryPortal } from '../delivery-portal/models/app/delivery-portal';
import { DropdownApiAdapter } from '../dropdown/adapter/dropdown-adapter';
import { RMS_TRANSFER_DIALOG_OBJECT } from '../feature-rms-data-access.module';
import { IntakePortalFormAdapter } from '../intake-portal-form/adapters/intake-portal-form-adapter';
import { IntakePortalForm } from '../intake-portal-form/models/app/intake-portal-form';
import { IntakePortalAdapter } from '../intake-portal/adapters/intake-portal-adapter';
import { IntakePortal } from '../intake-portal/models/app/intake-portal';
import { MyIntakePortalAdapter } from '../my-intake-portal/adapters/my-intake-portal-adapter';
import { MyIntakePortal } from '../my-intake-portal/models/app/my-intake-portal';
import { OrganizationSearchAdapter } from '../ogranization-search/adapters/organization-search-adapter';
import { OrganizationSearch } from '../ogranization-search/models/app/organization-search';
import { ProfileAdapter } from '../profile/adapters/profile-adapter';
import { Profile } from '../profile/models/app/profile';
import { SubmissionBatchAdapter } from '../submission-batch/adapters/submission-batch-adapter';
import { SubmissionBatch } from '../submission-batch/models/app/submission-batch';
import { SubmissionAdapter } from '../submission/adapters/submission-adapter';
import { Submission } from '../submission/models/app/submission';

export const DELIVERY_PORTAL_SERVICE_TOKEN = new InjectionToken<CrudService<DeliveryPortal>>('DELIVERY_PORTAL_SERVICE_TOKEN', {
  providedIn: 'root',
  factory: () =>
    new CrudService<DeliveryPortal>(inject(DeliveryPortalAdapter), inject(ApplicationInsightsService), new ServiceConfiguration(inject(AppConfigurationService).urls.apiRootRms, 'deliveryPortal'))
});

export const CONFIRM_RECEIPT_SERVICE_TOKEN = new InjectionToken<CrudService<SafariObject>>('CONFIRM_RECEIPT_SERVICE_TOKEN', {
  providedIn: 'root',
  factory: () =>
    new CrudService<SafariObject>(
      inject(HelperObjectAdapterPOST),
      inject(ApplicationInsightsService),
      new ServiceConfiguration(inject(AppConfigurationService).urls.apiRootRms, 'deliveryPortal/{0}/confirmReceipt')
    )
});

export const ACKNOWLEDGE_INSTRUCTIONS_SERVICE_TOKEN = new InjectionToken<CrudService<Acknowledgment>>('AKCNOWLEDGE_INSTRUCTIONS_SERVICE_TOKEN', {
  providedIn: 'root',
  factory: () =>
    new CrudService<Acknowledgment>(
      inject(AcknowledgmentAdapter),
      inject(ApplicationInsightsService),
      new ServiceConfiguration(inject(AppConfigurationService).urls.apiRootRms, 'deliveryPortal/{0}/acknowledgeInstructions')
    )
});

export const MY_AUTH_SERVICE_TOKEN = new InjectionToken<CrudService<MyAuth>>('MY_AUTH_SERVICE_TOKEN', {
  providedIn: 'root',
  factory: () => new CrudService<MyAuth>(inject(AuthAdapter), inject(ApplicationInsightsService), new ServiceConfiguration(inject(AppConfigurationService).urls.apiRootRms, 'my/auth'))
});

export const DROPDOWN_SERVICE_TOKEN = new InjectionToken<DropdownService>('DROPDOWN_SERVICE_TOKEN', {
  providedIn: 'root',
  factory: () => new DropdownService(inject(HttpClient), inject(AppConfigurationService).urls.apiRootRms, inject(DropdownApiAdapter))
});
export const GENERATE_TEMPLINK_SERVICE_TOKEN = new InjectionToken<CrudService<AttachmentLink>>('GENERATE_TEMPLINK_SERVICE_TOKEN', {
  providedIn: 'root',
  factory: () =>
    new CrudService<AttachmentLink>(
      inject(AttachmentLinkAdapter),
      inject(ApplicationInsightsService),
      new ServiceConfiguration(inject(AppConfigurationService).urls.apiRootRms, 'deliveryPortal/{0}/download/{1}/link')
    )
});
export const GENERATE_TEMPZIP_SERVICE_TOKEN = new InjectionToken<CrudService<AttachmentLink>>('GENERATE_TEMPZIP_SERVICE_TOKEN', {
  providedIn: 'root',
  factory: () =>
    new CrudService<AttachmentLink>(
      inject(AttachmentLinkAdapter),
      inject(ApplicationInsightsService),
      new ServiceConfiguration(inject(AppConfigurationService).urls.apiRootRms, 'deliveryPortal/{0}/download/responseZip')
    )
});
export const INTAKE_PORTAL_SERVICE_TOKEN = new InjectionToken<CrudService<IntakePortal>>('INTAKE_PORTAL_SERVICE_TOKEN', {
  providedIn: 'root',
  factory: () =>
    new CrudService<IntakePortal>(inject(IntakePortalAdapter), inject(ApplicationInsightsService), new ServiceConfiguration(inject(AppConfigurationService).urls.apiRootRms, 'intakePortals'))
});

export const INTAKE_PORTAL_FORM_SERVICE_TOKEN = new InjectionToken<CrudService<IntakePortalForm>>('INTAKE_PORTAL_FORM_SERVICE_TOKEN', {
  providedIn: 'root',
  factory: () =>
    new CrudService<IntakePortalForm>(
      inject(IntakePortalFormAdapter),
      inject(ApplicationInsightsService),
      new ServiceConfiguration(inject(AppConfigurationService).urls.apiRootRms, 'intakePortals/{0}/forms')
    )
});
export const SUBMISSION_SERVICE_TOKEN = new InjectionToken<CrudService<Submission>>('SUBMISSION_SERVICE_TOKEN', {
  providedIn: 'root',
  factory: () => new CrudService<Submission>(inject(SubmissionAdapter), inject(ApplicationInsightsService), new ServiceConfiguration(inject(AppConfigurationService).urls.apiRootRms, 'submissions'))
});
export const ORGANIZATION_SEARCH_SERVICE_TOKEN = new InjectionToken<CrudService<OrganizationSearch>>('ORGANIZATION_SEARCH_SERVICE_TOKEN', {
  providedIn: 'root',
  factory: () =>
    new CrudService<OrganizationSearch>(
      inject(OrganizationSearchAdapter),
      inject(ApplicationInsightsService),
      new ServiceConfiguration(inject(AppConfigurationService).urls.apiRootRms, 'search/organizations')
    )
});

export const GENERATE_SUBMISSION_TEMPLINK_SERVICE_TOKEN = new InjectionToken<CrudService<AttachmentLink>>('GENERATE_SUBMISSION_TEMPLINK_SERVICE_TOKEN', {
  providedIn: 'root',
  factory: () =>
    new CrudService<AttachmentLink>(
      inject(AttachmentLinkAdapter),
      inject(ApplicationInsightsService),
      new ServiceConfiguration(inject(AppConfigurationService).urls.apiRootRms, 'submissions/{0}/files/{1}/link')
    )
});
export const MY_PROFILE_SERVICE_TOKEN = new InjectionToken<CrudService<Profile>>('MY_PROFILE_SERVICE_TOKEN', {
  providedIn: 'root',
  factory: () =>
    new CrudService<Profile>(inject(ProfileAdapter), inject(ApplicationInsightsService), new ServiceConfiguration(inject(AppConfigurationService).urls.apiRootRms, 'my/profile', ['authUserId']))
});
export const MY_INTAKE_PORTAL_SERVICE_TOKEN = new InjectionToken<CrudService<MyIntakePortal>>('MY_INTAKE_PORTAL_SERVICE_TOKEN', {
  providedIn: 'root',
  factory: () =>
    new CrudService<MyIntakePortal>(
      inject(MyIntakePortalAdapter),
      inject(ApplicationInsightsService),
      new ServiceConfiguration(inject(AppConfigurationService).urls.apiRootRms, 'my/intakePortals', ['authUserId'])
    )
});
export const SUBMISSION_BATCH_SERVICE_TOKEN = new InjectionToken<CrudService<SubmissionBatch>>('SUBMISSION_BATCH_SERVICE_TOKEN', {
  providedIn: 'root',
  factory: () =>
    new CrudService<SubmissionBatch>(
      inject(SubmissionBatchAdapter),
      inject(ApplicationInsightsService),
      new ServiceConfiguration(inject(AppConfigurationService).urls.apiRootRms, 'submissionBatches'),
      undefined
    )
});
export const SUBMISSION_BATCH_ATTACHMENT_LINK_ATTACHMENT_LINK_SERVICE_TOKEN = new InjectionToken<CrudService<AttachmentLink>>('SUBMISSION_BATCH_ATTACHMENT_LINK_ATTACHMENT_LINK_SERVICE_TOKEN', {
  providedIn: 'root',
  factory: () =>
    new CrudService<AttachmentLink>(
      inject(AttachmentLinkAdapter),
      inject(ApplicationInsightsService),
      new ServiceConfiguration(inject(AppConfigurationService).urls.apiRootRms, 'submissionBatches/{0}/files/{1}/link'),
      undefined
    )
});
export const SUBMISSION_FILE_FILE_OBJECT_SERVICE_TOKEN = new InjectionToken<CrudService<FileObject>>('SUBMISSION_FILE_FILE_OBJECT_SERVICE_TOKEN', {
  providedIn: 'root',
  factory: () =>
    new CrudService<FileObject>(
      inject(FileObjectAdapter),
      inject(ApplicationInsightsService),
      new ServiceConfiguration(inject(AppConfigurationService).urls.apiRootRms, 'submissions/files'),
      inject(RMS_TRANSFER_DIALOG_OBJECT)
    )
});
/*** BEWARE OF DOG - DO NOT DELETE OR PUT ANYTHING ELSE BELOW THIS ***/
