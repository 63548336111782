import { Component, ContentChildren, Input, QueryList, TemplateRef, ViewChild } from '@angular/core';
import { DatatableComponent, NgxDatatableModule, SelectionType, TableColumn } from '@siemens/ngx-datatable';
import { FlyoutComponent, FlyoutDirection, FlyoutItem } from '../../../../shared/components/flyout/flyout.component';
import { SafariTableColumn } from '../index';

export class CheckboxOverride {
  function: (row: any) => boolean;
  value: string;
  class: string;
}
export enum RowMenuType {
  None,
  Flyout,
  SingleDeleteAction
}
export enum RowMenuActions {
  Delete = 'Delete',
  Undo = 'Undo'
}
@Component({ selector: 'sl-ui-kit-datatable', imports: [NgxDatatableModule, FlyoutComponent], templateUrl: './data-table-wrapper.component.html', styleUrls: ['./data-table-wrapper.component.scss'] })
export class DataTableWrapperComponent {
  private _hiddenColumnIndexes: number[] = [];
  private _columnOrder: number[] = [];
  FlyoutDirection = FlyoutDirection;
  RowMenuActions = RowMenuActions;
  @Input() checkboxOverrides: CheckboxOverride[] = [];
  @Input() rowMenuType: RowMenuType = RowMenuType.None;
  @Input() columnMode: 'flex' | 'standard' | 'force' = 'flex';
  @Input() rowHeight: number | 'auto' = 'auto';
  @Input() page = 0;
  @Input() selected = [];
  @Input() totalCount: number;

  @Input() pageSize = 20;
  @Input() id: string;
  @Input() headerHeight = 50;
  @Input() footerHeight = 50;
  @Input() selectionType: SelectionType = null;
  @Input() reorderable = false;
  @Input() rowMenuItems: FlyoutItem[] = null;
  @Input() onPageCallback: (event: any) => void;
  @Input() onSelectCallback: (event: any) => void;
  private _canBeSelectedCallback: (row: any) => boolean;
  public get canBeSelectedCallback(): (row: any) => boolean {
    return this._canBeSelectedCallback;
  }
  @Input()
  public set canBeSelectedCallback(value: (row: any) => boolean) {
    this._canBeSelectedCallback = value;
  }
  @Input() getHiderClassCallback: (row: any) => any;
  @Input() shouldShowRowActionsCallback: (row: any) => boolean;
  @Input() rowMenuButtonClickedCallback: (row: any) => void;
  @Input() rowMenuItemClickedCallback: (row: any) => void;
  @Input() canDisplaySelectAllCallback: () => boolean;
  @Input() getRowClassesFunctionCallback: (row: any) => any;
  @Input() canBeDeletedCallback: (row: any) => any;
  hideHeadersWhenNoData = false;
  emptyMessage = 'No data to display';
  totalMessage = 'total';
  selectedMessage = 'selected';

  @Input()
  columns: SafariTableColumn[] = [];

  private _rows = [];
  public get rows() {
    return this._rows;
  }
  @Input()
  public set rows(value) {
    this._rows = value;
    this._recalcHeaderHeight();
  }

  cols: TableColumn[] = [];
  private _initialCols: TableColumn[] = [];
  private _leadingCols: TableColumn[] = [];
  private _trailingCols: TableColumn[] = [];
  _recalcHeaderHeight() {
    if (this.table) {
      if (this.rows?.length > 0 || this.hideHeadersWhenNoData == false) {
        this.table.headerHeight = this.headerHeight;
        this.table.footerHeight = this.footerHeight;
      } else {
        this.table.headerHeight = 0;
        this.table.footerHeight = 0;
      }
    }
  }
  private _table: DatatableComponent = null;
  private _templates: QueryList<TemplateRef<any>> = null;
  @ViewChild(DatatableComponent) set table(value: DatatableComponent) {
    this._table = value;
  }
  get table(): DatatableComponent {
    return this._table;
  }
  @ContentChildren(TemplateRef) set templates(value: QueryList<TemplateRef<any>>) {
    this._templates = value;
  }

  private _columnsSet = false;

  @ViewChild('checkboxHeaderTemplate', { static: true }) checkboxHeaderTemplate: TemplateRef<any>;
  @ViewChild('checkboxCellTemplate', { static: true }) checkboxCellTemplate: TemplateRef<any>;

  @ViewChild('fileActionsHeaderTemplate', { static: true }) fileActionsHeaderTemplate: TemplateRef<any>;
  @ViewChild('fileActionsCellTemplate', { static: true }) fileActionsCellTemplate: TemplateRef<any>;

  setUpColumns() {
    let headerTemplate: TemplateRef<any> = null;
    let cellTemplate: TemplateRef<any> = null;
    this._initialCols = [];
    this._leadingCols = [];
    this._trailingCols = [];
    let columnsInfoIndex = -1;
    this._templates.forEach((template, index) => {
      const mod = index % 2;
      if (mod == 0) {
        headerTemplate = template;
        columnsInfoIndex++;
      } else if (mod == 1) {
        cellTemplate = template;
      }
      if (headerTemplate && cellTemplate) {
        const colOverrides = this.columns[columnsInfoIndex] || { flexGrow: 1 };
        if (typeof colOverrides.resizeable !== 'boolean') {
          colOverrides.resizeable = false;
        }
        // Name is a required property for datatable to compute column distribution correctly
        if (!colOverrides.name) {
          colOverrides.name = `Column${index}`;
        }
        this._initialCols.push({
          headerTemplate,
          cellTemplate,

          ...colOverrides
        } as TableColumn);
        headerTemplate = null;
        cellTemplate = null;
      }
    });

    if (this.selectionType != null) {
      this._leadingCols.push({ cellTemplate: this.checkboxCellTemplate, headerTemplate: this.checkboxHeaderTemplate, name: 'Checkbox', flexGrow: 0, width: 36, minWidth: 36 });
    }

    if (this.rowMenuType != RowMenuType.None) {
      this._trailingCols.push({ cellTemplate: this.fileActionsCellTemplate, headerTemplate: this.fileActionsHeaderTemplate, name: 'FileActions', flexGrow: 0, width: 50, minWidth: 50 });
    }

    this.cols = [...this._leadingCols, ...this._initialCols, ...this._trailingCols];
    this._table.messages = { emptyMessage: this.emptyMessage, totalMessage: this.totalMessage, selectedMessage: this.selectedMessage };

    this._processColumnOrder();

    this._recalcHeaderHeight();

    this._columnsSet = true;
  }

  private _processColumnOrder() {
    this.cols = [];
    const colsWithOrder = [];
    const colsWIthoutOrder = [];
    // eslint-disable-next-line @typescript-eslint/prefer-for-of -- we want to go by index specifically
    for (let i = 0; i < this._initialCols.length; i++) {
      const order = this._columnOrder.findIndex(x => x == i);
      if (order > -1) {
        if (this._hiddenColumnIndexes.find(x => x == i) == null) {
          colsWithOrder.push({ col: this._initialCols[i], order });
        }
      } else {
        if (this._hiddenColumnIndexes.find(x => x == i) == null) {
          colsWIthoutOrder.push(this._initialCols[i]);
        }
      }
    }
    colsWithOrder.sort((a, b) => a.order - b.order);
    this.cols = [...this._leadingCols, ...colsWithOrder.map(x => x.col), ...colsWIthoutOrder, ...this._trailingCols];
  }
  setHiddenColumns(hiddenColumnIndexes: number[]) {
    this._hiddenColumnIndexes = hiddenColumnIndexes;
    if (this._columnsSet) {
      this._processColumnOrder();
    }
  }
  setColumnOrder(columnOrder: number[]) {
    this._columnOrder = columnOrder;
    if (this._columnsSet) {
      this._processColumnOrder();
    }
  }
  getCheckboxOverride(isSelected: boolean, row: any) {
    if (isSelected) {
      return null;
    }
    for (const override of this.checkboxOverrides) {
      if (override.function(row)) {
        return override;
      }
    }
  }
  shouldShowSingleDelete(row: any) {
    return this.rowMenuType == RowMenuType.SingleDeleteAction;
  }
}
