import { Component, Injector, OnDestroy, OnInit } from '@angular/core';
import { NavigationEnd, RouterOutlet } from '@angular/router';

import { NavbarService, SafariRootAppComponent } from '@safarilaw-webapp/shared/app-bootstrap/data-access';
import { FeatureFlagsService } from '@safarilaw-webapp/shared/environment';
import { ReduxWrapperService } from '@safarilaw-webapp/shared/redux';

import { NgClass } from '@angular/common';
import { CustomThemeIds } from '@safarilaw-webapp/feature/rms/data-access';
import { RmsHeaderComponent, RmsNavbarComponent } from '@safarilaw-webapp/feature/rms/shared';
import { BlockUIModule } from 'ng-block-ui';
import { AppUpdateSplashComponent } from './app-update-splash/components/app-update-splash/app-update-splash';
import { NavbarItemCollection } from './navbar-item-collection';
import { MaintenanceHeaderComponent } from './site-maintenance/components/maintenance-header/maintenance-header';
import { MaintenanceSplashComponent } from './site-maintenance/components/maintenance-splash/maintenance-splash';

@Component({
  selector: 'sl-rms-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
  imports: [MaintenanceSplashComponent, AppUpdateSplashComponent, MaintenanceHeaderComponent, RouterOutlet, NgClass, RmsHeaderComponent, RmsNavbarComponent, BlockUIModule]
})
export class AppComponent extends SafariRootAppComponent implements OnInit, OnDestroy {
  private readonly noDividerPages = ['/profile', '/404', '/504', '/403', '/logout', 'login-error'];

  showSplash = false;

  constructor(
    injector: Injector,

    navbarService: NavbarService,
    private _featureFlagService: FeatureFlagsService,

    private _reduxWrapperService: ReduxWrapperService
  ) {
    super(injector, navbarService);
    this._navbarItems = [...NavbarItemCollection];
  }

  ngOnInit(): void {
    super.ngOnInit();

    /* BEGIN CUSTOM THEMES */
    const safariContext: any = (window['Safari'] = window['Safari'] || {});
    const head = window.document.getElementsByTagName('head')[0];
    for (const [key, value] of Object.entries(CustomThemeIds)) {
      const subdomain = (window.location.hostname?.toLowerCase()?.split('.') ?? [''])[0];
      if (subdomain === key || localStorage.getItem('safari_custom_theme') === key) {
        // Since this isn't observable expressionChangedAfterChecked can sometimes trigger.
        setTimeout(() => {
          safariContext.customThemeId = value;
        }, 0);

        let styleOverride = window.document.getElementById('client-theme-override') as HTMLLinkElement;
        if (!styleOverride) {
          styleOverride = window.document.createElement('link');
          styleOverride.id = 'client-theme-override';
          styleOverride.type = 'text/css';
          styleOverride.rel = 'stylesheet';
          head.appendChild(styleOverride);
        }
        styleOverride.href = `${value as string}.css`;
      }
    }
    /* END CUSTOM THEMES */
  }
  ngOnDestroy(): void {
    super.ngOnDestroy();
  }
  OnNavigationEnd(evt: NavigationEnd) {
    super.OnNavigationEnd(evt);
  }

  get canShowHeader() {
    return this.showHeader;
  }
  get canShowNavbar() {
    return this.showNavbar;
  }

  /* This is the little double bar separator between the page title and the content. Pages with an action bar include it.
   * For some pages we just don't want to show it because there's no title bar. */
  get showDivider(): boolean {
    return !this.noDividerPages.includes(this._router.url?.toLowerCase());
  }
}
