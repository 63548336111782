import { Component, Input } from '@angular/core';
import { SafariDumbComponent } from '../../../forms/components/safari-base/safari-base.component';

@Component({
  imports: [],
  selector: 'sl-ui-kit-list-warning',
  templateUrl: './list-warning.component.html',
  styleUrls: ['./list-warning.component.scss']
})
export class ListWarningComponent extends SafariDumbComponent {
  @Input()
  shouldShow = false;
  @Input()
  maxCountInMemory = 0;
}
