import { Injectable } from '@angular/core';
import { Actions, ofType } from '@ngrx/effects';
import { ReduxWrapperService } from '@safarilaw-webapp/shared/redux';
import { cloneDeep } from 'lodash-es';
import { Observable, distinctUntilChanged, filter, forkJoin, map, mergeMap, of, race, take } from 'rxjs';
import { SafariUiDataTableReduxObject } from '../../state/actions/layout-actions';
import { TableSubmitInfo } from '../../state/interfaces/layout-interface';

@Injectable({
  providedIn: 'root'
})
export class TableControlService {
  constructor(
    private _reduxWrapper: ReduxWrapperService,
    private _actions: Actions,
    private _tableReduxObject: SafariUiDataTableReduxObject
  ) {}

  isFilterEmpty(o: any) {
    return o == null || Object.keys(o).length == 0;
  }
  observeCount$(id: any): Observable<number> {
    return this._reduxWrapper.getGenericSelector(this._tableReduxObject.default.selectors.getListTotalCount(id), true);
  }

  observeFilter$(id: string, defaultIfNull: any = null, includeTextChange = true) {
    let func = this._reduxWrapper.getGenericSelector(this._tableReduxObject.default.selectors.getListFilterState(id));
    if (defaultIfNull) {
      func = func.pipe(map(o => (this.isFilterEmpty(o) ? defaultIfNull : o)));
    }

    // Filter is a complex object so setting filter = { something: 'x'} and then later setting to the same
    // object will retrigger an observable since they are two different objects in memory, even though they are
    // exactly the same. To prevent this we'll just block emissions with distinct.
    // We might also want to look into the table itself and prevent it from assinging the filter if it's already identical
    // to what it has, but this solves the problem just as well
    return func.pipe(
      distinctUntilChanged((prev, current) => {
        const a = cloneDeep(prev);
        const b = cloneDeep(current);
        if (!includeTextChange) {
          a.text = null;
          b.text = null;
        }
        return JSON.stringify(a) == JSON.stringify(b);
      })
    );
  }
  observeSort$(id: string, defaultIfNull: string = null) {
    const func = this._reduxWrapper.getGenericSelector(this._tableReduxObject.default.selectors.getListSortState(id));
    // Since a page might want to block on sort not being initialized yet (via our "sharedObservables" directive)
    // we can not return NULL from here as a sort value (if the sort was truly set to NULL)
    return func.pipe(map(o => (String.isNullOrEmpty(o) ? (defaultIfNull == null ? '' : defaultIfNull) : o)));
  }
  observePage$(id: string) {
    return this._reduxWrapper.getGenericSelector(this._tableReduxObject.default.selectors.getListPageState(id));
  }
  observeItemStatus$(tableId: string) {
    return this._reduxWrapper.listenForAction(this._tableReduxObject.default.actions.tableItemStatusChanged).pipe(filter(o => o.payload.id == tableId));
  }
  observeInitFlag$(id: string) {
    return this._reduxWrapper.getGenericSelector(this._tableReduxObject.default.selectors.getListInitStatus(id));
  }
  goToByRowId(id: string, rowId: string) {
    this._reduxWrapper.dispatchGenericAction(this._tableReduxObject.default.actions.tableGoToByRowId({ payload: { id, rowId } }));
  }
  /**
   *
   * "clear" functions should only be used by base pages to clear state when leaving (onPageLeave)
   * We'll probably have to come up with a different way of doing that so we don't expose these.
   * In general filter, count, selected, etc, should be managed via bindings
   */
  clearState(id: string) {
    this._reduxWrapper.dispatchGenericAction(this._tableReduxObject.default.actions.tableClearState({ id }));
  }
  clearSelected(id: string) {
    this._reduxWrapper.dispatchGenericAction(this._tableReduxObject.default.actions.tableClearSelected({ id }));
  }
  clearCount(id: string) {
    this._reduxWrapper.dispatchGenericAction(this._tableReduxObject.default.actions.tableClearCount({ id }));
  }

  private _getTableSubmitObservable$(id: string): Observable<TableSubmitInfo> {
    const ok = this._actions.pipe(
      ofType(this._tableReduxObject.default.actions.tableSubmitSuccess),

      filter(o => o.payload.id == id)
    );
    const fail = this._actions.pipe(
      ofType(this._tableReduxObject.default.actions.tableSubmitFail),
      filter(o => o.payload.id == id)
    );
    return race(ok, fail).pipe(
      mergeMap(o => {
        if (o.type === this._tableReduxObject.default.actions.tableSubmitFail.type) {
          return of({ ...o.payload, ...{ error: o['error'] } }) as Observable<TableSubmitInfo>;
        } else {
          return of(o.payload);
        }
      }),
      take(1)
    );
  }

  /**
   *
   * @param idPararms - One or many form IDS
   * @returns Mapped array of success/fail forms + hasErrors property for easy querying
   *
   * Note: If you have more than one form here you will need to pass a union type and then do your own casting
   * if you need to. Most of the time, however, your main page will only be interested in hasErrors property
   * and success[0].appModel so it can dispatch update
   */
  private _observeTableSubmit$(idPararms): Observable<TableSubmitInfo[]> {
    const ids = [...idPararms];
    const forms: Observable<TableSubmitInfo>[] = [];
    for (const id of ids) {
      forms.push(this._getTableSubmitObservable$(id));
    }
    return forkJoin(forms).pipe(take(1));
  }

  submitOnce$(submitInfoParams: { id: string; additionalInfo?: any }[]) {
    const params = [...submitInfoParams];
    // This is a one-off take(1) observable, not a long running observable like loadObject$ etc.
    // Here we HAVE TO setTimeout on the dispatch in order to ensure that the action is caught. Otherwise
    // the action would be dispatched and completed before the observable even returns to the caller

    const paramIds: string[] = params.map(o => o.id.toString());

    // To see why we're creating an observable that dispatches an action look at createOrUpdateObjectOnce$ comments
    return new Observable<void>(observer => {
      for (const param of params) {
        setTimeout(() =>
          this._reduxWrapper.dispatchGenericAction(
            this._tableReduxObject.default.actions.tableRequestSubmit({
              id: param.id,
              additionalInfo: param.additionalInfo
            })
          )
        );
      }
      observer.next();
      observer.complete();
    }).pipe(mergeMap(() => this._observeTableSubmit$(paramIds)));
  }
}
