import { Component, Input, ViewChild } from '@angular/core';
import { FormGroup, ReactiveFormsModule } from '@angular/forms';

import { FlyoutComponent, FlyoutDirection, FlyoutItem } from '../../../../shared/components/flyout/flyout.component';
import { ListWarningComponent } from '../../list-warning/list-warning.component';
import { TextFilterComponent } from '../../filters/text-filter/text-filter.component';
import { NgTemplateOutlet } from '@angular/common';
import { ActionsBarComponent } from '../../../../actions-bar/components/actions-bar/actions-bar.component';
import { RowMenuType } from '..';

export enum TableMenuMode {
  Compact,
  Extended
}
@Component({
  selector: 'sl-ui-kit-safarilist',
  templateUrl: './safari-list-wrapper.component.html',
  imports: [ListWarningComponent, FlyoutComponent, TextFilterComponent, ReactiveFormsModule, NgTemplateOutlet, ActionsBarComponent],
  styleUrls: ['./safari-list-wrapper.component.scss']
})
export class SafariListWrapperComponent {
  TableMenuMode = TableMenuMode;
  @Input() tableMenuMode: TableMenuMode = TableMenuMode.Compact;
  @Input() bulkMenu = false;
  @Input() newMenu = false;
  @Input() rowMenuType = RowMenuType.None;
  @Input() hasFilter = false;
  @Input() id: string;
  @Input() filterForm: FormGroup;
  @Input() newMenuTooltip = '';
  @Input() newMenuDisabled = true;

  private _textFilterClass = null;
  public get textFilterClass() {
    if (this._textFilterClass == null) {
      let cls = 'form-group col-md-6';
      if (this.tableMenuMode === TableMenuMode.Compact) {
        cls += ' pl-2 pt-1';
      }
      return cls;
    }
    return this._textFilterClass;
  }
  @Input()
  public set textFilterClass(value) {
    this._textFilterClass = value;
  }
  FlyoutDirection = FlyoutDirection;

  @Input() shouldShowBulkActionsCallback: () => boolean;
  @Input() populateBulkItemsCallback: () => void;
  @Input() bulkMenuItemClickedCallback: (flyoutItem: any) => void;
  @Input() bulkMenuItems: FlyoutItem[] = [];

  @Input() shouldShowNewActionsCallback: () => boolean;
  @Input() populateNewItemsCallback: () => void;
  @Input() newMenuItemClickedCallback: (flyoutItem: any) => void;

  @Input() newMenuItems: FlyoutItem[] = [];

  @Input() hideHeadersWhenNoData = false;
  @Input() emptyMessage = 'No data to display';
  @Input() totalMessage = 'total';
  @Input() selectedMessage = 'selected';

  @Input() deleteActionText = 'Delete';
  @Input() deleteActionDescription = 'will be deleted';
  private _listWarning: ListWarningComponent;

  @ViewChild(ListWarningComponent)
  set listWarning(content: ListWarningComponent) {
    this._listWarning = content;
  }
  get listWarning() {
    return this._listWarning;
  }
  get shouldShowTopSection() {
    return this.filterForm && (this.hasFilter || this.newMenu || this.bulkMenu);
  }
}
