import { Pipe, PipeTransform, SecurityContext } from '@angular/core';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';

@Pipe({
  name: 'registeredTrademark'
})
/** Encodes and sanitizes a string, but injects <sup>&reg;</sup> as SafeHtml when input ends with ® */
export class RegisteredTrademarkPipe implements PipeTransform {
  constructor(private _sanitizer: DomSanitizer) {}

  transform(value: string): SafeHtml {
    if (typeof value !== 'string') {
      return value;
    }
    const hasRegisteredTrademark = value.endsWith('®');
    const encodedPart = this._sanitizer.sanitize(SecurityContext.HTML, (hasRegisteredTrademark ? value.substring(0, value.length - 1) : value).htmlEncode());
    return this._sanitizer.bypassSecurityTrustHtml(`${encodedPart}${hasRegisteredTrademark ? '<sup>&reg;</sup>' : ''}`);
  }
}
